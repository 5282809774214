import React, { useRef, useState } from 'react';
import propTypes from 'prop-types';

import config from 'src/config';

import { subscribeToList } from 'src/utilities/klaviyo';

import * as S from './Signup.style';

const initialState = {
  firstName: '',
  value: '',
};

const Signup = ({ type }) => {
  const form = useRef();
  const button = useRef();
  const valueInput = useRef();
  const [state, setState] = useState(initialState);

  const valueType = 'email';
  const listId =
    type === 'e-motivator' ? config.CONNECT_LIST_ID : config.NEWSLETTER_LIST_ID;

  return (
    <S.Signup>
      <S.Wrapper>
        <S.TextWrapper>
          <S.Title>
            {type === 'e-motivator'
              ? 'Connect and be inspired!'
              : 'Signup to our Newsletter!'}
          </S.Title>
          <S.Text>
            {type === 'e-motivator'
              ? 'Signup for the Absolute E-Motivator and receive motivation directly to your inbox!'
              : 'Signup for the newsletter and receive inspiring tips by email to build a solid foundation for your life, stories of generosity and more!'}
          </S.Text>
        </S.TextWrapper>
        <S.InputWrapper
          ref={form}
          onSubmit={(event) => {
            event.preventDefault();
            button.current.click();
          }}
        >
          <S.Input
            required
            placeholder="Your Name"
            value={state.firstName}
            onChange={({ target: { value: newValue } }) => {
              setState((prevState) => ({ ...prevState, firstName: newValue }));
            }}
          />
          <S.Rule />
          <S.Input
            ref={valueInput}
            required
            type="email"
            placeholder="Email Address"
            value={state.value}
            onChange={({ target, target: { value: newValue } }) => {
              target.setCustomValidity('');
              setState((prevState) => ({
                ...prevState,
                value: newValue,
              }));
            }}
          />
          <S.Button
            type="button"
            ref={button}
            messages={{ loading: '', success: 'Subscribed', failure: 'Error' }}
            onClick={() =>
              subscribeToList(
                form.current,
                valueInput.current,
                listId,
                state.firstName,
                valueType,
                state.value
              )
            }
          >
            Subscribe
          </S.Button>
        </S.InputWrapper>
      </S.Wrapper>
    </S.Signup>
  );
};

Signup.propTypes = {
  type: propTypes.oneOf(['e-motivator', 'newsletter']).isRequired,
};

export default Signup;
