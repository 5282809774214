const SHOPIFY_CHECKOUT_ID = 'shopify_checkout_id';

/* eslint no-shadow: "off" */

export default async function fetchCheckout(helpers) {
  const { client, queries, mutations, setState } = helpers;

  const id =
    typeof window !== 'undefined' &&
    window.localStorage.getItem(SHOPIFY_CHECKOUT_ID);

  if (id) {
    await client.request(queries.checkout, { id }).then((data) => {
      let checkout = data.node;

      // Check if any discontinued variants are in the checkout and if so remove them
      if (checkout.lineItems.edges.length > 0) {
        for (const { node } of checkout.lineItems.edges) {
          if (!node.variant) {
            checkout = client
              .request(mutations.checkoutLineItemsRemove, {
                checkoutId: id,
                lineItemIds: [node.id],
              })
              .then((data) => data.checkoutLineItemsRemove.checkout)
              .then((checkout) =>
                setState((newState) => ({ ...newState, checkout }))
              );
          }
        }
      }
      setState((newState) => ({ ...newState, loading: false, checkout }));
    });
  } else {
    setState((newState) => ({
      ...newState,
      loading: false,
      checkout: undefined,
    }));
  }
}
