import styled from 'styled-components';

export const PurposeTemplate = styled.div`

`;

export const Headline = styled.h1`
  font-size: 2.125rem;
  text-align: center;
  margin: 0 0 40px;
  font-style: italic;
  letter-spacing: 0.04em;
  color: ${({ theme }) => theme.text.white};


  span {
    color: ${({ $color, theme }) => theme.hue[$color]};
    font-weight: 600;
    font-style: italic;
    font-family: ${({ theme }) => theme.font.secondary};
  }

  @media(min-width: ${({ theme }) => theme.size.md}) {
    font-size: 4rem;
  }
`;

export const Quote = styled.p`
  font-size: 1.5rem;
  text-align: center;
  width: fit-content;
  margin: 0 auto;
  padding: 0 16px 20px;
  color: ${({ theme }) => theme.text.white};
  font-weight: 600;
  font-style: italic;
  font-family: ${({ theme }) => theme.font.secondary};
  border-bottom: 1px solid ${({ theme }) => theme.text.white};

  @media(min-width: ${({ theme }) => theme.size.md}) {
    font-size: 1.75rem;
  }
`;
