export default async function addLineItem(helpers, lineItem) {
  const { state, setState, client, mutations } = helpers;

  if (state.loading) {
    throw new Error('Cannot call addLineItem when the checkout is loading.');
  }

  // If we don't have a checkout then create one
  if (!state.checkout) {
    return client
      .request(mutations.checkoutCreate, {
        input: {
          lineItems: [lineItem],
        },
      })
      .then((data) => data.checkoutCreate.checkout)
      .then((checkout) => setState((newState) => ({ ...newState, checkout })));
  }

  // Search for the product in the checkout and if it exists then combine the quantities
  for (const { node } of state.checkout.lineItems.edges) {
    if (lineItem.id === node.variant.id) {
      return client
        .request(mutations.checkoutLineItemsUpdate, {
          checkoutId: state.checkout.id,
          lineItems: [
            { id: node.id, quantity: lineItem.quantity + node.quantity },
          ],
        })
        .then((data) => data.checkoutLineItemsUpdate.checkout)
        .then((checkout) =>
          setState((newState) => ({ ...newState, checkout }))
        );
    }
  }

  // If the product isn't already in the checkout then add it
  return client
    .request(mutations.checkoutLineItemsAdd, {
      checkoutId: state.checkout.id,
      lineItems: [lineItem],
    })
    .then((data) => data.checkoutLineItemsAdd.checkout)
    .then((checkout) => setState((newState) => ({ ...newState, checkout })));
}
