import React, { useState } from 'react';
import propTypes from 'prop-types';

import SEO from 'src/components/SEO';
import SocialIcons from 'src/components/SocialIcons';
import { Text, Textarea } from 'src/components/Inputs';
import NetlifyForm from 'src/components/NetlifyForm';

import * as S from './general-inquiries.style';

const initialValue = {
  firstName: '',
  lastName: '',
  emailAddress: '',
  subject: '',
  message: '',
};

const GeneralInquiriesPage = ({ useAttributes }) => {
  useAttributes({ initiallyTransparent: false });
  const [value, setValue] = useState(initialValue);

  return (
    <S.GeneralInquiriesPage>
      <SEO title="General Inquiries | Absolute Training Centre" />
      <S.Watermark>Contact</S.Watermark>
      <S.Title>Contact Us</S.Title>
      <S.Text>
        Thank you for wanting to contact us!
        <br />
        We look forward to hearing from you.
      </S.Text>
      <S.Wrapper>
        <S.FormWrapper>
          <NetlifyForm
            name="General Inquiries Form"
            template={[
              'FirstName LastName',
              'EmailAddress Subject',
              'Message Message',
            ]}
            onSubmit={() => setValue(initialValue)}
          >
            <Text
              required
              id="FirstNameInput"
              label="First Name"
              name="FirstName"
              value={value.firstName}
              onChange={(newValue) =>
                setValue((state) => ({ ...state, firstName: newValue }))
              }
            />
            <Text
              required
              id="LastNameInput"
              label="Last Name"
              name="LastName"
              value={value.lastName}
              onChange={(newValue) =>
                setValue((state) => ({ ...state, lastName: newValue }))
              }
            />
            <Text
              required
              type="email"
              id="EmailAddressInput"
              label="Email Address"
              name="EmailAddress"
              value={value.emailAddress}
              onChange={(newValue) =>
                setValue((state) => ({ ...state, emailAddress: newValue }))
              }
            />
            <Text
              required
              id="SubjectInput"
              label="Subject"
              name="Subject"
              value={value.subject}
              onChange={(newValue) =>
                setValue((state) => ({ ...state, subject: newValue }))
              }
            />
            <Textarea
              required
              id="MessageInput"
              label="Message"
              name="Message"
              value={value.message}
              onChange={(newValue) =>
                setValue((state) => ({ ...state, message: newValue }))
              }
            />
          </NetlifyForm>
        </S.FormWrapper>
        <S.Info>
          <S.InfoItem>
            <S.InfoItemTitle>Email Address</S.InfoItemTitle>
            <S.InfoItemLink href="mailto: connect@absolutetrainingcentre.com">
              connect@absolutetrainingcentre.com
            </S.InfoItemLink>
          </S.InfoItem>
          <S.Line />
          <S.LogoLink to="/">
            <S.Logo />
          </S.LogoLink>
          <SocialIcons
            size="large"
            family="grey"
            links={[
              {
                icon: 'Instagram',
                url: 'https://www.instagram.com/absolutetrainingcentre/',
              },
              {
                icon: 'Facebook',
                url: 'https://www.facebook.com/Absolute-Training-Centre-104081782112714',
              },
              {
                icon: 'Youtube',
                url: 'https://www.youtube.com/channel/UC9LpeUxgYTM1iqFnLaaBI3g',
              },
            ]}
          />
        </S.Info>
      </S.Wrapper>
    </S.GeneralInquiriesPage>
  );
};

GeneralInquiriesPage.defaultProps = {
  useAttributes: undefined,
};

GeneralInquiriesPage.propTypes = {
  useAttributes: propTypes.func,
};

export default GeneralInquiriesPage;
