import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';

import * as S from './Quantity.style';

const Quantity = ({ value, setValue, className }) => {
  const [innerValue, setInnerValue] = useState(value);

  const handleChange = (newValue) => {
    const newValueInt = parseInt(newValue, 10);

    if (newValueInt < 1) {
      // eslint-disable-next-line no-alert
      alert('Product quantity cannot be less than one.');
      setInnerValue(value);
    } else {
      if (innerValue !== newValue) {
        setInnerValue(newValue);
      }
      setValue(newValueInt);
    }
  };

  const handleKeyUp = (event) => {
    // If the enter key is pressed act like the button was clicked
    if (event.keyCode === 13) {
      event.preventDefault();
      event.target.blur();
      handleChange(event.target.value);
    }
  };

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  return (
    <S.Quantity className={className}>
      <S.Decrement role="button" onClick={() => handleChange(value - 1)}>
        -
      </S.Decrement>
      <S.Input
        type="number"
        value={innerValue}
        onKeyUp={handleKeyUp}
        onChange={({ target: { value: newValue } }) => setInnerValue(newValue)}
        onBlur={() => handleChange(innerValue)}
      />
      <S.Increment role="button" onClick={() => handleChange(value + 1)}>
        +
      </S.Increment>
    </S.Quantity>
  );
};

Quantity.defaultProps = {
  className: undefined,
};

Quantity.propTypes = {
  value: propTypes.number.isRequired,
  setValue: propTypes.func.isRequired,
  className: propTypes.string,
};

export default Quantity;
