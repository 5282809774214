import React from 'react';

import * as S from './Spinner.style';

const Spinner = () => (
  <S.Spinner>
    <S.CircleWrapper>
      <S.Circle />
      <S.Circle />
      <S.Circle />
      <S.Circle />
      <S.Circle />
      <S.Circle />
      <S.Circle />
      <S.Circle />
      <S.Circle />
      <S.Circle />
      <S.Circle />
      <S.Circle />
    </S.CircleWrapper>
  </S.Spinner>
);

export default Spinner;
