import styled from 'styled-components';
import { Link as COMP1 } from 'gatsby';

import { PlayArrow as SVG1 } from '@styled-icons/material-rounded';

export const Submenu = styled.div`
  @media(min-width: ${({ theme }) => theme.size.md}) {
    display: inline-block;
    position: relative;
  }
`;

export const Title = styled.button`
  position: relative;
  display: block;
  width: fit-content;
  font-size: 1.25rem;
  text-decoration: none;

  transition: color 0.15s;
  padding: 10px 30px 10px 0;
  cursor: pointer;
  color: ${({ theme }) => theme.text.base};

  border: none;
  background: none;

  :hover {
    color: ${({ theme }) => theme.text.hover.base};
  }

  :active {
    color: ${({ theme }) => theme.text.active.base};
  }

  @media(min-width: ${({ theme }) => theme.size.md}) {
    display: inline-block;
    font-size: 1rem;
    padding-right: 20px;
    margin-right: 24px;


    color: ${({ theme }) => theme.text.white};

    :hover {
      color: ${({ theme }) => theme.text.hover.white};
    }

    :active {
      color: ${({ theme }) => theme.text.active.white};
    }
  }
`;

export const TitleArrow = styled(SVG1)`
  position: absolute;
  top: calc(50% - 10px);
  right: 0;
  width: 20px;
  height: 20px;
  pointer-events: none;
  will-change: transform;
  transition: transform 0.15s;
  transform: rotate(${({ $open }) => ($open ? '0deg' : '90deg')});
`;

export const CollapsibleArea = styled.div`
  overflow: hidden;
  transition: max-height 0.2s, padding 0.2s;

  max-height: ${({ $maxHeight }) => `${$maxHeight}px`};
  padding: ${({ $maxHeight }) => $maxHeight === 0 ? '0' : '4px 0 20px'};

  @media(min-width: ${({ theme }) => theme.size.md}) {
    position: absolute;
    top: 2.4rem;
    border-radius: 8px;
    padding: 0;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
    background: ${({ theme }) => theme.bg.base};
  }
`;

export const Link = styled(COMP1)`
  position: relative;
  display: block;
  width: fit-content;
  font-size: 1.125rem;
  text-decoration: none;

  transition: color 0.15s;
  padding: 10px 30px 10px 16px;
  color: ${({ theme }) => theme.text.base};

  :hover {
    color: ${({ theme }) => theme.text.hover.base};
  }

  :active {
    color: ${({ theme }) => theme.text.active.base};
  }

  @media(min-width: ${({ theme }) => theme.size.md}) {
    font-size: 1rem;
    word-wrap: normal;
    white-space: nowrap;
    width: 100%;

    :hover {
      background: ${({ theme }) => theme.bg.lightGrey};
    }
  }
`;
