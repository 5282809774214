import React from 'react';
import Animate from 'react-animate-on-scroll';

import Truth from 'src/assets/svg/truth.svg';
import Integrity from 'src/assets/svg/integrity.svg';
import Accountability from 'src/assets/svg/accountability.svg';
import Freedom from 'src/assets/svg/freedom.svg';

import * as S from './CoreValues.style';

const values = [
  {
    name: 'Truth',
    icon: Truth,
    text: 'Truth is the only thing that actually allows people to be who they are. Truth is being vulnerable with ourselves, which allows us to be open and honest with others. That builds relationships.',
  },
  {
    name: 'Accountability',
    icon: Accountability,
    text: 'Accountability builds trust, builds character and builds You. To reach your true potential you have to be accountable to the people in your your life and the things you commit to doing. ',
  },
  {
    name: 'Integrity',
    icon: Integrity,
    text: 'Integrity is honesty in the heart. It’s doing the right thing when no one is watching. That’s what elevates people to reach their true potential.',
  },
  {
    name: 'Freedom',
    icon: Freedom,
    text: 'Freedom to be who we’ve been created to be, not programmed to be. Freedom is honor, integrity, accountability, generosity all encompassed. Freedom is people being responsible for their actions and the integrity to make it right.',
  },
];

const CoreValues = () => (
  <S.CoreValues>
    <S.Square />
    <S.Watermark aria-hidden="true">Values</S.Watermark>
    <Animate animateOnce animateIn="fadeInUp">
      <S.Title>Core Values</S.Title>
    </Animate>
    <S.Values>
      {values.map((value, i) => (
        <S.Value
          key={value.name}
          animateOnce
          animateIn={i % 2 === 0 ? 'slideInLeft' : 'slideInRight'}
        >
          <S.Wrapper>
            <value.icon />
            <S.Name>{value.name}</S.Name>
          </S.Wrapper>
          <S.Text>{value.text}</S.Text>
        </S.Value>
      ))}
    </S.Values>
  </S.CoreValues>
);

export default CoreValues;
