import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Animate from 'react-animate-on-scroll';

import ArticleGrid from 'src/components/ArticleGrid';

import * as S from './RecentArticles.style';

const RecentArticles = () => {
  const data = useStaticQuery(query);

  return (
    <S.RecentArticles>
      <Animate animateOnce animateIn="fadeInUp">
        <S.Title>Recent Articles</S.Title>
      </Animate>
      <Animate animateOnce animateIn="fadeIn">
        <S.Text>
          Every shared story relates to Well-being, Family, Business and Life.
          Each area focuses on foundational principles and experiences to
          inspire you to greater possibilities. Be encouraged by stories of
          overcoming and breakthrough. Be inspired.
        </S.Text>
      </Animate>
      <ArticleGrid family="scroll" articles={data.recentArticles.nodes} />
    </S.RecentArticles>
  );
};

const query = graphql`
  query {
    recentArticles: allMarkdownRemark(
      limit: 4
      sort: { fields: date, order: DESC }
      filter: { frontmatter: { type: { eq: "article" } } }
    ) {
      nodes {
        ...ArticleGridFragment
      }
    }
  }
`;

export default RecentArticles;
