import styled from 'styled-components';

import COMP1 from 'src/components/Button';

export const RelatedContent = styled.div`
  padding: 0;
  max-width: 1200px;
  margin: 0 auto 120px;
`;

export const Title = styled.h2`
  font-size: 2.5rem;
  margin: 0 0 8px;
  padding: 0 16px;
  font-weight: 600;
  font-style: italic;
  font-family: ${({ theme }) => theme.font.secondary};

  @media(min-width: ${({ theme }) => theme.size.md}) {
    font-size: 3.25rem;
  }
`;

export const Wrapper  = styled.div`
  margin: 0 0 64px;
`;

export const Button = styled(COMP1)`
  display: block;
  margin: 24px auto 0;
`;
