import { useQueryParam, withDefault, StringParam } from 'use-query-params';

const useSelectedVariant = (variants) => {
  const [selectedVariantId, setSelectedVariantId] = useQueryParam(
    'variant',
    withDefault(StringParam, variants[0].legacyResourceId)
  );

  const selectedVariant = variants.find(
    ({ legacyResourceId }) => legacyResourceId === selectedVariantId
  );

  if (!selectedVariant) {
    setSelectedVariantId(variants[0].legacyResourceId);
  }

  return [selectedVariant, setSelectedVariantId];
};

export default useSelectedVariant;
