import React, { useContext, useEffect } from 'react';
import { useQueryParams, StringParam } from 'use-query-params';

import AlertContext from 'src/contexts/AlertContext';

import * as S from './Alert.style';

const statuses = ['success', 'error'];

const Alert = () => {
  const { alert, pushAlert, resetAlert } = useContext(AlertContext);
  const [params] = useQueryParams({
    status: StringParam,
    message: StringParam,
  });

  useEffect(() => {
    if (statuses.includes(params.status) && params.message) {
      pushAlert(params.status, params.message);
    }
  }, []);

  return (
    <S.Alert $status={alert.status}>
      {alert.message && (
        <>
          {alert.status === 'success' && <S.Success />}
          {alert.status === 'error' && <S.Error />}
          <S.Message>{alert.message}</S.Message>
          <S.Button onClick={resetAlert} aria-label="Close">
            <S.Close />
          </S.Button>
        </>
      )}
    </S.Alert>
  );
};

export default Alert;
