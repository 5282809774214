import styled from 'styled-components';

import COMP1 from 'src/components/Button';

import { Close as SVG1 } from '@styled-icons/material-rounded';

export const ArticleCTA = styled.div`
  padding: 0 16px;
  margin: 0 0 100px;

  @media(min-width: 1400px) {
    display: flex;
    align-items: center;
    position: fixed;
    top: 80px;
    bottom: 0;
    width: 260px;
    margin: 0;
    transition: 0.2s left;
    z-index: 1;
    left: ${({ $open }) => $open ? '16px' : '-260px'};
  }
`;

export const Wrapper = styled.form`
  position: relative;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
  background: ${({ theme }) => theme.bg.base};
`;

export const Title = styled.h4`
  font-size: 1.75rem;
  margin: 0 0 16px;
  font-weight: 600;
  font-style: italic;
  font-family: ${({ theme }) => theme.font.secondary};

  @media(min-width: 1400px) {
    font-size: 1.625rem;
  }
`;

export const Text = styled.p`
  font-size: 1rem;
  margin: 0 0 20px;

  @media(min-width: 1400px) {
    font-size: 0.875rem;
  }
`;

export const Input = styled.input`
  display: block;
  width: 100%;
  height: 40px;
  background: none;
  border-radius: 4px;
  padding: 8px 16px;
  border: 1px solid ${({ theme }) => theme.bg.mediumGrey};
  margin: 0 0 20px;
`;

export const Button = styled(COMP1)`
  display: block;
  width: 100%;
  margin: 0 0 24px;
`;

export const Line = styled.hr`
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid ${({ theme }) => theme.bg.mediumGrey};
`;

export const ShareText = styled.p`
  font-size: 1rem;
  margin: 0 0 12px;
`;

export const CloseButton = styled.button`
  display: none;
  position: absolute;
  top: 8px;
  right: 8px;
  background: none;
  padding: 0;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.text.light};

  @media(min-width: 1400px) {
    display: block;
  }
`;

export const CloseIcon = styled(SVG1)`
  display: block;
  width: 20px;
  height: 20px;
  padding: 2px;
  fill: ${({ theme }) => theme.text.light};
`;
