import React from 'react';
import propTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Animate from 'react-animate-on-scroll';

import * as S from './Statement.style';

const Statement = ({ type }) => {
  const data = useStaticQuery(query);

  return (
    <S.Statement>
      <S.Watermark aria-hidden>
        {type === 'purpose' && 'Purpose'}
        {type === 'mission' && 'Mission'}
      </S.Watermark>
      <Animate animateOnce animateIn="fadeIn">
        <S.ClusterOne
          alt="Cluster of images depicting Absolute's goals"
          image={data.clusterOne.childImageSharp.gatsbyImageData}
        />
      </Animate>
      <Animate animateOnce animateIn="fadeIn">
        <S.ClusterTwo
          alt="Cluster of images depicting Absolute's goals"
          image={data.clusterTwo.childImageSharp.gatsbyImageData}
        />
      </Animate>
      <Animate animateOnce animateIn="fadeIn">
        <S.ClusterMobile
          alt="Cluster of images depicting Absolute's goals"
          image={data.clusterMobile.childImageSharp.gatsbyImageData}
        />
      </Animate>
      <Animate animateOnce animateIn="fadeInUp">
        <S.Title>
          Our {type === 'purpose' && 'Purpose'}
          {type === 'mission' && 'Mission'}
        </S.Title>
      </Animate>
      <S.Text>
        {type === 'purpose' && (
          <>
            Our Purpose is to Elevate, Equip and Immerse people to build a solid
            foundation for living their best life.
            <S.Tagline $type={type}>
              Your Well-being ・ Your Family ・ Your Business ・ Your Life
            </S.Tagline>
            Four pillars to build a solid foundation for living your best life.
            We purposely continue to grow our capacity to trust and to be
            trusted with sharing in these foundational pillars, empowering
            others to impact their workplace, communities, and most importantly
            their life.
            <br />
          </>
        )}
        {type === 'mission' && (
          <>
            Our mission is to train people by bringing out the best in them, and
            living with a standard of excellence and integrity with simple rock
            solid guiding principles. We train and teach people to build a solid
            foundation for their lives, so they can be free from guilt, shame,
            financial stress, eating disorders, addictions, and broken families.
            <S.Tagline $type={type}>...it all Starts with You!</S.Tagline>
          </>
        )}
      </S.Text>
      {type === 'mission' && (
        <S.Button outline to="/about-us">
          Learn More About Us
        </S.Button>
      )}
    </S.Statement>
  );
};

const query = graphql`
  query {
    clusterOne: file(relativePath: { eq: "png/mission-cluster-one.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    clusterTwo: file(relativePath: { eq: "png/mission-cluster-two.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    clusterMobile: file(
      relativePath: { eq: "png/mission-cluster-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;

Statement.propTypes = {
  type: propTypes.oneOf(['purpose', 'mission']).isRequired,
};

export default Statement;
