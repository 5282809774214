import styled from 'styled-components';

export const Spinner = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;

export const CircleWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
`;

export const Circle = styled.div`
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  animation: spinner 1.2s linear infinite;
  background: ${({ theme }) => theme.color.purple};

  &:nth-child(1) {
    animation-delay: 0s;
    top: 46.25%;
    left: 82.5%;
  }
  &:nth-child(2) {
    animation-delay: -0.1s;
    top: 27.5%;
    left: 77.5%;
  }
  &:nth-child(3) {
    animation-delay: -0.2s;
    top: 13.75%;
    left: 65%;
  }
  &:nth-child(4) {
    animation-delay: -0.3s;
    top: 8.75%;
    left: 46.25%;
  }
  &:nth-child(5) {
    animation-delay: -0.4s;
    top: 13.75%;
    left: 27.5%;
  }
  &:nth-child(6) {
    animation-delay: -0.5s;
    top: 27.5%;
    left: 13.75%;
  }
  &:nth-child(7) {
    animation-delay: -0.6s;
    top: 46.25%;
    left: 8.75%;
  }
  &:nth-child(8) {
    animation-delay: -0.7s;
    top: 65%;
    left: 13.75%;
  }
  &:nth-child(9) {
    animation-delay: -0.8s;
    top: 77.5%;
    left: 27.5%;
  }
  &:nth-child(10) {
    animation-delay: -0.9s;
    top: 82.5%;
    left: 46.25%;
  }
  &:nth-child(11) {
    animation-delay: -1s;
    top: 77.5%;
    left: 65%;
  }
  &:nth-child(12) {
    animation-delay: -1.1s;
    top: 65%;
    left: 77.5%;
  }

  @keyframes spinner {
    0%,
    20%,
    80%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
  }
`;
