import { useEffect } from 'react';
import { useLocation } from '@reach/router';

const useRouteChange = (func) => {
  const location = useLocation();

  // When the route changes run the function passed as an argument
  useEffect(func, [location.pathname, location.params]);
};

export default useRouteChange;
