import React from 'react';
import propTypes from 'prop-types';
import { graphql } from 'gatsby';

import SEO from 'src/components/SEO';

import PageHero from 'src/sections/PageHero';

import * as S from './shop.style';

const ShopPage = ({ data, useAttributes }) => {
  useAttributes({ initiallyTransparent: true });

  return (
    <S.ShopPage>
      <SEO title="Boutique | Absolute Training Centre" />
      <PageHero
        alt="Sign that says browse our boutique"
        title={
          <>
            Browse Our <span>Store</span>
          </>
        }
        image={data.file}
      />
      <S.ProductGrid paginate products={data.products.nodes} />
    </S.ShopPage>
  );
};

export const query = graphql`
  query {
    file(relativePath: { eq: "png/shop-hero.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    products: allShopifyProduct(filter: { isGiftCard: { eq: false } }) {
      nodes {
        ...ProductGridFragment
      }
    }
  }
`;

ShopPage.defaultProps = {
  useAttributes: undefined,
};

ShopPage.propTypes = {
  data: propTypes.shape({
    file: propTypes.shape({
      childImageSharp: propTypes.shape({
        gatsbyImageData: propTypes.object.isRequired,
      }).isRequired,
    }).isRequired,
    products: propTypes.shape({
      nodes: propTypes.arrayOf(propTypes.object).isRequired,
    }).isRequired,
  }).isRequired,
  useAttributes: propTypes.func,
};

export default ShopPage;
