import React, { useRef, useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { Link } from 'gatsby';

import * as S from './Button.style';

const Button = React.forwardRef(
  (
    {
      type,
      outline,
      to,
      href,
      tabIndex,
      messages,
      disabled,
      onClick,
      className,
      children,
    },
    ref
  ) => {
    const timeout = useRef();
    const [status, setStatus] = useState();

    useEffect(() => () => clearTimeout(timeout.current), []);

    let as;
    if (to && !href) as = Link;
    else if (!to && href) as = 'a';
    else if (!to && !href) as = 'button';

    const handleClick = (e) => {
      setStatus('loading');
      return Promise.resolve(onClick(e))
        .then((param) => {
          if (param) {
            setStatus('success');
          } else {
            setStatus('failure');
          }

          return param;
        })
        .finally((...params) => {
          timeout.current = setTimeout(() => setStatus(undefined), 5000);
          return params;
        });
    };

    return (
      <S.Button
        ref={ref}
        as={as}
        to={to}
        href={href}
        type={type}
        onClick={onClick && handleClick}
        tabIndex={tabIndex}
        disabled={disabled}
        className={className}
        $outline={outline}
        $status={status}
      >
        {children}
        {status === 'loading' && messages && (
          <S.Overlay>
            {messages.loading}
            <S.Dot $outline={outline} />
            <S.Dot $outline={outline} />
            <S.Dot $outline={outline} />
          </S.Overlay>
        )}
        {status === 'success' && messages && (
          <S.Overlay>
            <S.Animation>{messages.success} ✓</S.Animation>
          </S.Overlay>
        )}
        {status === 'failure' && messages && (
          <S.Overlay>
            <S.Animation>{messages.failure} ⨯</S.Animation>
          </S.Overlay>
        )}
      </S.Button>
    );
  }
);

Button.defaultProps = {
  type: undefined,
  outline: false,
  to: undefined,
  href: undefined,
  tabIndex: undefined,
  messages: undefined,
  disabled: false,
  onClick: undefined,
  className: undefined,
};

Button.propTypes = {
  type: propTypes.oneOf(['button', 'submit']),
  outline: propTypes.bool,
  to: propTypes.string,
  href: propTypes.string,
  tabIndex: propTypes.number,
  messages: propTypes.shape({
    loading: propTypes.string.isRequired,
    success: propTypes.string.isRequired,
    failure: propTypes.string.isRequired,
  }),
  disabled: propTypes.bool,
  onClick: propTypes.func,
  className: propTypes.string,
  children: propTypes.node.isRequired,
};

export default Button;
