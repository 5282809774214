import styled, { css } from 'styled-components';
import { Link as COMP1 } from 'gatsby';
import { GatsbyImage as COMP2 } from 'gatsby-plugin-image';

import COMP3 from 'src/components/Button';

export const EventGrid = styled.div`
  overflow-x: auto;
`;

export const Wrapper = styled.div`
  display: grid;
  grid-gap: 16px;
  padding: 16px;

  ${({ $family }) => $family === 'row' && css`
    margin: 0 auto;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, minmax(0, calc(1120px / 3)));
  `}

  ${({ $family }) => $family === 'scroll' && css`
    grid-auto-flow: column;
    grid-auto-columns: calc(1120px / 3);

    @media(min-width: ${({ theme }) => theme.size.xl}) {
      grid-auto-flow: row;
      margin: 0 auto;
      justify-content: center;
      grid-template-columns: repeat(auto-fill, minmax(0, calc(1120px / 3)));
    }
  `}
`;

export const Card = styled(COMP1)`
  display: grid;
  grid-gap: 16px;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  width: 100%;
  padding: 16px;
  overflow: hidden;
  border-radius: 16px;
  background: ${({ theme }) => theme.bg.base};
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.125);
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  transition: box-shadow 0.3s;
  font-size: ${({ $featured }) => $featured ? '1rem' : '0.875rem'};

  :hover {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
  }

  ${({ $featured }) => $featured && css`
    @media(min-width: ${({ theme }) => theme.size.xl}) {
      grid-gap: 32px;
      grid-column: 1 / -1;
      grid-template-rows: 1fr;
      grid-template-columns: 1fr 1fr;
    }
  `}
`;

export const Image = styled(COMP2)`
  display: block;
  border-radius: 16px;
  height: 0;
  padding-bottom: calc(100% * 3 / 5);
`;

export const Content = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-rows: 1fr auto;
  align-content: end;
`;

export const Meta = styled.div`

`;

export const Purposes = styled.div`

`;

export const Purpose = styled.span`
  font-size: 12px;
  display: inline-block;
  line-height: 1;
  padding: 4px 12px;
  border-radius: 12px;
  margin: 0 8px 16px 0;
  border: 2px solid ${({ $color, theme }) => theme.color[$color]};
`;

export const Title = styled.h4`
  font-size: 1.5rem;
  margin: 0 0 16px;
`;

export const Excerpt = styled.p`
  display: none;
  margin: 0 0 20px;

  ${({ $featured }) => $featured && css`
    @media(min-width: ${({ theme }) => theme.size.xl}) {
      display: block;
    }
  `}
`;

export const Info = styled.p`
  font-style: italic;
  margin: 0 0 4px;
`;

export const Label = styled.span`
  font-style: normal;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.text.light};
`;

export const Link = styled.button`
  width: 100%;
  display: block;
  padding: 16px 0 0;
  text-align: center;
  color: ${({ theme }) => theme.text.base};
  background: none;
  border-left: none;
  border-right: none;
  border-bottom: none;
  cursor: pointer;
  border-top: 1px solid ${({ theme }) => theme.bg.lightGrey};

  :hover {
    color: ${({ theme }) => theme.text.hover.base};
  }

  :active {
    color: ${({ theme }) => theme.text.active.base};
  }
`;

export const LoadMore = styled(COMP3)`
  display: block;
  margin: 40px auto 0;
`;
