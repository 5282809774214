import React from 'react';
import propTypes from 'prop-types';
import { graphql, navigate } from 'gatsby';

import { mapPurposeToColor } from 'src/utilities/helpers';

import SEO from 'src/components/SEO';
import PurposeMenu from 'src/components/PurposeMenu';

import PageHero from 'src/sections/PageHero';
import PurposeDescription from 'src/sections/PurposeDescription';
import RelatedContent from 'src/sections/RelatedContent';

import * as S from './purpose.style';

const PurposeTemplate = ({ data, useAttributes }) => {
  useAttributes({ initiallyTransparent: true });

  return (
    <S.PurposeTemplate>
      <SEO
        title={`The Purpose of ${data.purpose.frontmatter.name} | Absolute Training Centre`}
        description={data.purpose.excerpt}
      />
      <PageHero wide alt="" image={data.purpose.frontmatter.image}>
        <S.Headline $color={mapPurposeToColor(data.purpose.frontmatter.name)}>
          The Purpose of <span>{data.purpose.frontmatter.name}</span>
        </S.Headline>
        <S.Quote>{data.purpose.frontmatter.quote}</S.Quote>
      </PageHero>
      <PurposeMenu
        family="link"
        purpose={data.purpose.frontmatter.name}
        setPurpose={(newPurpose) =>
          navigate(`/purpose/${newPurpose.toLowerCase()}`, {
            state: { updateScroll: false },
          })
        }
      />
      <PurposeDescription purpose={data.purpose} />
      <RelatedContent
        links={{
          articles: `/blog/${data.purpose.slug}`,
          events: `/events/${data.purpose.slug}`,
        }}
        articles={data.relatedArticles.nodes}
        events={data.relatedEvents.nodes}
      />
    </S.PurposeTemplate>
  );
};

export const query = graphql`
  query ($purpose: String) {
    purpose: markdownRemark(
      frontmatter: { type: { eq: "purpose" }, name: { eq: $purpose } }
    ) {
      slug
      excerpt
      frontmatter {
        name
        quote
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      ...PurposeDescriptionFragment
    }
    relatedArticles: allMarkdownRemark(
      limit: 7
      sort: { fields: date, order: DESC }
      filter: {
        frontmatter: { type: { eq: "article" }, purposes: { eq: $purpose } }
      }
    ) {
      nodes {
        ...ArticleGridFragment
      }
    }
    relatedEvents: allMarkdownRemark(
      limit: 7
      sort: { fields: date, order: DESC }
      filter: {
        frontmatter: { type: { eq: "event" }, purposes: { eq: $purpose } }
      }
    ) {
      nodes {
        ...EventGridFragment
      }
    }
  }
`;

PurposeTemplate.defaultProps = {
  useAttributes: undefined,
};

PurposeTemplate.propTypes = {
  data: propTypes.shape({
    purpose: propTypes.shape({
      slug: propTypes.string.isRequired,
      excerpt: propTypes.string.isRequired,
      frontmatter: propTypes.shape({
        name: propTypes.string.isRequired,
        quote: propTypes.string.isRequired,
        image: propTypes.shape({
          childImageSharp: propTypes.shape({
            gatsbyImageData: propTypes.object.isRequired,
          }).isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
    relatedArticles: propTypes.shape({
      nodes: propTypes.arrayOf(propTypes.object).isRequired,
    }).isRequired,
    relatedEvents: propTypes.shape({
      nodes: propTypes.arrayOf(propTypes.object).isRequired,
    }).isRequired,
  }).isRequired,
  useAttributes: propTypes.func,
};

export default PurposeTemplate;
