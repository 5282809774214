import styled from 'styled-components';

import COMP1 from 'src/components/Quote';
import COMP2 from 'src/components/NetlifyForm';

export const InviteToSpeakPage = styled.div`

`;



export const Watermark = styled.span`
  display: none;

  @media(min-width: ${({ theme }) => theme.size.xl}) {
    display: inline;
    font-size: 160px;
    position: absolute;
    top: -40px;
    right: -20px;
    z-index: -1;
    font-weight: 800;
    color: ${({ theme }) => theme.bg.mediumGrey};
  }
`;


export const Quote = styled(COMP1)`
  margin: 32px auto 48px;

  @media(min-width: ${({ theme }) => theme.size.lg}) {
    margin: 60px auto 0;
  }
`;

export const FormTitle = styled.h2`
  font-size: 2rem;
  text-align: center;
  padding: 0 16px;
  font-weight: 600;
  font-style: italic;
  font-family: ${({ theme }) => theme.font.secondary};

  @media(min-width: ${({ theme }) => theme.size.lg}) {
    font-size: 3rem;
  }
`;

export const NetlifyForm = styled(COMP2)`
  max-width: 1200px;
  padding: 0 16px;
  margin: 0 auto 120px;
`;
