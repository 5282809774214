import styled, { css } from 'styled-components';
import { Link as COMP1 } from 'gatsby';

import {
  Menu as SVG1,
  Close as SVG2,
  ShoppingCart as SVG3,
} from '@styled-icons/material-rounded';

import SVG4 from 'src/assets/svg/logo-condensed-white.svg';

export const Header = styled.div`
  position: ${({ $scrolled }) => $scrolled ? 'fixed' : 'absolute'};
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  transition: background 0.25s;
  box-shadow: ${({ $transparent, $scrolled }) => $transparent && !$scrolled ? 'none' : '0 4px 4px 0 rgba(0, 0, 0, 0.2)'};
  background: ${({ $transparent, $menuOpen, $desktop, $scrolled, theme }) => $transparent && (!$menuOpen || $desktop) && !$scrolled ? 'none' : theme.custom.purple};
`;

export const Wrapper = styled.div`
  height: 56px;
  padding: 8px 16px;

  @media(min-width: ${({ theme }) => theme.size.md}) {
    height: 80px;
    margin: 0 auto;
    max-width: 1200px;
    padding: 16px 16px;
  }
`;

export const LogoLink = styled(COMP1)`
  display: block;
  float: left;
  width: fit-content;
`;

export const Logo = styled(SVG4)`
  width: auto;
  height: 44px;
  padding: 4px 0;
  display: block;
  margin: -2px 0;

  @media(min-width: ${({ theme }) => theme.size.md}) {
    height: 56px;
    padding: 6px 0;
    margin: -4px 0;
  }
`;

export const MenuButton = styled.button`
  padding: 0;
  margin: 0;
  float: right;
  border: none;
  background: none;

  @media(min-width: ${({ theme }) => theme.size.md}) {
    display: none;
  }
`;

export const OpenIcon = styled(SVG1)`
  width: 40px;
  height: 40px;
  fill: ${({ theme }) => theme.text.white};
`;

export const CloseIcon = styled(SVG2)`
  width: 40px;
  height: 40px;
  fill: ${({ theme }) => theme.text.white};
`;

export const Menu = styled.nav`
  display: block;
  position: fixed;
  top: 56px;
  left: 0;
  right: 0;
  will-change: height, padding;
  transition: height 0.25s, padding 0.25s;
  background: ${({ theme }) => theme.bg.base};

  ${({ $open }) => !$open && css`
    height: 0px;
    padding: 0px 16px;
    overflow-y: hidden;
  `}

  ${({ $open }) => $open && css`
    height: calc(100% - 56px);
    padding: 40px 16px 60px;
    overflow-y: auto;
  `}

  @media(min-width: ${({ theme }) => theme.size.md}) {
    position: static;
    top: auto;
    left: auto;
    right: auto;
    background: none;
    will-change: unset;
    transition: none;
    height: auto;
    padding: 4.8px 0;
    overflow-y: visible;
    width: fit-content;
    float: right;
  }
`;

export const MenuLink = styled(COMP1)`
  display: block;
  font-size: 1.25rem;
  text-decoration: none;

  transition: color 0.15s;
  padding: 10px 0;
  color: ${({ theme }) => theme.text.base};

  :hover {
    color: ${({ theme }) => theme.text.hover.base};
  }

  :active {
    color: ${({ theme }) => theme.text.active.base};
  }

  @media(min-width: ${({ theme }) => theme.size.md}) {
    display: inline-block;
    font-size: 1rem;
    margin-right: 24px;


    color: ${({ theme }) => theme.text.white};

    :hover {
      color: ${({ theme }) => theme.text.hover.white};
    }

    :active {
      color: ${({ theme }) => theme.text.active.white};
    }
  }
`;

export const Cart = styled(COMP1)`
  display: block;
  font-size: 1.25rem;
  text-decoration: none;

  transition: color 0.15s, fill 0.15s;
  padding: 10px 0;
  cursor: pointer;
  fill: ${({ theme }) => theme.text.base};
  color: ${({ theme }) => theme.text.base};

  :hover {
    fill: ${({ theme }) => theme.text.hover.base};
    color: ${({ theme }) => theme.text.hover.base};
  }

  :active {
    fill: ${({ theme }) => theme.text.active.base};
    color: ${({ theme }) => theme.text.active.base};
  }

  @media(min-width: ${({ theme }) => theme.size.md}) {
    display: inline-block;
    font-size: 1rem;
    margin-left: 20px;


    fill: ${({ theme }) => theme.text.white};
    color: ${({ theme }) => theme.text.white};

    :hover {
      fill: ${({ theme }) => theme.text.hover.white};
      color: ${({ theme }) => theme.text.hover.white};
    }

    :active {
      fill: ${({ theme }) => theme.text.active.white};
      color: ${({ theme }) => theme.text.active.white};
    }
  }
`;

export const CartIcon = styled(SVG3)`
  width: 24px;
  height: 24px;
  float: left;
  margin-right: 8px;

  @media(min-width: ${({ theme }) => theme.size.md}) {
    width: 1.25rem;
    height: 1.25rem;
    margin: -0.05rem 4px -0.05rem 0;
  }
`;

export const CartText = styled.span`
  position: relative;
`;

export const CartIndicator = styled.span`
  position: absolute;
  left: calc(100% + 4px);
`;
