import styled from 'styled-components';
import { Link as COMP1 } from 'gatsby';

import SVG1 from 'src/assets/svg/logo-condensed-white.svg';

export const Footer = styled.footer`
  position: relative;
  background: ${({ theme }) => theme.color.purple};
`;

export const Wrapper = styled.div`
  max-width: 1200px;
  margin: auto;
  padding: 48px 16px 60px;

  @media(min-width: ${({ theme }) => theme.size.md}) {
    display: flex;
  }
`;

export const Info = styled.div`
  margin-bottom: 40px;

  @media(min-width: ${({ theme }) => theme.size.md}) {
    padding-right: 120px;
    margin-right: 120px;
    margin-bottom: 0;
    border-right: 1px solid ${({ theme }) => theme.bg.base};
  }
`;

export const LogoLink = styled(COMP1)`
  display: block;
  margin: 0 0 32px;
  width: fit-content;
`;

export const Logo = styled(SVG1)`
  width: auto;
  height: 68px;
  display: block;
`;

export const Name = styled.h5`
  font-size: 0.875rem;
  font-weight: 400;
  margin: 0 0 4px;
  color: ${({ theme }) => theme.text.white};
`;

export const Location = styled.h5`
  font-size: 0.875rem;
  font-weight: 400;
  margin: 0 0 12px;
  color: ${({ theme }) => theme.bg.mediumGrey};
`;

export const Directory = styled.nav`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  grid-gap: 32px;

  @media(min-width: ${({ theme }) => theme.size.md}) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
  }
`;

export const LinkGroup = styled.div`

`;

export const LinkGroupTitle = styled.h3`
  font-size: 1.125rem;
  margin: 0 0 16px;
  color: ${({ theme }) => theme.text.white};
`;

export const Link = styled(COMP1)`
  display: block;
  font-size: 1rem;
  color: ${({ theme }) => theme.text.white};
  text-decoration: none;
  margin-bottom: 12px;

  :hover {
    color: ${({ theme }) => theme.text.hover.white};
  }

  :active {
    color: ${({ theme }) => theme.text.active.white};
  }
`;
