import React from 'react';
import propTypes from 'prop-types';
import { useLocation } from '@reach/router';

import * as S from './Back.style';

const Back = ({ type }) => {
  const location = useLocation();

  let returnTo;

  if (type === 'article') {
    returnTo = '/blog';

    if (location.previous && location.previous.href.startsWith('/blog/')) {
      returnTo = location.previous.href;
    }
  }

  if (type === 'event') {
    returnTo = '/events';

    if (location.previous && location.previous.href.startsWith('/events/')) {
      returnTo = location.previous.href;
    }
  }

  if (type === 'product') {
    returnTo = '/shop';
  }

  return (
    <S.Back>
      <S.Wrapper>
        <S.Link to={returnTo}>
          <S.LinkArrow />
          Back to {type === 'article' && 'blog'}
          {type === 'event' && 'events'}
          {type === 'product' && 'shop'}
        </S.Link>
      </S.Wrapper>
    </S.Back>
  );
};

Back.propTypes = {
  type: propTypes.oneOf(['article', 'event', 'product']).isRequired,
};

export default Back;
