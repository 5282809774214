import React from 'react';
import propTypes from 'prop-types';
import { graphql, navigate } from 'gatsby';

import SEO from 'src/components/SEO';
import PurposeMenu from 'src/components/PurposeMenu';

import PageHero from 'src/sections/PageHero';

import * as S from './blog.style';

const BlogTemplate = ({ data, useAttributes }) => {
  useAttributes({ initiallyTransparent: true });

  return (
    <S.BlogTemplate>
      <SEO
        title={`
          ${data.purpose ? `${data.purpose.frontmatter.name} ` : ''}
          Blog | Absolute Training Centre
        `}
        description="Awaken yourself to reach your true potential with articles written by Absolute Training Centre."
      />
      <PageHero
        alt="A notebook with a pencil on top"
        image={data?.purpose?.frontmatter?.image || data.file}
      >
        <S.Headline>
          Articles To <span>Inspire</span>
        </S.Headline>
        <S.Purpose>
          Our Vision is to bring unity to a world programmed for division.
        </S.Purpose>
        <S.Line />
      </PageHero>
      <PurposeMenu
        showAll
        family="link"
        purpose={data?.purpose?.frontmatter?.name || 'All'}
        setPurpose={(newPurpose) =>
          navigate(
            newPurpose.toLowerCase() !== 'all'
              ? `/blog/${newPurpose.toLowerCase()}`
              : '/blog',
            { state: { updateScroll: false } }
          )
        }
      />
      <S.ArticleGrid
        paginate
        family="row"
        articles={data?.allArticles?.nodes || data?.filteredArticles?.nodes}
      />
    </S.BlogTemplate>
  );
};

export const query = graphql`
  query ($purpose: String, $filter: Boolean = false) {
    file(relativePath: { eq: "png/blog-hero.png" }) @skip(if: $filter) {
      childImageSharp {
        gatsbyImageData
      }
    }
    purpose: markdownRemark(
      frontmatter: { type: { eq: "purpose" }, name: { eq: $purpose } }
    ) @include(if: $filter) {
      frontmatter {
        name
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    allArticles: allMarkdownRemark(
      sort: { fields: date, order: DESC }
      filter: { frontmatter: { type: { eq: "article" } } }
    ) @skip(if: $filter) {
      nodes {
        ...ArticleGridFragment
      }
    }
    filteredArticles: allMarkdownRemark(
      sort: { fields: date, order: DESC }
      filter: {
        frontmatter: { type: { eq: "article" }, purposes: { in: [$purpose] } }
      }
    ) @include(if: $filter) {
      nodes {
        ...ArticleGridFragment
      }
    }
  }
`;

BlogTemplate.defaultProps = {
  useAttributes: undefined,
};

BlogTemplate.propTypes = {
  data: propTypes.shape({
    file: propTypes.object.isRequired,
    purpose: propTypes.shape({
      frontmatter: propTypes.shape({
        name: propTypes.string.isRequired,
        image: propTypes.object.isRequired,
      }).isRequired,
    }),
    allArticles: propTypes.shape({
      nodes: propTypes.array.isRequired,
    }),
    filteredArticles: propTypes.shape({
      nodes: propTypes.array.isRequired,
    }),
  }).isRequired,
  useAttributes: propTypes.func,
};

export default BlogTemplate;
