import React from 'react';

import * as S from './PartnerDescription.style';

const partners = [
  'Organizations',
  'Businesses',
  'Schools',
  'Churches',
  'Governments',
];

const PartnerDescription = () => (
  <S.PartnerDescription>
    <S.Watermark>Partner</S.Watermark>
    <S.Title>Who We Partner With</S.Title>
    <S.Wrapper>
      <S.Partners>
        {partners.map((partner, i) => (
          <React.Fragment key={partner}>
            {i > 0 && (
              <>
                <span> • </span>
                <br />
              </>
            )}
            {partner}
          </React.Fragment>
        ))}
      </S.Partners>
      <S.TextWrapper>
        <S.Topic>
          We are partnering with leaders that are hungry for the vision of
          creating a brighter future that will inspire others to do more than
          they thought possible.
        </S.Topic>
        <S.Text>
          On our own we have a limited impact in life. Right now together we can
          create a transformational movement that will impact generations to
          come. Partnering with Absolute can be accomplished in many ways;
          completing our equip classes and choosing to grow with other growing
          leaders, volunteering time using your gifts and talents, sponsoring
          one of our Making a Difference Projects, and or a business that wants
          to partner with other business leaders to elevate and equip themselves
          and others to reach new heights in our growing Community. We are
          inviting you to join us and partner with a global mission,
          Transformation 2031.
        </S.Text>
      </S.TextWrapper>
    </S.Wrapper>
  </S.PartnerDescription>
);

export default PartnerDescription;
