import React from 'react';
import propTypes from 'prop-types';

import * as S from './Form.style';

const Form = React.forwardRef(
  ({ name, netlify, error, message, template, className, children }, ref) => (
    <S.Form
      name={name}
      ref={ref}
      data-netlify={netlify}
      netlify-honeypot={netlify && 'bot-field'}
      className={className}
    >
      {netlify && <input type="hidden" name="bot-field" />}
      {message && <S.MessageBanner>{message}</S.MessageBanner>}
      {error && (
        <S.ErrorBanner>
          <span>Error!</span>
          {error}
        </S.ErrorBanner>
      )}
      {template ? (
        <S.FormGrid $template={template}>{children}</S.FormGrid>
      ) : (
        children
      )}
    </S.Form>
  )
);

Form.defaultProps = {
  name: undefined,
  netlify: false,
  error: undefined,
  message: undefined,
  template: undefined,
  className: undefined,
};

Form.propTypes = {
  name: propTypes.string,
  netlify: propTypes.bool,
  error: propTypes.string,
  message: propTypes.string,
  template: propTypes.arrayOf(propTypes.string),
  className: propTypes.string,
  children: propTypes.node.isRequired,
};

Form.displayName = 'Form';

export default Form;
