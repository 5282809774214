import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Animate from 'react-animate-on-scroll';

import Button from 'src/components/Button';

import * as S from './Partnerships.style';

const Partnerships = () => {
  const data = useStaticQuery(query);

  return (
    <S.Partnerships>
      <S.Watermark>Partnership</S.Watermark>
      <S.Image
        alt="Collage of Bryan & Andrea Fraser with partners"
        image={data.file.childImageSharp.gatsbyImageData}
      />
      <S.Wrapper>
        <Animate animateOnce animateIn="fadeInUp">
          <S.Title>Become A Partner</S.Title>
        </Animate>
        <Animate animateOnce animateIn="fadeIn">
          <S.Topic>
            Partnership means being a part of something larger than yourself.
          </S.Topic>
          <S.Text>
            We are partnering with leaders that are hungry for a vision of
            bringing unity to a world programmed for division.
          </S.Text>
          <S.Text>
            On our own we have a limited impact in life. Together we can create
            a transformational movement that will impact generations to come.
            Today more than ever the world needs people working together with a
            common vision. Together we can make a difference!
          </S.Text>
          <S.Text>
            Begin the journey of bringing unity to your organization by
            partnering with Absolute today!
          </S.Text>
        </Animate>
        <Button outline to="/contact/request-partnership">
          Request Partnership
        </Button>
      </S.Wrapper>
    </S.Partnerships>
  );
};

const query = graphql`
  query {
    file(relativePath: { eq: "png/partnership.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;

export default Partnerships;
