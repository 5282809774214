import styled from 'styled-components';

export const Quantity = styled.div`
  display: inline-block;
  width: 172px;
  height: 38px;
  overflow: hidden;
  border-radius: 8px;
  border: 2px solid ${({ theme }) => theme.color.purple};
`;

export const Decrement = styled.button`
  display: inline-block;
  width: 38px;
  height: 38px;
  margin: -2px;
  cursor: pointer;
  border: none;
  background: none;
  color: ${({ theme }) => theme.color.purple};
  transition: all 0.15s;

  &:hover {
    background: ${({ theme }) => theme.bg.hover.base};
  }

  &:active {
    transition: none;
    color: ${({ theme }) => theme.text.white};
    background: ${({ theme }) => theme.color.purple};
  }
`;

export const Input = styled.input`
  display: inline-block;
  position: relative;
  width: 100px;
  height: 38px;
  margin: -2px 0;
  text-align: center;
  color: ${({ theme }) => theme.text.base};
  background: ${({ theme }) => theme.bg.base};
  border: 2px solid ${({ theme }) => theme.color.purple};
  border-top: none;
  border-bottom: none;

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }
`;

export const Increment = styled.button`
  display: inline-block;
  width: 38px;
  height: 38px;
  margin: -2px;
  cursor: pointer;
  border-radius: 0 4px 4px 0;
  border: none;
  background: none;
  color: ${({ theme }) => theme.color.purple};
  transition: all 0.15s;

  &:hover {
    background: ${({ theme }) => theme.bg.hover.base};
  }

  &:active {
    transition: none;
    color: ${({ theme }) => theme.text.white};
    background: ${({ theme }) => theme.color.purple};
  }
`;
