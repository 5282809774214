import styled, { css } from 'styled-components';
import { GatsbyImage as COMP1 } from 'gatsby-plugin-image';

import COMP2 from 'src/components/Button';

export const Statement = styled.div`
  padding: 0 16px;
  max-width: 660px;
  margin: 24px auto 0;
  position: relative;

  @media(min-width: ${({ theme }) => theme.size.lg}) {
    margin: 120px auto 100px;
  }
`;

export const Watermark = styled.span`
  display: none;

  @media(min-width: ${({ theme }) => theme.size.lg}) {
    display: inline;
    font-size: 175px;
    position: absolute;
    top: -144px;
    left: -310px;
    z-index: -1;
    font-weight: 800;
    color: ${({ theme }) => theme.hue.purple};
  }
`;

export const Title = styled.h1`
  font-size: 2.125rem;
  text-align: center;
  margin: 0 0 24px;

  @media(min-width: ${({ theme }) => theme.size.md}) {
    font-size: 4rem;
  }
`;

export const Text = styled.p`
  font-size: 1.125rem;
  text-align: center;
  margin: 0 0 20px;

  @media(min-width: ${({ theme }) => theme.size.md}) {
    line-height: 1.8;
  }
`;

export const Tagline = styled.span`
  display: block;
  margin: 30px 0;
  text-align: center;
  color: ${({ theme }) => theme.color.purple};
  font-weight: 600;
  font-style: italic;
  font-family: ${({ theme }) => theme.font.secondary};

  ${({ $type }) => $type === 'purpose' && css`
    font-size: 1.375rem;
  `};

  ${({ $type }) => $type === 'mission' && css`
    font-size: 1.375rem;
  `};
`;

export const Button = styled(COMP2)`
  display: block;
  margin: 0 auto 40px;
`;

export const ClusterOne = styled(COMP1)`
  display: none;

  @media(min-width: ${({ theme }) => theme.size.lg}) {
    display: block;
    width: 280px;
    position: absolute;
    left: -300px;
    bottom: -40px;
  }
`;

export const ClusterTwo = styled(COMP1)`
  display: none;

  @media(min-width: ${({ theme }) => theme.size.lg}) {
    display: block;
    width: 280px;
    position: absolute;
    right: -300px;
    top: -40px;
  }
`;

export const ClusterMobile = styled(COMP1)`
  display: block;

  @media(min-width: ${({ theme }) => theme.size.lg}) {
    display: none;
  }
`;
