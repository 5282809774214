import React from 'react';
import { graphql } from 'gatsby';
import propTypes from 'prop-types';

import { mapPurposeToColor } from 'src/utilities/helpers';

import * as S from './Article.style';

const Article = ({ article, author }) => (
  <S.Article>
    {article.frontmatter.purposes.map((name) => (
      <S.Purpose key={name} $color={mapPurposeToColor(name)}>
        {name}
      </S.Purpose>
    ))}
    <S.Title>{article.frontmatter.title}</S.Title>
    <S.Excerpt>{article.excerpt}</S.Excerpt>
    <S.Meta>
      <S.Headshot
        alt={`${author.frontmatter.name}s Headshot`}
        image={author.frontmatter.headshot.childImageSharp.gatsbyImageData}
      />
      <S.Author>{author.frontmatter.name}</S.Author>
      <S.PublishDate>{article.frontmatter.date}</S.PublishDate>
    </S.Meta>
    {article?.frontmatter?.tags?.length > 0 &&
      article.frontmatter.tags.map((name) => <S.Tag key={name}>{name}</S.Tag>)}
    <S.FeaturedImage
      alt={article.frontmatter.featuredImageAlt}
      image={article.frontmatter.featuredImage.childImageSharp.gatsbyImageData}
    />
    <S.Body dangerouslySetInnerHTML={{ __html: article.html }} />
  </S.Article>
);

Article.propTypes = {
  article: propTypes.shape({
    html: propTypes.string.isRequired,
    excerpt: propTypes.string.isRequired,
    frontmatter: propTypes.shape({
      tags: propTypes.arrayOf(propTypes.string),
      date: propTypes.string.isRequired,
      title: propTypes.string.isRequired,
      purposes: propTypes.arrayOf(propTypes.string).isRequired,
      featuredImage: propTypes.shape({
        childImageSharp: propTypes.shape({
          gatsbyImageData: propTypes.object.isRequired,
        }).isRequired,
      }).isRequired,
      featuredImageAlt: propTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  author: propTypes.shape({
    frontmatter: propTypes.shape({
      name: propTypes.string.isRequired,
      headshot: propTypes.shape({
        childImageSharp: propTypes.shape({
          gatsbyImageData: propTypes.object.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export const ArticleArticleFragment = graphql`
  fragment ArticleFragment on MarkdownRemark {
    html
    excerpt
    frontmatter {
      tags
      date(formatString: "MMMM Do, YYYY")
      title
      purposes
      featuredImage {
        childImageSharp {
          gatsbyImageData
        }
      }
      featuredImageAlt
    }
  }
`;

export const ArticleAuthorFragment = graphql`
  fragment ArticleAuthorFragment on MarkdownRemark {
    frontmatter {
      name
      headshot {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`;

export default Article;
