import styled, { keyframes, css } from 'styled-components';

const loading = keyframes`
  0%,
  20%,
  80%,
  100% {
    transform: scale(1) translateY(0px);
  }
  50% {
    transform: scale(1.25) translateY(-2.5px);
  }
`;

const animation = keyframes`
  0% {
    transform: translateX(-100%);
  }
  8% {
    transform: translateX(10%);
  }
  10% {
    transform: translateX(0);
  }
  84% {
    transform: translateX(0);
  }
  97% {
    transform: translateX(-10%);
  }
  100% {
    transform: translateX(100%);
  }
`;

export const Button = styled.button`
  position: relative;
  overflow: hidden;
  display: inline-block;
  font-size: 1rem;
  padding: 8px 16px;
  border-radius: 4px;
  text-align: center;
  font-weight: 600;
  line-height: 1.15;
  margin: 0;
  cursor: pointer;
  width: fit-content;
  text-decoration: none;
  transition: border 0.25s, background 0.25s, color 0.25s;

  border: 2px solid ${({ theme }) => theme.color.purple};

  :disabled {
    pointer-events: none;
    color: ${({ theme }) => theme.color.purple};
    background: ${({ theme }) => theme.bg.mediumGrey};
    border: 2px solid ${({ theme }) => theme.bg.mediumGrey};
  }

  ${({ $outline }) => $outline ? css`
    color: ${({ theme }) => theme.color.purple};
    background: none;

    :hover, :focus {
      background: ${({ theme }) => theme.bg.hover.base};

    }

    :active {
      background: ${({ theme }) => theme.bg.active.base};
    }
  ` : css`
    color: ${({ theme }) => theme.text.white};
    background: ${({ theme }) => theme.color.purple};

    :hover, :focus {
      background: ${({ theme }) => theme.color.hover.purple};
    }

    :active {
      background: ${({ theme }) => theme.color.active.purple};
    }
  `}
`;

export const Overlay = styled.span`
  pointer-events: none;
  position: absolute;
  left: 0;
  right: 0;
  bottom: calc((100% - 1lh) / 2);
  overflow: hidden;
  text-align: center;
  background: inherit;
`;

export const Dot = styled.span`
  display: inline-block;
  width: 6px;
  height: 6px;
  margin: 1px 4px 0;
  border-radius: 50%;
  background: ${({ $outline, theme }) => $outline ? theme.color.purple : theme.bg.base};
  animation: ${loading} 0.9s linear infinite;

  &:nth-child(1) {
    animation-delay: 0s;
  }

  &:nth-child(2) {
    animation-delay: 0.3s;
  }

  &:nth-child(3) {
    animation-delay: 0.6s;
  }
`;

export const Animation = styled.span`
  display: block;
  width: 100%;
  animation: ${animation} 5s linear;
`;
