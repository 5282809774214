import { gql } from 'graphql-request';

import CheckoutFragment from '../fragments/CheckoutFragment';
import CheckoutLineItemFragment from '../fragments/CheckoutLineItemFragment';
import VariantFragment from '../fragments/VariantFragment';

export default gql`
  ${CheckoutFragment}
  ${CheckoutLineItemFragment}
  ${VariantFragment}
  mutation ($checkoutId: ID!, $lineItems: [CheckoutLineItemUpdateInput!]!) {
    checkoutLineItemsUpdate(checkoutId: $checkoutId, lineItems: $lineItems) {
      checkout {
        ...CheckoutFragment
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`;
