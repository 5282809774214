import React from 'react';
import propTypes from 'prop-types';
import { graphql } from 'gatsby';

import SEO from 'src/components/SEO';

import Back from 'src/sections/Back';
import Article from 'src/sections/Article';
import ArticleCTA from 'src/sections/ArticleCTA';
import RelatedContent from 'src/sections/RelatedContent';

import * as S from './article.style';

const ArticleTemplate = ({ data, useAttributes }) => {
  useAttributes({ initiallyTransparent: false });

  return (
    <S.ArticleTemplate>
      <SEO title={data.article.title} description={data.article.excerpt} />
      <Back type="article" />
      <Article article={data.article} author={data.author} />
      <ArticleCTA />
      <RelatedContent
        articles={data.relatedArticles.nodes}
        events={data.relatedEvents.nodes}
      />
    </S.ArticleTemplate>
  );
};

export const query = graphql`
  query ($slug: String, $name: String, $purposes: [String]) {
    article: markdownRemark(
      slug: { eq: $slug }
      frontmatter: { type: { eq: "article" } }
    ) {
      excerpt
      frontmatter {
        title
      }
      ...ArticleFragment
    }
    author: markdownRemark(
      frontmatter: { type: { eq: "author" }, name: { eq: $name } }
    ) {
      ...ArticleAuthorFragment
    }
    relatedArticles: allMarkdownRemark(
      limit: 4
      sort: { fields: date, order: DESC }
      filter: {
        slug: { ne: $slug }
        frontmatter: { type: { eq: "article" }, purposes: { in: $purposes } }
      }
    ) {
      nodes {
        ...ArticleGridFragment
      }
    }
    relatedEvents: allMarkdownRemark(
      limit: 4
      sort: { fields: date, order: DESC }
      filter: {
        frontmatter: { type: { eq: "event" }, purposes: { in: $purposes } }
      }
    ) {
      nodes {
        ...EventGridFragment
      }
    }
  }
`;

ArticleTemplate.defaultProps = {
  useAttributes: undefined,
};

ArticleTemplate.propTypes = {
  data: propTypes.shape({
    article: propTypes.object.isRequired,
    author: propTypes.object.isRequired,
    relatedArticles: propTypes.shape({
      nodes: propTypes.arrayOf(propTypes.object).isRequired,
    }).isRequired,
    relatedEvents: propTypes.shape({
      nodes: propTypes.arrayOf(propTypes.object).isRequired,
    }).isRequired,
  }).isRequired,
  useAttributes: propTypes.func,
};

export default ArticleTemplate;
