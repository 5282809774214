import React from 'react';
import propTypes from 'prop-types';

import SEO from 'src/components/SEO';

import HomeHero from 'src/sections/HomeHero';
import Signup from 'src/sections/Signup';
import Statement from 'src/sections/Statement';
import Purposes from 'src/sections/Purposes';
import Generosity from 'src/sections/Generosity';
import RecentArticles from 'src/sections/RecentArticles';
import Partnerships from 'src/sections/Partnerships';

import * as S from './index.style';

const HomePage = ({ useAttributes }) => {
  useAttributes({ initiallyTransparent: true });

  return (
    <S.HomePage>
      <SEO title="Absolute Training Centre | ...it all Starts with You!" />
      <HomeHero />
      <Signup type="e-motivator" />
      <Statement type="mission" />
      <Purposes />
      <Generosity />
      <RecentArticles />
      <Signup type="newsletter" />
      <Partnerships />
    </S.HomePage>
  );
};

HomePage.defaultProps = {
  useAttributes: undefined,
};

HomePage.propTypes = {
  useAttributes: propTypes.func,
};

export default HomePage;
