import React, { createContext, useState } from 'react';
import propTypes from 'prop-types';

const AlertContext = createContext();

const initialState = {
  status: undefined,
  message: undefined,
};

export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState(initialState);

  const pushAlert = (status, message) => {
    setAlert({ status, message });
  };

  const resetAlert = () => {
    setAlert(initialState);
  };

  return (
    <AlertContext.Provider value={{ alert, pushAlert, resetAlert }}>
      {children}
    </AlertContext.Provider>
  );
};

AlertProvider.propTypes = {
  children: propTypes.node.isRequired,
};

export default AlertContext;
