import styled from 'styled-components';
import { GatsbyImage as COMP1 } from 'gatsby-plugin-image';

import COMP2 from 'src/components/Button';

export const Generosity = styled.div`
  position: relative;
  margin: 0 0 48px;
  padding: 16px 16px 32px;
  background: ${({ theme }) => theme.bg.lightGrey};

  @media(min-width: ${({ theme }) => theme.size.md}) {
    margin: 0 auto 48px;
    background: none;
    max-width: 768px;
  }

  @media(min-width: ${({ theme }) => theme.size.xl}) {
    display: grid;
    background: none;
    margin: 0 auto 72px;
    padding: 178px 16px 0;
    grid-template-columns: 1fr 540px;
    max-width: 1200px;
    grid-gap: 48px;
    align-items: start;
  }
`;

export const Watermark = styled.span`
  display: none;

  @media(min-width: ${({ theme }) => theme.size.xl}) {
    display: inline;
    font-size: 175px;
    position: absolute;
    top: -32px;
    left: -80px;
    z-index: -1;
    font-weight: 800;
    color: ${({ theme }) => theme.hue.purple};
  }
`;

export const Image = styled(COMP1)`
  margin: 0 0 32px;
`;

export const Wrapper = styled.div`

`;

export const Title = styled.h2`
  font-size: 2.125rem;
  margin: 0 0 20px;
  font-weight: 600;
  font-style: italic;
  font-family: ${({ theme }) => theme.font.secondary};

  @media(min-width: ${({ theme }) => theme.size.lg}) {
    font-size: 4rem;
    margin: 0 0 4px;
  }
`;

export const Topic = styled.p`
  font-size: 1.25rem;
  font-weight: 600;
  font-style: italic;

  @media(min-width: ${({ theme }) => theme.size.lg}) {
    font-size: 1.5rem;
  }
`;

export const Text = styled.p`
  font-size: 1.125rem;
`;

export const Truncate = styled.p`
  font-size: 1.125rem;
  display: ${({ $open }) => $open ? 'block' : 'none'};
`;

export const Button = styled(COMP2)`
  margin: 10px 0 0;
  display: ${({ $open }) => $open ? 'none' : 'inline-block'};
`;
