import React from 'react';
import propTypes from 'prop-types';

import ArticleGrid from 'src/components/ArticleGrid';
import EventGrid from 'src/components/EventGrid';

import * as S from './RelatedContent.style';

const RelatedContent = ({ links, articles, events }) => (
  <S.RelatedContent>
    {articles && articles.length > 0 && (
      <S.Wrapper>
        <S.Title>
          {articles.length === 1 ? 'Related Article' : 'Related Articles'}
        </S.Title>
        <ArticleGrid family="scroll" articles={articles} />
        {links && (
          <S.Button to={links.articles}>View all related Articles</S.Button>
        )}
      </S.Wrapper>
    )}
    {events && events.length > 0 && (
      <S.Wrapper>
        <S.Title>
          {events.length === 1 ? 'Related Event' : 'Related Events'}
        </S.Title>
        <EventGrid family="scroll" events={events} />
        {links && (
          <S.Button to={links.events}>View all related Events</S.Button>
        )}
      </S.Wrapper>
    )}
  </S.RelatedContent>
);

RelatedContent.defaultProps = {
  links: undefined,
  articles: undefined,
  events: undefined,
};

RelatedContent.propTypes = {
  links: propTypes.shape({
    articles: propTypes.string.isRequired,
    events: propTypes.string.isRequired,
  }),
  articles: propTypes.arrayOf(propTypes.object),
  events: propTypes.arrayOf(propTypes.object),
};

export default RelatedContent;
