import React from 'react';

import SocialIcons from 'src/components/SocialIcons';

import * as S from './Footer.style';

const directory = [
  {
    title: 'Navigation',
    links: [
      { title: 'Home', url: '/' },
      { title: 'About Us', url: '/about-us' },
      { title: 'Events', url: '/events' },
      { title: 'Blog', url: '/blog' },
      { title: 'Shop', url: '/shop' },
    ],
  },
  {
    title: 'Purpose',
    links: [
      { title: 'Well-being', url: '/purpose/well-being' },
      { title: 'Family', url: '/purpose/family' },
      { title: 'Business', url: '/purpose/business' },
      { title: 'Life', url: '/purpose/life' },
    ],
  },
  {
    title: 'Contact',
    links: [
      { title: 'General Inquiries', url: '/contact/general-inquiries' },
      { title: 'Request Partnership', url: '/contact/request-partnership' },
      { title: 'Invite To Speak', url: '/contact/invite-to-speak' },
    ],
  },
  {
    title: 'More',
    links: [
      { title: 'Shop', url: '/shop' },
      { title: 'Cart', url: '/cart' },
      { title: 'Legal', url: '/legal' },
    ],
  },
];

const Footer = () => (
  <S.Footer>
    <S.Wrapper>
      <S.Info>
        <S.LogoLink to="/">
          <S.Logo />
        </S.LogoLink>
        <S.Name>Absolute Training Centre</S.Name>
        <S.Location>Edmonton, Alberta, Canada</S.Location>
        <SocialIcons
          size="small"
          family="white"
          links={[
            {
              icon: 'Instagram',
              url: 'https://www.instagram.com/absolutetrainingcentre/',
            },
            {
              icon: 'Facebook',
              url: 'https://www.facebook.com/Absolute-Training-Centre-104081782112714',
            },
            {
              icon: 'Youtube',
              url: 'https://www.youtube.com/channel/UC9LpeUxgYTM1iqFnLaaBI3g',
            },
          ]}
        />
      </S.Info>
      <S.Directory>
        {directory.map((linkGroup) => (
          <S.LinkGroup key={linkGroup.title}>
            <S.LinkGroupTitle>{linkGroup.title}</S.LinkGroupTitle>
            {linkGroup.links.map((link) => (
              <S.Link key={link.title} to={link.url}>
                {link.title}
              </S.Link>
            ))}
          </S.LinkGroup>
        ))}
      </S.Directory>
    </S.Wrapper>
  </S.Footer>
);

export default Footer;
