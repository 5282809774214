import React, { useState } from 'react';
import Animate from 'react-animate-on-scroll';

import { mapPurposeToColor } from 'src/utilities/helpers';

import Button from 'src/components/Button';
import PurposeMenu from 'src/components/PurposeMenu';

import * as S from './Purposes.style';

const purposes = [
  {
    name: 'Well-being',
    topic:
      'Many people believe ‘money makes the world go around’... we believe what you put in your body and mind is what makes the world go around.',
    content: (
      <>
        <p>
          What you put in your mouth is in direct correlation to what you put in
          your mind. If you feed yourself with healthy food, you most likely
          feed your mind with positive thoughts. On the other hand, if you feed
          yourself with unhealthy foods, drugs, alcohol consistently you
          probably feed your mind with negative, discriminating, dysfunctional,
          self defeating thoughts. And of course, there is everything in
          between.
        </p>
        <p>
          What we think about is what directs our actions. Hence why if we
          continually feed our mind with negative, self-defeating thoughts, we
          continue to feed our bodies with unhealthy substances. Connect to be
          Inspired in your well-being.
        </p>
      </>
    ),
  },
  {
    name: 'Family',
    topic:
      'Family is the heartbeat of who we are. Family is the heartbeat of who everyone is. We are created that way.',
    content: (
      <>
        <p>
          We believe family is a group of people who have similar beliefs and
          values. We don’t function the same, we believe and value life the
          same. That is not the case for most families today. Most families
          today have suffered separation, dysfunction, addictions, comparison,
          and therefore there is no heartbeat. Building relationships goes in
          direct opposition to what most people have experienced. That’s where
          the struggle is with most marriages, families, children, finances.
          People have not been shown or taught how to build a thriving
          relationship with another person, a spouse, a child, themselves, or
          even with money. It’s more about survival mode.
        </p>
        <p>
          Strong unified marriages will lead to security in children, and a
          positive financial structure, working together with one another. We
          desire to equip families, and marriages with a solid foundation. A
          strong unified family, can build strong individuals, businesses, lives
          – connect with our growing family.
        </p>
      </>
    ),
  },
  {
    name: 'Business',
    topic:
      'If you are a business owner or corporate leader, do you feel isolated in your business or job?',
    content: (
      <>
        <p>
          Many have been devastated through financial loss & trauma, world
          chaos, job restructuring and bankruptcy. Business owners and leaders
          are desperate for a higher purpose but aren’t sure what that purpose
          is or how to achieve it. You are not alone. Far too many believe it is
          impossible today to overcome. Partnering with other successful
          business leaders to get equipped will build a strong business
          community and business leaders. Through working together with a clear
          purpose & vision this will be possible to thrive in the marketplace
          and in Life.
        </p>
        <p>
          Get Equipped. Run a strong healthy business once again. Become part of
          a growing community of leaders.
        </p>
      </>
    ),
  },
  {
    name: 'Life',
    topic:
      'Your Well-being. Your Family. Your Business. Your Life. They are all connected.',
    content: (
      <>
        <p>
          Each area of your life affects all the areas within your life. That is
          great news! You are in control of the life you would like to have.
          Everyone is included. No one has to settle for what they have been
          told. It will come down to what you believe in for your life. Capture
          a vision you want for your life, your family, your business. Igniting
          one area of your life will impact all areas of your life.
        </p>
        <p>
          Transformation begins with a decision, follow through will happen when
          you surround yourself with people who consistently strive for
          excellence in their life. Life&apos;s for living. How do you want to
          be living yours? People elevate people to reach their true potential.
          Connect and grow your best life!
        </p>
      </>
    ),
  },
];

const Purposes = () => {
  const [purpose, setPurpose] = useState(purposes[0]);

  return (
    <S.Purposes>
      <S.Watermark $color={mapPurposeToColor(purpose.name)} aria-hidden="true">
        {purpose.name}
      </S.Watermark>
      <S.Wrapper>
        <S.Subtitle>Build Your Foundation</S.Subtitle>
        <Animate animateOnce animateIn="fadeInUp">
          <S.Title>Our Purpose</S.Title>
        </Animate>
        <S.PurposeWrapper>
          <PurposeMenu
            showAll={false}
            family="button"
            purpose={purpose.name}
            setPurpose={(name) => {
              for (const i in purposes) {
                if (purposes[i].name === name) setPurpose(purposes[i]);
              }
            }}
          />
          <S.TextWrapper>
            <S.Topic>{purpose.topic}</S.Topic>
            <S.Content>{purpose.content}</S.Content>
            <Button outline to={`/purpose/${purpose.name.toLowerCase()}`}>
              Learn about {purpose.name}
            </Button>
          </S.TextWrapper>
        </S.PurposeWrapper>
      </S.Wrapper>
    </S.Purposes>
  );
};

export default Purposes;
