import React from 'react';
import propTypes from 'prop-types';
import { graphql } from 'gatsby';
import { useQueryParam, withDefault, NumberParam } from 'use-query-params';

import { mapPurposeToColor } from 'src/utilities/helpers';

import * as S from './EventGrid.style';

const eventsPerPage = 18;

const EventGrid = ({ featured, events, family, paginate, className }) => {
  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 1));

  return (
    <S.EventGrid className={className}>
      <S.Wrapper $family={family}>
        {(paginate ? events.slice(0, page * eventsPerPage) : events).map(
          (event, i) => (
            <S.Card
              $featured={featured && i === 0}
              key={event.slug}
              to={`/event/${event.slug}`}
            >
              <S.Image
                alt={event.frontmatter.featuredImageAlt}
                image={
                  event.frontmatter.featuredImage.childImageSharp
                    .gatsbyImageData
                }
              />
              <S.Content>
                <S.Meta>
                  <S.Purposes>
                    {event.frontmatter.purposes.map((name) => (
                      <S.Purpose key={name} $color={mapPurposeToColor(name)}>
                        {name}
                      </S.Purpose>
                    ))}
                  </S.Purposes>
                  <S.Title>{event.frontmatter.title}</S.Title>
                  <S.Excerpt $featured={featured && i === 0}>
                    {event.excerpt}
                  </S.Excerpt>
                  <S.Info>
                    <S.Label>Teachers:</S.Label> {event.frontmatter.teachers}
                  </S.Info>
                  <S.Info>
                    <S.Label>Start Date:</S.Label>{' '}
                    {event.frontmatter.dates[0].formattedDate}
                  </S.Info>
                  <S.Info>
                    <S.Label>Location:</S.Label> {event.frontmatter.location}
                  </S.Info>
                </S.Meta>
                <S.Link>View Event</S.Link>
              </S.Content>
            </S.Card>
          )
        )}
      </S.Wrapper>
      {paginate && events.length > page * eventsPerPage && (
        <S.LoadMore outline onClick={() => setPage(page + 1)}>
          Load More events
        </S.LoadMore>
      )}
    </S.EventGrid>
  );
};

EventGrid.defaultProps = {
  featured: true,
  paginate: false,
  className: undefined,
};

EventGrid.propTypes = {
  featured: propTypes.bool,
  events: propTypes.arrayOf(
    propTypes.shape({
      slug: propTypes.string.isRequired,
      excerpt: propTypes.string.isRequired,
      frontmatter: propTypes.shape({
        title: propTypes.string.isRequired,
        purposes: propTypes.arrayOf(propTypes.string).isRequired,
        featuredImage: propTypes.shape({
          childImageSharp: propTypes.shape({
            gatsbyImageData: propTypes.object.isRequired,
          }).isRequired,
        }).isRequired,
        featuredImageAlt: propTypes.string.isRequired,
        teachers: propTypes.string.isRequired,
        dates: propTypes.arrayOf(
          propTypes.shape({
            formattedDate: propTypes.string.isRequired,
          })
        ).isRequired,
        location: propTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  family: propTypes.oneOf(['scroll', 'row']).isRequired,
  paginate: propTypes.bool,
  className: propTypes.string,
};

export const EventGridFragment = graphql`
  fragment EventGridFragment on MarkdownRemark {
    slug
    excerpt(pruneLength: 240)
    frontmatter {
      title
      purposes
      featuredImageAlt
      featuredImage {
        childImageSharp {
          gatsbyImageData
        }
      }
      teachers
      dates {
        formattedDate: date(formatString: "MMMM Do, YYYY")
      }
      location
    }
  }
`;

export default EventGrid;
