import React from 'react';
import propTypes from 'prop-types';
import { graphql } from 'gatsby';

import SEO from 'src/components/SEO';

import Back from 'src/sections/Back';
import Product from 'src/sections/Product';

import * as S from './product.style';

const ProductTemplate = ({ data, useAttributes }) => {
  useAttributes({ initiallyTransparent: false });

  return (
    <S.ProductTemplate>
      <SEO
        title={
          data.product.seo.title ||
          `${data.product.title} | Absolute Training Centre`
        }
        description={data.product.seo.description}
      />
      <Back type="product" />
      <Product product={data.product} />
    </S.ProductTemplate>
  );
};

export const query = graphql`
  query ($handle: String!) {
    product: shopifyProduct(handle: { eq: $handle }) {
      title
      seo {
        title
        description
      }
      ...ProductFragment
    }
  }
`;

ProductTemplate.defaultProps = {
  useAttributes: undefined,
};

ProductTemplate.propTypes = {
  data: propTypes.shape({
    product: propTypes.shape({
      title: propTypes.string.isRequired,
      seo: propTypes.shape({
        title: propTypes.string,
        description: propTypes.string,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  useAttributes: propTypes.func,
};

export default ProductTemplate;
