import React, { useRef, useState } from 'react';
import propTypes from 'prop-types';
import fetch from 'isomorphic-unfetch';

import { reportInputValidity } from 'src/utilities/helpers';

import * as S from './NetlifyForm.style';

const NetlifyForm = ({ name, template, onSubmit, className, children }) => {
  const form = useRef();
  const [error, setError] = useState(null);

  const handleClick = async (event) => {
    event.preventDefault();

    if (reportInputValidity(form.current)) {
      let body = '';

      for (const element of form.current.elements) {
        if (element.name && element.value) {
          if (body !== '') body += '&';
          body += `${encodeURIComponent(element.name)}=${encodeURIComponent(
            element.value
          )}`;
        }
      }

      return fetch('/', {
        body,
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
        .then(async () => {
          await onSubmit();
          return true;
        })
        .catch((err) => {
          setError(err);
          return false;
        });
    }

    return false;
  };

  return (
    <S.NetlifyForm
      netlify
      name={name}
      ref={form}
      error={error}
      template={[...template]}
      className={className}
    >
      <input type="hidden" name="form-name" value={name} />
      {children}
      <S.Button type="submit" size="large" family="red" onClick={handleClick}>
        Submit
      </S.Button>
    </S.NetlifyForm>
  );
};

NetlifyForm.defaultProps = {
  onSubmit: undefined,
  className: undefined,
};

NetlifyForm.propTypes = {
  name: propTypes.string.isRequired,
  template: propTypes.arrayOf(propTypes.string).isRequired,
  onSubmit: propTypes.func,
  className: propTypes.string,
  children: propTypes.node.isRequired,
};

export default NetlifyForm;
