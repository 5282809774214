import { gql } from 'graphql-request';

import CheckoutFragment from '../fragments/CheckoutFragment';
import CheckoutLineItemFragment from '../fragments/CheckoutLineItemFragment';
import VariantFragment from '../fragments/VariantFragment';

export default gql`
  ${CheckoutFragment}
  ${CheckoutLineItemFragment}
  ${VariantFragment}
  mutation ($lineItems: [CheckoutLineItemInput!]!, $checkoutId: ID!) {
    checkoutLineItemsAdd(lineItems: $lineItems, checkoutId: $checkoutId) {
      checkout {
        ...CheckoutFragment
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`;
