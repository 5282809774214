module.exports = {
  // Variables defined during build-time and run-time
  CHECKOUT_URL: process.env.GATSBY_CHECKOUT_URL,
  MYSHOPIFY_URL: process.env.GATSBY_MYSHOPIFY_URL,
  SITE_URL: process.env.GATSBY_SITE_URL,
  STOREFRONT_TOKEN: process.env.GATSBY_STOREFRONT_TOKEN,
  // Variables defined during build-time
  KLAVIYO_PRIVATE_TOKEN: process.env.KLAVIYO_PRIVATE_TOKEN,
  KLAVIYO_PUBLIC_TOKEN: process.env.KLAVIYO_PUBLIC_TOKEN,
  SHOPIFY_APP_ID: process.env.SHOPIFY_APP_ID,
  SHOPIFY_TOKEN: process.env.SHOPIFY_TOKEN,
  STRIPE_ENDPOINT_SECRET: process.env.STRIPE_ENDPOINT_SECRET,
  STRIPE_SECRET_KEY: process.env.STRIPE_SECRET_KEY,
  TAG_MANAGER_ID: process.env.TAG_MANAGER_ID,
  // Variables defined in this file
  CONNECT_LIST_ID: 'SkETQK',
  NEWSLETTER_LIST_ID: 'WFJYrg',
};
