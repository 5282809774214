import styled from 'styled-components';

import COMP1 from 'src/components/Button';

export const ErrorPage = styled.div`
  min-height: 100vh;
  display: flex;
  padding: 56px 16px 0;
  flex-direction: column;
  justify-content: center;

  @media(min-width: ${({ theme }) => theme.size.md}) {
    padding: 80px 16px 0;
  }
`;

export const Title = styled.h1`
  font-size: 2.125rem;
  text-align: center;
  margin: 0 0 20px;
  font-weight: 600;
  font-style: italic;
  font-family: ${({ theme }) => theme.font.secondary};

  @media(min-width: ${({ theme }) => theme.size.md}) {
    font-size: 4rem;
  }
`;

export const Text = styled.p`
  font-size: 1.125rem;
  max-width: 640px;
  margin: 0 auto 10px;
  text-align: center;
`;

export const Link = styled.a`

`;

export const Button = styled(COMP1)`
  display: block;
  margin: 20px auto 0;
`;
