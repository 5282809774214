import React, { useState, useEffect, cloneElement } from 'react';
import propTypes from 'prop-types';
import Helmet from 'react-helmet';
import { ThemeProvider } from 'styled-components';

import 'animate.css';

import theme from 'src/theme';

import { AlertProvider } from 'src/contexts/AlertContext';
import { ShopifyProvider } from 'src/contexts/ShopifyContext';

import Header from 'src/components/Header';
import Footer from 'src/components/Footer';

import * as S from './Layout.style';

const googleFontsUrl =
  'https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,600;0,700;1,600;1,700&family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap';

const initialAttributes = { initiallyTransparent: true };

const Layout = ({ children }) => {
  const [attributes, setAttributes] = useState(initialAttributes);

  const useAttributes = (...params) =>
    useEffect(() => {
      setAttributes(...params);
    }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta content="IE=edge" httpEquiv="X-UA-Compatible" />
        <meta
          content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=5"
          name="viewport"
        />
        <link
          rel="preconnect"
          crossOrigin="true"
          href="https://fonts.gstatic.com"
        />
        <link rel="preload" as="style" href={googleFontsUrl} />
        <link rel="stylesheet" as="style" href={googleFontsUrl} />
      </Helmet>
      <ThemeProvider theme={theme}>
        <AlertProvider>
          <ShopifyProvider>
            <S.GlobalStyle />
            <Header initiallyTransparent={attributes.initiallyTransparent} />
            {cloneElement(children, { ...children.props, useAttributes })}
            <Footer />
          </ShopifyProvider>
        </AlertProvider>
      </ThemeProvider>
    </>
  );
};

Layout.propTypes = {
  children: propTypes.node.isRequired,
};

export default Layout;
