import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Animate from 'react-animate-on-scroll';

import * as S from './Generosity.style';

const Generosity = () => {
  const data = useStaticQuery(query);
  const [open, setOpen] = useState(false);

  return (
    <S.Generosity>
      <S.Watermark aria-hidden="true">Generosity</S.Watermark>
      <Animate animateOnce animateIn="fadeIn">
        <S.Image
          alt="Collage of children"
          image={data.file.childImageSharp.gatsbyImageData}
        />
      </Animate>
      <S.Wrapper>
        <Animate animateOnce animateIn="fadeInUp">
          <S.Title>Story of Generosity</S.Title>
        </Animate>
        <Animate animateOnce animateIn="fadeInRight">
          <S.Topic>
            We are igniting our next project with schools in the community.
          </S.Topic>
          <S.Text>
            To us, generosity means filling in the gaps in society. We see the
            biggest need now is hope. Through generous acts, Absolute partners
            with individuals and businesses that want to be part of our mission
            to fill in the gap in communities.
          </S.Text>
          <S.Truncate $open={open}>
            1 in 5 children go to school without lunch. We are filling in the
            gap that will give kids in school something to work towards. This
            requires a partnering effort. Everyone is involved. Kids today feel
            like they don’t belong when they go to school without lunch.
          </S.Truncate>
          <S.Truncate $open={open}>
            This program will help support children to work together, elevate
            each other, and feed the children that currently aren’t able to
            afford lunch. Elevating and equipping the kids in the school to work
            together for a common goal, will bring hope and unity that may not
            have been there before.
          </S.Truncate>
        </Animate>
        <S.Button outline $open={open} onClick={() => setOpen(true)}>
          Read More +
        </S.Button>
      </S.Wrapper>
    </S.Generosity>
  );
};

const query = graphql`
  query {
    file(relativePath: { eq: "png/generosity.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;

export default Generosity;
