import styled from 'styled-components';

import COMP1 from 'src/components/Quote';
import COMP2 from 'src/components/NetlifyForm';

export const RequestPartnershipPage = styled.div`

`;


export const Quote = styled(COMP1)`
  margin: 0 auto 40px;

  @media(min-width: ${({ theme }) => theme.size.lg}) {
    margin: 0 auto 120px;
  }
`;

export const FormTitle = styled.h2`
  font-size: 2rem;
  text-align: center;
  padding: 0 16px;
  font-weight: 600;
  font-style: italic;
  font-family: ${({ theme }) => theme.font.secondary};

  @media(min-width: ${({ theme }) => theme.size.lg}) {
    font-size: 3rem;
  }
`;

export const NetlifyForm = styled(COMP2)`
  max-width: 1200px;
  padding: 0 16px;
  margin: 0 auto 120px;
`;
