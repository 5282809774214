export default async function updateLineItem(helpers, lineItem) {
  const { client, mutations, state, setState } = helpers;

  return client
    .request(mutations.checkoutLineItemsUpdate, {
      checkoutId: state.checkout.id,
      lineItems: [lineItem],
    })
    .then((data) => data.checkoutLineItemsUpdate.checkout)
    .then((checkout) => setState((newState) => ({ ...newState, checkout })));
}
