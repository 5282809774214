import styled from 'styled-components';
import { GatsbyImage as COMP1 } from 'gatsby-plugin-image';

export const Partnerships = styled.div`
  position: relative;
  padding: 0 16px;
  margin: 80px auto 120px;

  @media(min-width: ${({ theme }) => theme.size.md}) {
    max-width: 768px;
  }

  @media(min-width: ${({ theme }) => theme.size.lg}) {
    display: grid;
    grid-gap: 48px;
    max-width: 1200px;
    grid-template-columns: 1fr 532px;
    align-items: start;
    padding: 100px 0 16px;
  }
`;

export const Watermark = styled.span`
  display: none;

  @media(min-width: ${({ theme }) => theme.size.lg}) {
    display: inline;
    font-size: 175px;
    position: absolute;
    top: -44px;
    left: -100px;
    z-index: -1;
    font-weight: 800;
    color: ${({ theme }) => theme.hue.purple};
  }
`;

export const Image = styled(COMP1)`
  display: block;
  margin: 0 0 32px;

  @media(min-width: ${({ theme }) => theme.size.lg}) {
    order: 1;
    margin: calc((4rem * 1.15) + 12px) 0 0;
  }
`;

export const Wrapper = styled.div`

`;

export const Title = styled.h2`
  font-size: 2.125rem;
  font-weight: 600;
  font-style: italic;
  font-family: ${({ theme }) => theme.font.secondary};
  margin: 0 0 20px;

  @media(min-width: ${({ theme }) => theme.size.lg}) {
    font-size: 4rem;
  }
`;

export const Topic = styled.p`
  font-size: 1.5rem;
  font-style: italic;
  font-weight: 600;
  margin: 0 0 24px;
`;

export const Text = styled.p`
  font-size: 1.125rem;
  margin: 0 0 16px;
`;
