import React from 'react';
import propTypes from 'prop-types';
import { graphql } from 'gatsby';
import { mapPurposeToColor } from 'src/utilities/helpers';

import * as S from './Event.style';

function checkRegistrationClosed(dateString) {
  const currentTime = new Date();
  const closeTime = new Date(dateString);
  closeTime.setDate(closeTime.getDate() - 1);

  return currentTime > closeTime;
}

const Event = ({ event }) => {
  const meta = [
    {
      title: 'Location',
      icon: S.Icon,
      value: event.frontmatter.location,
    },
    {
      title: 'Cost',
      icon: S.Icon,
      value: <>${event.frontmatter.cost.toFixed(2)}</>,
    },
    {
      title: 'Dates',
      icon: S.Icon,
      value: event.frontmatter.dates
        .map(({ date, time }) => (
          <>
            {date} — {time}
          </>
        ))
        .reduce((acc, value) => (
          <>
            {acc}
            <br />
            {value}
          </>
        )),
    },
    {
      title: 'Teachers',
      icon: S.Icon,
      value: event.frontmatter.teachers,
    },
  ];

  const registrationClosed = checkRegistrationClosed(
    event.frontmatter.dates[0].originalDate
  );

  const handleClick = async () => {
    const res = await fetch('/api/event/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        event_id: event.slug,
      }),
    });

    const data = await res.json();

    if (res.ok && data && data.checkout_url) {
      window.location.href = data.checkout_url;
      return true;
    }

    return false;
  };

  return (
    <S.Event>
      <S.Meta>
        {meta.map((metaItem) => (
          <S.MetaItem key={metaItem.title}>
            <S.Title>
              <metaItem.icon />
              {metaItem.title}
            </S.Title>
            <S.Value>{metaItem.value}</S.Value>
          </S.MetaItem>
        ))}
        <S.Button
          onClick={handleClick}
          disabled={registrationClosed}
          messages={{ loading: '', success: 'Success', failure: 'Error' }}
        >
          {registrationClosed ? 'Registration closed' : 'Register Now'}
        </S.Button>
        <S.Line />
        {event.frontmatter.purposes.map((name) => (
          <S.Purpose key={name} $color={mapPurposeToColor(name)}>
            {name}
          </S.Purpose>
        ))}
        {event?.frontmatter?.tags?.length > 0 &&
          event.frontmatter.tags.map((name) => (
            <S.Tag key={name}>{name}</S.Tag>
          ))}
      </S.Meta>
      <S.Description dangerouslySetInnerHTML={{ __html: event.html }} />
    </S.Event>
  );
};

Event.propTypes = {
  event: propTypes.shape({
    slug: propTypes.string.isRequired,
    html: propTypes.string.isRequired,
    frontmatter: propTypes.shape({
      location: propTypes.string.isRequired,
      cost: propTypes.number.isRequired,
      dates: propTypes.arrayOf(
        propTypes.shape({
          date: propTypes.string.isRequired,
          time: propTypes.string.isRequired,
          originalDate: propTypes.string.isRequired,
        })
      ).isRequired,
      teachers: propTypes.string.isRequired,
      purposes: propTypes.arrayOf(propTypes.string).isRequired,
      tags: propTypes.arrayOf(propTypes.string),
    }).isRequired,
  }).isRequired,
};

export const EventFragment = graphql`
  fragment EventFragment on MarkdownRemark {
    slug
    html
    frontmatter {
      location
      cost
      dates {
        originalDate: date
        date(formatString: "MMMM Do")
        time
      }
      teachers
      purposes
      tags
    }
  }
`;

export default Event;
