import styled, { css } from 'styled-components';

export const Text = styled.div`
  margin-bottom: 32px;
  ${({ $name }) => $name && css`
    grid-area: ${$name};
  `}
`;

export const Input = styled.input`
  display: block;
  width: 100%;
  font-size: 1.125rem;
  border-radius: 6px;
  padding: 10px 16px;
  color: ${({ theme }) => theme.text.base};
  border: 1.5px solid ${({ theme }) => theme.color.purple};

  ::placeholder {
    color: #808080;
  }
`;

export const Label = styled.label`
  display: block;
  font-size: 1.125rem;
  margin: 0 0 8px;
  font-weight: 500;
  color: ${({ theme }) => theme.text.light};
`;
