import React, { useRef, useState } from 'react';
import propTypes from 'prop-types';

import useRouteChange from 'src/hooks/useRouteChange';

import * as S from './Submenu.style';

const Submenu = ({ title, menuOpen, subdirectory, desktop }) => {
  const collapsibleArea = useRef();
  const [submenuOpen, setSubmenuOpen] = useState(false);

  let maxHeight = 0;

  if (collapsibleArea.current && submenuOpen) {
    maxHeight = collapsibleArea.current.scrollHeight;
  }

  useRouteChange(() => {
    if (submenuOpen) setSubmenuOpen(false);
  });

  return (
    <S.Submenu
      onMouseEnter={() =>
        window.matchMedia('(hover: hover)').matches && setSubmenuOpen(true)
      }
      onMouseLeave={() =>
        window.matchMedia('(hover: hover)').matches && setSubmenuOpen(false)
      }
    >
      <S.Title onClick={() => setSubmenuOpen(!submenuOpen)}>
        {title}
        <S.TitleArrow $open={submenuOpen} />
      </S.Title>
      <S.CollapsibleArea
        ref={collapsibleArea}
        aria-hidden={!menuOpen || !submenuOpen}
        $maxHeight={maxHeight}
      >
        {Object.keys(subdirectory).map((subtitle) => (
          <S.Link
            key={subtitle}
            tabIndex={(menuOpen || desktop) && submenuOpen ? '0' : '-1'}
            to={subdirectory[subtitle]}
          >
            {subtitle}
          </S.Link>
        ))}
      </S.CollapsibleArea>
    </S.Submenu>
  );
};

Submenu.propTypes = {
  title: propTypes.string.isRequired,
  menuOpen: propTypes.bool.isRequired,
  subdirectory: propTypes.object.isRequired,
  desktop: propTypes.bool.isRequired,
};

export default Submenu;
