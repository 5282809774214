import styled from 'styled-components';

import { markdown } from 'src/utilities/css';

export const LegalPage = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  padding: 56px 16px 0;
  flex-direction: column;
  justify-content: center;

  @media(min-width: ${({ theme }) => theme.size.md}) {
    padding: 80px 16px 0;
  }
`;

export const Text = styled.div`
  ${markdown}
  width: 100%;
  max-width: 960px;
  margin: 60px auto 120px;
`;
