exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-cart-jsx": () => import("./../../../src/pages/cart.jsx" /* webpackChunkName: "component---src-pages-cart-jsx" */),
  "component---src-pages-contact-general-inquiries-jsx": () => import("./../../../src/pages/contact/general-inquiries.jsx" /* webpackChunkName: "component---src-pages-contact-general-inquiries-jsx" */),
  "component---src-pages-contact-invite-to-speak-jsx": () => import("./../../../src/pages/contact/invite-to-speak.jsx" /* webpackChunkName: "component---src-pages-contact-invite-to-speak-jsx" */),
  "component---src-pages-contact-request-partnership-jsx": () => import("./../../../src/pages/contact/request-partnership.jsx" /* webpackChunkName: "component---src-pages-contact-request-partnership-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-legal-jsx": () => import("./../../../src/pages/legal.jsx" /* webpackChunkName: "component---src-pages-legal-jsx" */),
  "component---src-pages-shop-jsx": () => import("./../../../src/pages/shop.jsx" /* webpackChunkName: "component---src-pages-shop-jsx" */),
  "component---src-templates-article-jsx": () => import("./../../../src/templates/article.jsx" /* webpackChunkName: "component---src-templates-article-jsx" */),
  "component---src-templates-blog-jsx": () => import("./../../../src/templates/blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-templates-event-jsx": () => import("./../../../src/templates/event.jsx" /* webpackChunkName: "component---src-templates-event-jsx" */),
  "component---src-templates-events-jsx": () => import("./../../../src/templates/events.jsx" /* webpackChunkName: "component---src-templates-events-jsx" */),
  "component---src-templates-product-jsx": () => import("./../../../src/templates/product.jsx" /* webpackChunkName: "component---src-templates-product-jsx" */),
  "component---src-templates-purpose-jsx": () => import("./../../../src/templates/purpose.jsx" /* webpackChunkName: "component---src-templates-purpose-jsx" */)
}

