import styled from 'styled-components';

import COMP1 from 'src/components/ArticleGrid';

export const BlogTemplate = styled.div`

`;

export const Headline = styled.h1`
  font-size: 2.125rem;
  letter-spacing: 0.04em;
  text-align: center;
  font-weight: 400;
  margin: 0 0 32px;
  color: ${({ theme }) => theme.text.white};


  @media(min-width: ${({ theme }) => theme.size.md}) {
    font-size: 4rem;
  }

  span {
    font-size: 2.5rem;
    position: relative;
    font-weight: 600;
    font-style: italic;
    font-family: ${({ theme }) => theme.font.secondary};

    @media(min-width: ${({ theme }) => theme.size.md}) {
      font-size: 4.5rem;
    }
  }
`;

export const Purpose = styled.p`
  font-size: 1.5rem;
  text-align: center;
  margin: 0 0 12px;
  color: ${({ theme }) => theme.text.white};
  font-weight: 600;
  font-style: italic;
  font-family: ${({ theme }) => theme.font.secondary};

  @media(min-width: ${({ theme }) => theme.size.md}) {
    font-size: 1.75rem;
  }
`;

export const Line = styled.hr`
  margin: 0;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid ${({ theme }) => theme.text.white};
`;

export const ArticleGrid = styled(COMP1)`
  max-width: 1200px;
  margin: 48px auto 120px;
`;
