import React, { useState } from 'react';
import propTypes from 'prop-types';
import { graphql } from 'gatsby';

import SEO from 'src/components/SEO';
import { Text, Select, Textarea } from 'src/components/Inputs';

import PageHero from 'src/sections/PageHero';
import Speaking from 'src/sections/Speaking';

import * as S from './invite-to-speak.style';

const initialValue = {
  firstName: '',
  lastName: '',
  emailAddress: '',
  phoneNumber: '',
  organizationName: '',
  relationshipToOrganization: '',
  eventAddress: '',
  estimatedAtendees: '',
  groupDetails: '',
  purpose: '',
  description: '',
  explanation: '',
};

const InviteToSpeakPage = ({ data, useAttributes }) => {
  const [value, setValue] = useState(initialValue);
  useAttributes({ initiallyTransparent: true });

  return (
    <S.InviteToSpeakPage>
      <SEO title="Invite Us To Speak | Absolute Training Centre" />
      <PageHero
        alt="Andrea Fraser speaking on stage"
        image={data.file}
        title="Invite Us To Speak"
      />
      <S.Quote size="large">Building a vision of unity together.</S.Quote>
      <Speaking />
      <S.FormTitle>Ready to Begin? Fill Out The Form Below</S.FormTitle>
      <S.NetlifyForm
        name="Invite To Speak Form"
        template={[
          'FirstName LastName',
          'EmailAddress PhoneNumber',
          'OrganizationName RelationshipToOrganization',
          'EventAddress EstimatedAtendees',
          'GroupDetails Purpose',
          'Description Description',
          'Explanation Explanation',
        ]}
        onSubmit={() => setValue(initialValue)}
      >
        <Text
          required
          id="FirstNameInput"
          label="First Name"
          name="FirstName"
          value={value.firstName}
          onChange={(newValue) =>
            setValue((state) => ({ ...state, firstName: newValue }))
          }
        />
        <Text
          required
          id="LastNameInput"
          label="Last Name"
          name="LastName"
          value={value.lastName}
          onChange={(newValue) =>
            setValue((state) => ({ ...state, lastName: newValue }))
          }
        />
        <Text
          required
          type="email"
          id="EmailAddressInput"
          label="Email Address"
          name="EmailAddress"
          value={value.emailAddress}
          onChange={(newValue) =>
            setValue((state) => ({ ...state, emailAddress: newValue }))
          }
        />
        <Text
          required
          type="tel"
          id="PhoneNumberInput"
          label="Phone Number"
          name="PhoneNumber"
          value={value.phoneNumber}
          onChange={(newValue) =>
            setValue((state) => ({ ...state, phoneNumber: newValue }))
          }
        />
        <Text
          required
          id="OrganizationNameInput"
          label="Organization Name"
          name="OrganizationName"
          value={value.organizationName}
          onChange={(newValue) =>
            setValue((state) => ({ ...state, organizationName: newValue }))
          }
        />
        <Text
          required
          id="RelationshipToOrganizationInput"
          label="Relationship To Organization"
          name="RelationshipToOrganization"
          value={value.relationshipToOrganization}
          onChange={(newValue) =>
            setValue((state) => ({
              ...state,
              relationshipToOrganization: newValue,
            }))
          }
        />
        <Text
          required
          id="EventAddressInput"
          label="Event Address"
          name="EventAddress"
          value={value.eventAddress}
          onChange={(newValue) =>
            setValue((state) => ({ ...state, eventAddress: newValue }))
          }
        />
        <Text
          required
          id="EstimatedAtendeesInput"
          label="Estimated Atendees"
          name="EstimatedAtendees"
          value={value.estimatedAtendees}
          onChange={(newValue) =>
            setValue((state) => ({ ...state, estimatedAtendees: newValue }))
          }
        />
        <Text
          required
          id="GroupDetailsInput"
          label="Group Details (Gender, Age, etc)"
          name="GroupDetails"
          value={value.groupDetails}
          onChange={(newValue) =>
            setValue((state) => ({ ...state, groupDetails: newValue }))
          }
        />
        <Select
          required
          id="PurposeInput"
          label="Which purpose would you like us to speak on?"
          name="Purpose"
          options={['Well-being', 'Family', 'Business', 'Life']}
          value={value.purpose}
          onChange={(newValue) =>
            setValue((state) => ({ ...state, purpose: newValue }))
          }
        />
        <Textarea
          required
          id="DescriptionInput"
          label="What outcome would you like to achieve from having us speak with you?"
          name="Description"
          value={value.description}
          onChange={(newValue) =>
            setValue((state) => ({ ...state, description: newValue }))
          }
        />
        <Textarea
          required
          id="ExplanationInput"
          label="Why is achieving that outcome important to you or your organization right now?"
          name="Explanation"
          value={value.explanation}
          onChange={(newValue) =>
            setValue((state) => ({ ...state, explanation: newValue }))
          }
        />
      </S.NetlifyForm>
    </S.InviteToSpeakPage>
  );
};

export const query = graphql`
  query {
    file(relativePath: { eq: "png/invite-to-speak-hero.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;

InviteToSpeakPage.defaultProps = {
  useAttributes: undefined,
};

InviteToSpeakPage.propTypes = {
  data: propTypes.shape({
    file: propTypes.object.isRequired,
  }).isRequired,
  useAttributes: propTypes.func,
};

export default InviteToSpeakPage;
