import React, { useState, cloneElement } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Animate from 'react-animate-on-scroll';

import { getImageByName } from 'src/utilities/helpers';

import * as S from './Leadership.style';

const members = [
  {
    name: 'Andrea Fraser',
    title: 'President',
    fileName: 'andrea-fraser',
    description: [
      <S.Text>
        Andrea is the founding creator of Absolute Training Centre. She is
        passionate about people discovering the treasures that are inside them.
        Andrea has a gifted ability to see the treasures that are inside of
        people, and the potential that waits to be awakened in them.
        Andrea&apos;s purpose is to awaken and strengthen individuals to reach
        their true potential, and live from that place. Andrea is a teacher,
        author, speaker, recipe creator and developer of Andrea&apos;s Healthy
        Kitchen. She communicates her convictions of life, well-being, family,
        and business in everything she does. She embraces life, and lives
        everyday fully, and with intention.
      </S.Text>,
      <S.Text>
        Andrea&apos;s conviction for living out your best life comes from her
        personal testimony, and the pursuit of becoming the best version of
        herself knowing and believing it is the only way to impact the world.
        Andrea has over 22 years of health and wellness experience, along with
        20 years in business. Her teaching and speaking on well-being, family
        and business has impacted thousands of people directly and indirectly.
      </S.Text>,
      <S.Text>
        Andrea has learned how health and wealth are directly correlated. She is
        passionate about teaching the connection of health and wealth, and its
        impacts on family and life. Her driving factor is she knows the impacts
        that a person&apos;s well-being, has on their family, and others.
        Andrea&apos;s mission is to teach people to slow down, appreciate the
        small things, and enjoy life. Andrea knows, absolute wellness is a state
        of being.
      </S.Text>,
    ],
  },
  {
    name: 'Bryan Fraser',
    title: 'Vice President',
    fileName: 'bryan-fraser',
    description: [
      <S.Text>
        Bryan Fraser is the power behind the creation of Absolute Training
        Centre. Bryan has a diverse background in business, home building
        construction, athletics coaching and life coaching. He has a passion and
        desire to see people win! Bryan&apos;s purpose is to build champion
        teams and organizations. He thrives when people are committed to
        overcoming the obstacles and challenges life brings them, as he knows it
        elevates the champion within. His desire to bring out the best in
        everyone is contagious and is communication style is both humorous and
        exposing to the heart of the issue.
      </S.Text>,
      <S.Text>
        Bryan has a unique ability of connecting with people who want to live
        out their best in their own lives, families and careers! His many
        experiences working or playing with team players, he knows first hand
        what it takes to thrive on a winning team. Bryan holds a standard of
        excellence for himself, and those wanting to be elevated. He has
        equipped thousands of people to elevate to a higher standard for their
        lives.
      </S.Text>,
      <S.Text>
        At the heart of Bryan, everything is about team. And being on a winning
        team, that is making a difference. He&apos;s experienced both, and is
        committed to building champion teams & families. He has never been more
        inspired to learn, grow and do more in his life, and helping others do
        the same.
      </S.Text>,
      <S.Text>
        Bryan has four beautiful children: Kaitlyn, Ryland, Mackenzie and
        Alexis. Together Bryan and Andrea have encouraged each other and their
        kids to value their dreams, themselves, and other people. Bryan lives by
        what we leave inside our children, is more important than what we leave
        for them.
      </S.Text>,
    ],
  },
];

const Leadership = () => {
  const data = useStaticQuery(query);
  const [open, setOpen] = useState(members.map(() => false));

  return (
    <S.Leadership>
      <S.Watermark aria-hidden="true">Leadership</S.Watermark>
      <S.SectionTitle>Leadership</S.SectionTitle>
      <S.Members>
        {members.map((member, i) => (
          <S.Member key={member.name}>
            <Animate animateOnce animateIn="fadeIn">
              <S.Headshot
                alt={`${member.name}s Headshot`}
                image={getImageByName(data.headshots.nodes, member.fileName)}
              />
            </Animate>
            <Animate animateOnce animateIn="fadeInUp">
              <S.Name>{member.name}</S.Name>
            </Animate>
            <Animate animateOnce animateIn="fadeInUp">
              <S.Title>{member.title}</S.Title>
            </Animate>
            <Animate
              animateOnce
              animateIn={i % 2 === 0 ? 'slideInLeft' : 'slideInRight'}
            >
              <S.Description>
                {member.description.map((paragraph, j) =>
                  cloneElement(paragraph, {
                    key: paragraph.props.children,
                    $truncate: j !== 0 && !open[i],
                  })
                )}
                {!open[i] && (
                  <S.ReadMore
                    onClick={() =>
                      setOpen(
                        open.map((value, j) => (j === i ? !value : value))
                      )
                    }
                  >
                    Read More...
                  </S.ReadMore>
                )}
              </S.Description>
            </Animate>
          </S.Member>
        ))}
      </S.Members>
    </S.Leadership>
  );
};

export const query = graphql`
  query {
    headshots: allFile(
      filter: { relativePath: { regex: "/^png/headshots/.*$/" } }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(width: 400)
        }
      }
    }
  }
`;

export default Leadership;
