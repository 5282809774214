import React from 'react';
import propTypes from 'prop-types';

import * as S from './Quote.style';

const Quote = ({ size, className, children }) => (
  <S.Quote className={className}>
    <S.Mark $size={size} />
    <S.Text $size={size}>{children}</S.Text>
  </S.Quote>
);

Quote.defaultProps = {
  className: undefined,
};

Quote.propTypes = {
  size: propTypes.oneOf(['small', 'large']).isRequired,
  className: propTypes.string,
  children: propTypes.node.isRequired,
};

export default Quote;
