import React, { useState, useContext } from 'react';
import propTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import ShopifyContext from 'src/contexts/ShopifyContext';

import useActiveMedia from 'src/hooks/useActiveMedia';
import useSelectedVariant from 'src/hooks/useSelectedVariant';

import Quantity from 'src/components/Quantity';
import SocialIcons from 'src/components/SocialIcons';

import * as S from './Product.style';

const Product = ({ product }) => {
  const data = useStaticQuery(query);
  const [quantity, setQuantity] = useState(1);
  const { addLineItem } = useContext(ShopifyContext);
  const [selectedVariant, setSelectedVariantId] = useSelectedVariant(
    product.variants
  );
  const [activeMedia, setActiveMedia] = useActiveMedia(
    selectedVariant,
    product.media
  );

  const isValueSelected = (name, value) => {
    for (const option of selectedVariant.selectedOptions) {
      if (option.name === name && option.value === value) return true;
    }
    return false;
  };

  const getVariantByOption = (name, value) => {
    // Update the correct option with the new value
    const selectedOptions = selectedVariant.selectedOptions.map(
      (selectedOption) => {
        if (name === selectedOption.name) {
          return { ...selectedOption, value };
        }

        return selectedOption;
      }
    );

    // Find a variant with the correct selectedOptions and set the new variant ID
    for (const variant of product.variants) {
      let isSelectedVariant = true;
      for (const i in variant.selectedOptions) {
        if (variant.selectedOptions[i].value !== selectedOptions[i].value) {
          isSelectedVariant = false;
          break;
        }
      }
      if (isSelectedVariant) {
        return variant;
      }
    }

    return null;
  };

  const url = encodeURIComponent(
    typeof window !== 'undefined' && window.location.href
  );

  const outOfStock =
    selectedVariant.inventoryPolicy !== 'CONTINUE' &&
    selectedVariant.inventoryQuantity === 0;

  return (
    <S.Product>
      <S.ImageSlider>
        <S.ActiveImage
          alt={
            product.media.length > 0
              ? product.media[activeMedia].preview.image.altText || ''
              : 'Image Coming Soon'
          }
          image={
            product.media.length > 0
              ? product.media[activeMedia].preview.image.gatsbyImageData
              : data.file.childImageSharp.gatsbyImageData
          }
        />
        <S.ImagePreviews>
          {product.media.map((medium, i) => (
            <S.ImagePreviewButton
              key={medium.id}
              onClick={() => setActiveMedia(i)}
              $active={i === activeMedia}
            >
              <S.ImagePreview
                alt={medium.preview.image.altText || ''}
                image={medium.preview.image.gatsbyImageData}
              />
            </S.ImagePreviewButton>
          ))}
        </S.ImagePreviews>
      </S.ImageSlider>
      <S.Meta>
        <S.Title>{product.title}</S.Title>
        <S.Price>
          ${selectedVariant.price}
          {selectedVariant.compareAtPrice && (
            <S.CompareAtPrice>
              ${selectedVariant.compareAtPrice}
            </S.CompareAtPrice>
          )}
        </S.Price>
        <S.Description
          dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}
        />
        <S.Options>
          {!product.hasOnlyDefaultVariant &&
            product.options.map((option) => (
              <React.Fragment key={option.name}>
                <S.Label>{option.name}</S.Label>
                <S.Option>
                  {option.values.map((value) => (
                    <S.Value
                      key={value}
                      disabled={!getVariantByOption(option.name, value)}
                      $selected={isValueSelected(option.name, value)}
                      onClick={() =>
                        setSelectedVariantId(
                          getVariantByOption(option.name, value)
                            .legacyResourceId
                        )
                      }
                    >
                      {value}
                    </S.Value>
                  ))}
                </S.Option>
              </React.Fragment>
            ))}
          <S.Label>Quantity</S.Label>
          <Quantity value={quantity} setValue={setQuantity} />
        </S.Options>
        <S.Button
          messages={{
            loading: 'Adding To Cart',
            success: 'Added To Cart',
            failure: 'Something Went Wrong',
          }}
          onClick={async () => {
            await addLineItem({
              variantId: selectedVariant.storefrontId,
              quantity,
            });
            return true;
          }}
          disabled={outOfStock}
        >
          {outOfStock ? 'Out Of Stock' : 'Add To Cart'}
        </S.Button>
        <S.Line />
        <S.Share>
          <S.ShareText>Share this product: </S.ShareText>
          <SocialIcons
            size="large"
            family="grey"
            links={[
              {
                url: `https://www.facebook.com/sharer.php?u=${url}`,
                icon: 'Facebook',
              },
              {
                url: `https://twitter.com/intent/tweet?url=${url}`,
                icon: 'Twitter',
              },
            ]}
          />
        </S.Share>
      </S.Meta>
    </S.Product>
  );
};

Product.propTypes = {
  product: propTypes.shape({
    title: propTypes.string.isRequired,
    handle: propTypes.string.isRequired,
    descriptionHtml: propTypes.string.isRequired,
    hasOnlyDefaultVariant: propTypes.bool.isRequired,
    media: propTypes.arrayOf(
      propTypes.shape({
        id: propTypes.string.isRequired,
        preview: propTypes.shape({
          image: propTypes.shape({
            altText: propTypes.string,
            gatsbyImageData: propTypes.object.isRequired,
          }).isRequired,
        }).isRequired,
      })
    ).isRequired,
    options: propTypes.arrayOf(
      propTypes.shape({
        name: propTypes.string.isRequired,
        values: propTypes.arrayOf(propTypes.string).isRequired,
      })
    ).isRequired,
    productType: propTypes.string.isRequired,
    storefrontId: propTypes.string.isRequired,
    tags: propTypes.arrayOf(propTypes.string).isRequired,
    totalInventory: propTypes.number.isRequired,
    variants: propTypes.arrayOf(
      propTypes.shape({
        price: propTypes.number.isRequired,
        compareAtPrice: propTypes.number,
        availableForSale: propTypes.bool.isRequired,
        displayName: propTypes.string.isRequired,
        id: propTypes.string.isRequired,
        inventoryQuantity: propTypes.number.isRequired,
        legacyResourceId: propTypes.string.isRequired,
        position: propTypes.number.isRequired,
        storefrontId: propTypes.string.isRequired,
        title: propTypes.string.isRequired,
        selectedOptions: propTypes.arrayOf(
          propTypes.shape({
            name: propTypes.string.isRequired,
            value: propTypes.string.isRequired,
          })
        ).isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export const ProductFragment = graphql`
  fragment ProductFragment on ShopifyProduct {
    title
    handle
    excerpt
    descriptionHtml
    hasOnlyDefaultVariant
    media {
      id
      preview {
        image {
          altText
          gatsbyImageData
        }
      }
    }
    options {
      name
      values
    }
    productType
    storefrontId
    tags
    totalInventory
    variants {
      price
      compareAtPrice
      availableForSale
      displayName
      id
      inventoryPolicy
      inventoryQuantity
      legacyResourceId
      media {
        id
      }
      position
      storefrontId
      title
      selectedOptions {
        name
        value
      }
    }
  }
`;

const query = graphql`
  query {
    file(relativePath: { eq: "png/coming-soon.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;

export default Product;
