import { gql } from 'graphql-request';

import CheckoutFragment from '../fragments/CheckoutFragment';
import CheckoutLineItemFragment from '../fragments/CheckoutLineItemFragment';
import VariantFragment from '../fragments/VariantFragment';

export default gql`
  ${CheckoutFragment}
  ${CheckoutLineItemFragment}
  ${VariantFragment}
  mutation ($input: CheckoutCreateInput!) {
    checkoutCreate(input: $input) {
      checkout {
        ...CheckoutFragment
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`;
