import styled from 'styled-components';

export const Form = styled.form`

`;

export const FormGrid = styled.div`
  @media(min-width: ${({ theme }) => theme.size.lg}) {
    display: grid;
    grid-column-gap: 32px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: ${({ $template }) => $template.map((row) => `"${row}"`)};
  }
`;

export const MessageBanner = styled.div`
  border-radius: 4px;
  padding: 8px 16px;
  margin: -16px 0 16px;
  font-weight: 500;
  border: 2px solid ${({ theme }) => theme.color.green};
  color: ${({ theme }) => theme.color.green};

  span {
    font-weight: 700;
    margin-right: 4px;
  }
`;

export const ErrorBanner = styled.div`
  border-radius: 4px;
  padding: 8px 16px;
  margin: -16px 0 16px;
  border: 2px solid ${({ theme }) => theme.color.purple};
  background: rgba(190, 30, 45, 0.3);
  color: ${({ theme }) => theme.color.purple};

  span {
    font-weight: 700;
    margin-right: 4px;
  }
`;
