import styled from 'styled-components';

import COMP1 from "src/components/Button";

export const EventsTemplate = styled.div`
  margin: 0 0 120px;
`;

export const NoneFound = styled.p`
  font-size: 1.25rem;
  margin: 80px auto 0;
  max-width: 640px;
  font-weight: 600;
  text-align: center;
  padding: 0 16px;
`;

export const Group = styled.div`
  max-width: 1200px;
  margin: 48px auto 120px;
`;

export const GroupTitle = styled.h2`
  font-size: 2.5rem;
  padding: 0 16px;
  margin: 0 0 32px;
  font-weight: 600;
  font-style: italic;
  font-family: ${({ theme }) => theme.font.secondary};
  text-transform: capitalize;

  @media(min-width: ${({ theme }) => theme.size.md}) {
    font-size: 3.25rem;
  }
`;

export const Button = styled(COMP1)`
  display: block;
  margin: 0 auto;
`;