import styled from 'styled-components';

import COMP1 from 'src/components/ProductGrid';

export const ShopPage = styled.div`

`;

export const ProductGrid = styled(COMP1)`
  max-width: 1200px;
  margin: 40px auto 120px;
`;
