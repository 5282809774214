import styled from 'styled-components';
import { GatsbyImage as COMP1 } from 'gatsby-plugin-image';

export const PageHero = styled.div`
  position: relative;
  padding: 80px 0 0;
`;

export const Image = styled(COMP1)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0.5);
`;

export const Title = styled.h1`
  font-size: 2.5rem;
  text-align: center;
  font-weight: 500;
  color: ${({ theme }) => theme.text.white};

  span {
    font-size: 2.5rem;
    font-weight: 600;
    font-style: italic;
    font-family: ${({ theme }) => theme.font.secondary};
  }

  @media(min-width: ${({ theme }) => theme.size.md}) {
    font-size: 4.5rem;

    span {
      font-size: 4.5rem;
    }
  }
`;

export const Content = styled.div`
  margin: 0 auto;
  padding: 48px 16px;
  max-width: ${({ $wide }) => $wide ? '1056px' : '768px'};
`;
