import styled from 'styled-components';
import { GatsbyImage as COMP1 } from 'gatsby-plugin-image';

export const Leadership = styled.div`
  position: relative;
  padding: 0 16px;
  margin: 64px auto 80px;

  @media(min-width: ${({ theme }) => theme.size.lg}) {
    max-width: 1200px;
  }

  @media(min-width: ${({ theme }) => theme.size.xl}) {
    padding: 124px 16px 0;
  }
`;

export const Watermark = styled.span`
  display: none;

  @media(min-width: ${({ theme }) => theme.size.xl}) {
    display: inline;
    font-size: 175px;
    position: absolute;
    top: -32px;
    left: -32px;
    z-index: -1;
    font-weight: 800;
    color: ${({ theme }) => theme.hue.purple};
  }
`;

export const SectionTitle = styled.h1`
  font-size: 2.125rem;
  margin: 0 0 32px;
  text-align: center;

  @media(min-width: ${({ theme }) => theme.size.md}) {
    font-size: 4rem;
    margin: 0 0 48px;
  }
`;

export const Members = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;

  @media(min-width: ${({ theme }) => theme.size.md}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 96px;
  }
`;

export const Member = styled.div`

`;

export const Text = styled.p`
  display: ${({ $truncate }) => $truncate ? 'none' : 'block'};
`;

export const Headshot = styled(COMP1)`
  display: block;
  width: 120px;
  height: 120px;
  float: left;
  margin: 0 24px 16px 0;
`;

export const Name = styled.h4`
  font-size: 1.75rem;
  padding: 28px 0 0;
  margin: 0 0 4px;
`;

export const Title = styled.h6`
  font-size: 1.125rem;
  font-weight: 400;
  margin: 0;
  font-style: italic;
  color: ${({ theme }) => theme.text.light};
`;

export const Description = styled.div`
  font-size: 1.125rem;
  clear: both;
`;

export const ReadMore = styled.button`
  background: none;
  border: none;
  padding: 0;
  font-style: italic;
  cursor: pointer;
  color: ${({ theme }) => theme.color.purple};
  text-decoration: underline;
  transition: color 0.25s;

  :hover {
    color: ${({ theme }) => theme.color.active.purple};
  }
`;
