import styled from 'styled-components';
import { Link as COMP1 } from 'gatsby';

import { PlayArrow as SVG1 } from '@styled-icons/material-rounded';


export const Back = styled.div`
  margin: 0 0 24px;
  border-bottom: 1px solid ${({ theme }) => theme.bg.mediumGrey};
`;

export const Wrapper = styled.div`
  max-width: 768px;
  margin: 0 auto;
  padding: 0 16px;
`;

export const Link = styled(COMP1)`
  display: block;
  font-size: 1rem;
  width: fit-content;
  padding: 12px 0;
  text-decoration: none;
  color: ${({ theme }) => theme.text.base};

  :hover {
    color: ${({ theme }) => theme.text.hover.base};
  }
`;

export const LinkArrow = styled(SVG1)`
  display: inline;
  width: 23.5px;
  height: 23.5px;
  margin-top: -2.5px;
  transform: rotate(180deg);
`;
