import styled from 'styled-components';

export const AboutUsPage = styled.div`

`;

export const Headline = styled.h1`
  text-align: center;
  font-weight: 400;
  margin: 0 0 60px;
  color: ${({ theme }) => theme.text.white};
`;

export const Highlight = styled.span`
  font-weight: 600;
  font-style: italic;
`;

export const Intro = styled.span`
  font-size: 2.125rem;
  letter-spacing: 0.04em;
  font-weight: 600;
  font-style: italic;
  color: ${({ theme }) => theme.text.white};

  @media(min-width: ${({ theme }) => theme.size.md}) {
    font-size: 4rem;
  }
`;

export const Purpose = styled.p`
  font-size: 1.5rem;
  text-align: center;
  margin: 0 0 12px;
  font-weight: 600;
  font-style: italic;
  color: ${({ theme }) => theme.text.white};
  font-family: ${({ theme }) => theme.font.secondary};

  @media(min-width: ${({ theme }) => theme.size.md}) {
    font-size: 1.75rem;
  }
`;

export const Line = styled.hr`
  margin: 0;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid ${({ theme }) => theme.text.white};
`;
