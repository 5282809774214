import styled, { css } from 'styled-components';
import { Link as COMP1 } from 'gatsby';
import { GatsbyImage as COMP2 } from 'gatsby-plugin-image';

import COMP3 from 'src/components/Button';

export const ProductGrid = styled.div`
  overflow-x: auto;
`;

export const NoneFound = styled.p`
  font-size: 1.25rem;
  margin: 0 auto;
  max-width: 640px;
  font-weight: 600;
  text-align: center;
  padding: 0 16px;
`;

export const Wrapper = styled.div`
  display: grid;
  grid-gap: 32px;
  padding: 16px;
  margin: 0 auto;
  align-items: center;
  justify-items: center;

  @media(min-width: ${({ theme }) => theme.size.md}) {
    grid-gap: 64px;
  }
`;

export const Card = styled(COMP1)`
  position: relative;
  display: flex;
  gap: 16px;
  flex-direction: column;
  padding: 16px;
  font-size: 1rem;
  width: 100%;
  cursor: pointer;
  color: inherit;
  border-radius: 16px;
  text-decoration: none;
  transition: box-shadow 0.3s;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.125);

  :hover {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
  }

  @media(min-width: ${({ theme }) => theme.size.md}) {
    flex-direction: row;
    grid-gap: 120px;
    max-width: 960px;
    padding: 24px;

    > * {
      flex: 1 1 0px;
    }

    ${({ $reverse }) => $reverse && css`
      flex-direction: row-reverse;
    `}
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  margin: 0 0 10px;

  @media(min-width: ${({ theme }) => theme.size.md}) {
    margin: 0;
  }
`;

export const OutOfStock = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`;

export const Indicator = styled.span`
  font-weight: 600;
  font-size: 1.125rem;
  padding: 8px 14px;
  border-radius: 4px;
  color: $P
  font-weight: 600;
  font-size: 1.125rem;
  background: rgba(238, 236, 246, 0.85);
`;

export const Image = styled(COMP2).attrs(() => ({
  imgStyle: { objectFit: 'contain' },
}))`
  display: block;

  height: 0;
  width: 100%;
  padding-bottom: calc(100%);
`;

export const Content = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-rows: 1fr auto;
  align-content: end;
`;

export const Meta = styled.div`
  direction: ltr;
`;

export const Title = styled.h4`
  font-size: 1.5rem;
  margin: 0 0 6px;
`;

export const Price = styled.span`
  display: block;
  font-size: 1.125rem;
  font-weight: 600;
  margin: 0 0 16px;

  @media(min-width: ${({ theme }) => theme.size.md}) {
    font-size: 1.25rem;
    font-weight: 400;
    font-style: italic;
  }
`;

export const CompareAtPrice = styled.span`
  font-size: 0.875rem;
  color: #BD1816;
  text-decoration: line-through;
  margin-left: 8px;
`;

export const Excerpt = styled.p`
  margin: 0 0 30px;
`;

export const Tag = styled.span`
  font-size: 12px;
  display: inline-block;
  line-height: 1;
  padding: 4px 12px;
  border-radius: 12px;
  margin: 0 8px 8px 0;
  border: 2px solid ${({ theme }) => theme.bg.lightGrey};
`;

export const Link = styled.button`
  width: 100%;
  display: block;
  padding: 16px 0 0;
  text-align: center;
  color: ${({ theme }) => theme.text.base};
  background: none;
  border-left: none;
  border-right: none;
  border-bottom: none;
  cursor: pointer;
  border-top: 1px solid ${({ theme }) => theme.bg.lightGrey};

  :hover {
    color: ${({ theme }) => theme.text.hover.base};
  }

  :active {
    color: ${({ theme }) => theme.text.active.base};
  }
`;

export const LoadMore = styled(COMP3)`
  display: block;
  margin: 40px auto 0;
`;
