import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import * as S from './Speaking.style';

const Speaking = () => {
  const data = useStaticQuery(query);

  return (
    <S.Speaking>
      <S.Topic>
        As people begin to unlock their potential, everything within their reach
        is impacted.
      </S.Topic>
      <S.Text>
        Inviting Absolute Training Centre to speak with your leadership team,
        community group, business, sports team, church, school; will elevate
        your group to see within themselves what they are capable of. Absolute
        will inspire your people with real-life stories and timeless principles
        that are backed up with actionable steps to elevate you, your family,
        and your team.
      </S.Text>
      <S.Tagline>Well-being. Family. Business. Life.</S.Tagline>
      <S.Text>
        If you&apos;re looking to elevate & equip your team, business or
        organization fill out the form below and you will be contacted within 5
        business days to discuss your request in more detail.
      </S.Text>
      <S.Image
        $side="left"
        alt="Andrea speaking on stage"
        image={data.andrea.childImageSharp.gatsbyImageData}
      />
      <S.Image
        $side="right"
        alt="A Microphone"
        image={data.microphone.childImageSharp.gatsbyImageData}
      />
    </S.Speaking>
  );
};

const query = graphql`
  query {
    andrea: file(relativePath: { eq: "png/andrea-speaking.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    microphone: file(relativePath: { eq: "png/microphone.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;

export default Speaking;
