import styled from 'styled-components';

import COMP1 from 'src/components/Button';

export const Cart = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto 120px;
  flex-grow: 1;
`;

export const NoneFound = styled.p`
  font-size: 1.25rem;
  margin: 0 auto;
  max-width: 640px;
  font-weight: 600;
  text-align: center;
  padding: 0 16px;
`;

export const Wrapper = styled.div`
  display: grid;
  grid-gap: 32px;

  @media(min-width: ${({ theme }) => theme.size.md }) {
    grid-gap: 64px;
    grid-template-columns: 1.8fr 1fr;
  }
`;

export const Information = styled.div`
  padding: 16px;
`;

export const Data = styled.h4`
  font-size: 1.25rem;
  margin: 0 0 20px;
  padding: 0 0 20px;

  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.bg.mediumGrey};
  }

  span {
    float: right;
    font-weight: 400;
  }
`;

export const Button = styled(COMP1)`
  display: block;
  width: 100%;
  margin: 0 0 8px;
`;

export const DiscountText = styled.p`
  font-size: 0.875rem;
  margin: 0;
`;
