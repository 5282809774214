import styled from 'styled-components';
import { GatsbyImage as COMP1 } from 'gatsby-plugin-image';

import { markdown } from 'src/utilities/css';

export const Article = styled.article`
  padding: 0 16px;
  max-width: 768px;
  margin: 0 auto;
`;

export const Purpose = styled.span`
  font-size: 12px;
  display: inline-block;
  line-height: 1;
  padding: 4px 12px;
  border-radius: 12px;
  margin: 0 8px 16px 0;
  border: 2px solid ${({ $color, theme }) => theme.color[$color]};
`;

export const Title = styled.h1`
  font-size: 2rem;
  margin: 0 0 10px;

  @media(min-width: ${({ theme }) => theme.size.md}) {
    font-size: 2.5rem;
    margin: 0 0 16px;
  }
`;

export const Excerpt = styled.p`
  font-size: 1.125rem;
  margin: 0 0 20px;

  @media(min-width: ${({ theme }) => theme.size.md}) {
    font-size: 1.25rem;
    margin: 0 0 24px;
  }
`;

export const Meta = styled.div`
  margin: 0 0 24px;

  :after {
    clear: both;
  }
`;

export const Headshot = styled(COMP1)`
  float: left;
  width: 48px;
  height: 48px;
  margin: 0 12px 0 0;
  border-radius: 24px;
`;

export const Author = styled.h5`
  font-size: 1rem;
  margin: 0;
  padding: 4px 0;
`;

export const PublishDate = styled.h6`
  font-size: 0.875rem;
  margin: 0;
  font-weight: 400;
  color: ${({ theme }) => theme.text.light};
`;

export const Tag = styled.span`
  font-size: 12px;
  display: inline-block;
  line-height: 1;
  padding: 4px 12px;
  border-radius: 12px;
  margin: 0 8px 16px 0;
  border: 2px solid ${({ theme }) => theme.bg.lightGrey};
`;

export const FeaturedImage = styled(COMP1)`
  display: block;
  height: 0;
  border-radius: 8px;
  margin: 16px 0 48px;
  padding-bottom: calc(100% * 3 / 5);
`;

export const Body = styled.div`
  ${markdown}
  font-size: 1.125rem;
  margin: 0 0 80px;
`;
