export default async function removeLineItem(helpers, lineItemId) {
  const { client, mutations, state, setState } = helpers;

  return client
    .request(mutations.checkoutLineItemsRemove, {
      checkoutId: state.checkout.id,
      lineItemIds: [lineItemId],
    })
    .then((data) => data.checkoutLineItemsRemove.checkout)
    .then((checkout) => setState((newState) => ({ ...newState, checkout })));
}
