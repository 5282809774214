import React from 'react';
import propTypes from 'prop-types';
import { graphql } from 'gatsby';
import { useQueryParam, withDefault, NumberParam } from 'use-query-params';

import { mapPurposeToColor } from 'src/utilities/helpers';

import * as S from './ArticleGrid.style';

const articlesPerPage = 18;

const ArticleGrid = ({ articles, family, paginate, className }) => {
  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 1));

  return (
    <S.ArticleGrid className={className}>
      {articles.length === 0 && (
        <S.NoneFound>
          It looks like we haven&apos;t written any articles in this category
          yet. Maybe it&apos;s time to contact us about it.
        </S.NoneFound>
      )}
      <S.Wrapper $family={family}>
        {(paginate ? articles.slice(0, page * articlesPerPage) : articles).map(
          (article, i) => (
            <S.Card
              key={article.slug}
              $featured={i === 0}
              to={`/article/${article.slug}`}
            >
              <S.Image
                alt={article.frontmatter.featuredImageAlt}
                image={
                  article.frontmatter.featuredImage.childImageSharp
                    .gatsbyImageData
                }
              />
              <S.Content>
                <S.Meta>
                  <S.Purposes>
                    {article.frontmatter.purposes.map((name) => (
                      <S.Purpose key={name} $color={mapPurposeToColor(name)}>
                        {name}
                      </S.Purpose>
                    ))}
                  </S.Purposes>
                  <S.Title>{article.frontmatter.title}</S.Title>
                  <S.Excerpt>{article.excerpt}</S.Excerpt>
                  <S.Info $featured={i === 0}>
                    <S.Label>Author:</S.Label> {article.frontmatter.author}
                  </S.Info>
                  <S.Info $featured={i === 0}>
                    <S.Label>Published:</S.Label> {article.frontmatter.date}
                  </S.Info>
                </S.Meta>
                <S.Link>Read Article</S.Link>
              </S.Content>
            </S.Card>
          )
        )}
      </S.Wrapper>
      {paginate && articles.length > page * articlesPerPage && (
        <S.LoadMore outline onClick={() => setPage(page + 1)}>
          Load More Articles
        </S.LoadMore>
      )}
    </S.ArticleGrid>
  );
};

ArticleGrid.defaultProps = {
  paginate: false,
  className: undefined,
};

ArticleGrid.propTypes = {
  articles: propTypes.arrayOf(
    propTypes.shape({
      slug: propTypes.string.isRequired,
      excerpt: propTypes.string.isRequired,
      frontmatter: propTypes.shape({
        date: propTypes.string.isRequired,
        title: propTypes.string.isRequired,
        author: propTypes.string.isRequired,
        purposes: propTypes.arrayOf(propTypes.string).isRequired,
        featuredImageAlt: propTypes.string.isRequired,
        featuredImage: propTypes.shape({
          childImageSharp: propTypes.shape({
            gatsbyImageData: propTypes.object.isRequired,
          }).isRequired,
        }).isRequired,
      }).isRequired,
    })
  ).isRequired,
  family: propTypes.oneOf(['scroll', 'row']).isRequired,
  paginate: propTypes.bool,
  className: propTypes.string,
};

export const ArticleGridFragment = graphql`
  fragment ArticleGridFragment on MarkdownRemark {
    slug
    excerpt(pruneLength: 240)
    frontmatter {
      date(formatString: "MMMM Do, YYYY")
      title
      author
      purposes
      featuredImageAlt
      featuredImage {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`;

export default ArticleGrid;
