import { useEffect, useRef, useState } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const useBodyScroll = (element) => {
  const savedElement = useRef(element);
  const [scrollable, setScrollable] = useState(true);

  function disableScroll() {
    setScrollable(false);
    disableBodyScroll(savedElement.current);
  }

  function enableScroll() {
    setScrollable(true);
    enableBodyScroll(savedElement.current);
  }

  useEffect(
    () => () => {
      enableBodyScroll(savedElement.current);
    },
    []
  );

  useEffect(() => {
    savedElement.current = element;
  });

  return [scrollable, disableScroll, enableScroll];
};

export default useBodyScroll;
