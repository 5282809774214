import React from 'react';
import propTypes from 'prop-types';
import Animate from 'react-animate-on-scroll';

import { mapPurposeToColor } from 'src/utilities/helpers';

import * as S from './PurposeMenu.style';

const purposes = ['Well-being', 'Family', 'Business', 'Life'];
const purposesWithAll = ['All', ...purposes];

const PurposeMenu = ({ purpose, setPurpose, family, showAll, className }) => {
  const content = (showAll ? purposesWithAll : purposes).map((name) => (
    <S.Purpose
      key={name}
      onClick={() => setPurpose(name)}
      $active={purpose === name}
      $family={family}
      $color={mapPurposeToColor(name)}
    >
      {name}
    </S.Purpose>
  ));

  return (
    <S.PurposeMenu $family={family} className={className}>
      {family === 'link' && content}
      {family === 'button' && (
        <Animate animateOnce animateIn="slideInLeft">
          {content}
        </Animate>
      )}
    </S.PurposeMenu>
  );
};

PurposeMenu.defaultProps = {
  showAll: false,
  className: undefined,
};

PurposeMenu.propTypes = {
  purpose: propTypes.oneOf(['All', 'Well-being', 'Family', 'Business', 'Life'])
    .isRequired,
  setPurpose: propTypes.func.isRequired,
  family: propTypes.oneOf(['link', 'button']).isRequired,
  showAll: propTypes.bool,
  className: propTypes.string,
};

export default PurposeMenu;
