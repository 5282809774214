import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Animate from 'react-animate-on-scroll';

import * as S from './HomeHero.style';

const HomeHero = () => {
  const data = useStaticQuery(query);

  return (
    <S.HomeHero>
      <S.BackgroundImage
        alt="Sunset on a dock overlooking a lake and cloudy sky"
        image={data.file.childImageSharp.gatsbyImageData}
      />
      <S.Content>
        <Animate animateOnce duration={2} animateIn="fadeIn">
          <S.Headline>
            <S.Intro>We Are </S.Intro>
            <S.LogoWrapper>
              <S.Logo />
            </S.LogoWrapper>
          </S.Headline>
        </Animate>
        <Animate animateOnce duration={2} animateIn="fadeInUp">
          <S.Purpose>Awaken and Strengthen Your True Potential</S.Purpose>
          <S.Line />
          <S.Tagline>...it all Starts with You!</S.Tagline>
        </Animate>
      </S.Content>
    </S.HomeHero>
  );
};

const query = graphql`
  query {
    file(relativePath: { eq: "png/home-hero.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;

export default HomeHero;
