import React from 'react';
import propTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import defaultImage from 'src/assets/png/brand-image.png';

const defaultTitle = 'Absolute Training Centre | ...it all Starts with You!';

const defaultDescription =
  'Absolute is all about making a difference. Our mission is to elevate, equip and immerse individuals, groups, and businesses to build a solid foundation for their future.';

const SEO = ({ title, description, image }) => {
  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: image || defaultImage,
  };

  return (
    <Helmet title={seo.title}>
      {/* Default Metadata */}
      <meta name="image" content={seo.image} />
      <meta name="description" content={seo.description} />
      {/* OG Metadata */}
      <meta property="og:type" content="website" />
      <meta property="og:image" content={seo.image} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:site_name" content="Absolute Training Centre" />
      <meta property="og:description" content={seo.description} />
    </Helmet>
  );
};

SEO.defaultProps = {
  title: undefined,
  description: undefined,
  image: undefined,
};

SEO.propTypes = {
  title: propTypes.string,
  description: propTypes.string,
  image: propTypes.string,
};

export default SEO;
