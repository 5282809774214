import styled from 'styled-components';

export const PartnerDescription = styled.div`
  position: relative;
  padding: 120px 16px 0;
  max-width: 660px;
  margin: 0 auto 120px;

  @media(min-width: ${({ theme }) => theme.size.lg}) {
    max-width: 1068px;
  }
`;

export const Watermark = styled.span`
  display: none;

  @media(min-width: ${({ theme }) => theme.size.xl}) {
    display: inline;
    font-size: 175px;
    position: absolute;
    top: -40px;
    left: -152px;
    z-index: -1;
    font-weight: 800;
    color: ${({ theme }) => theme.bg.mediumGrey};
  }
`;

export const Title = styled.h1`
  font-size: 2.125rem;
  margin: 0 0 16px;
  text-align: center;

  @media(min-width: ${({ theme }) => theme.size.lg}) {
    font-size: 4rem;
    margin: 0 0 50px;
  }
`;

export const Wrapper = styled.div`
  @media(min-width: ${({ theme }) => theme.size.lg}) {
    display: flex;
  }
`;

export const Partners = styled.p`
  font-size: 1.5rem;
  text-align: center;
  margin: 0 0 24px;
  color: ${({ theme }) => theme.color.purple};
  font-weight: 600;
  font-style: italic;
  font-family: ${({ theme }) => theme.font.secondary};

  br {
    display: none;
  }

  @media(min-width: ${({ theme }) => theme.size.lg}) {
    font-size: 1.875rem;
    text-align: left;
    margin: 0 80px 0 0;

    span {
      display: none;
    }

    br {
      display: inline;
    }
  }
`;

export const TextWrapper = styled.div`

`;

export const Topic = styled.p`
  font-size: 1.25rem;
  font-weight: 600;
  font-style: italic;
  margin: 0 0 16px;

  @media(min-width: ${({ theme }) => theme.size.lg}) {
    font-size: 1.5rem;
  }
`;

export const Text = styled.p`
  font-size: 1.125rem;
  margin: 0;
`;
