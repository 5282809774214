import React from 'react';
import propTypes from 'prop-types';
import { graphql } from 'gatsby';

import SEO from 'src/components/SEO';

import PageHero from 'src/sections/PageHero';
import Back from 'src/sections/Back';
import Event from 'src/sections/Event';
import RelatedContent from 'src/sections/RelatedContent';

import * as S from './event.style';

const EventTemplate = ({ data, useAttributes }) => {
  useAttributes({ initiallyTransparent: false });

  return (
    <S.EventTemplate>
      <SEO
        title={data.event.frontmatter.title}
        description={data.event.excerpt}
      />
      <PageHero
        overlay
        title={data.event.frontmatter.title}
        alt={data.event.frontmatter.featuredImageAlt}
        image={data.event.frontmatter.featuredImage}
      />
      <Back type="event" />
      <Event event={data.event} />
      <RelatedContent events={data.relatedEvents.nodes} />
    </S.EventTemplate>
  );
};

export const query = graphql`
  query ($slug: String, $purposes: [String]) {
    event: markdownRemark(
      slug: { eq: $slug }
      frontmatter: { type: { eq: "event" } }
    ) {
      excerpt
      frontmatter {
        title
        featuredImageAlt
        featuredImage {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      ...EventFragment
    }
    relatedEvents: allMarkdownRemark(
      limit: 4
      sort: { fields: date, order: DESC }
      filter: {
        slug: { ne: $slug }
        frontmatter: { type: { eq: "event" }, purposes: { in: $purposes } }
      }
    ) {
      nodes {
        ...EventGridFragment
      }
    }
  }
`;

EventTemplate.defaultProps = {
  useAttributes: undefined,
};

EventTemplate.propTypes = {
  data: propTypes.shape({
    event: propTypes.shape({
      excerpt: propTypes.string.isRequired,
      frontmatter: propTypes.shape({
        title: propTypes.string.isRequired,
        featuredImage: propTypes.shape({
          childImageSharp: propTypes.shape({
            gatsbyImageData: propTypes.object.isRequired,
          }).isRequired,
        }).isRequired,
        featuredImageAlt: propTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    relatedEvents: propTypes.shape({
      nodes: propTypes.arrayOf(propTypes.object).isRequired,
    }).isRequired,
  }).isRequired,
  useAttributes: propTypes.func,
};

export default EventTemplate;
