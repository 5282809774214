import styled, { css } from 'styled-components';
import { GatsbyImage as COMP1 } from 'gatsby-plugin-image';

export const Speaking = styled.div`
  position: relative;
  padding: 0 16px;
  max-width: 660px;
  margin: 0 auto 80px;

  @media(min-width: ${({ theme }) => theme.size.lg}) {
    margin: 80px auto 120px;
  }

  @media(min-width: ${({ theme }) => theme.size.xl}) {
    padding: 88px 16px;
  }
`;

export const Topic = styled.p`
  font-size: 1.375rem;
  font-weight: 600;
  margin: 0 0 20px;

  @media(min-width: ${({ theme }) => theme.size.lg}) {
    font-size: 1.625rem;
    text-align: center;
  }
`;

export const Text = styled.p`
  font-size: 1.125rem;
  margin: 0 0 32px;
  line-height: 1.6;
  text-align: center;

  @media(min-width: 1320px) {
    margin: 0;
  }
`;

export const Tagline = styled.span`
  display: block;
  font-size: 1.5rem;
  margin: 30px 0;
  text-align: center;
  color: ${({ theme }) => theme.color.purple};
  font-weight: 600;
  font-style: italic;
  font-family: ${({ theme }) => theme.font.secondary};
`;

export const Image = styled(COMP1)`
  display: block;
  border-radius: 8px;
  max-width: 400px;
  margin: 0 auto;

  ${({ $side }) => $side === 'right' && css`
    display: none;
  `}

  @media(min-width: 1320px) {
    display: block;
    position: absolute;
    max-width: 280px;

    ${({ $side }) => $side === 'left' && css`
      left: -320px;
      bottom: 88px;
    `}

    ${({ $side }) => $side === 'right' && css`
        top: 88px;
        right: -320px;
    `}
  }
`;
