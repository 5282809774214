import styled from 'styled-components';
import { Link as COMP1 } from 'gatsby';

import SVG1 from 'src/assets/svg/logo-expanded-purple.svg';

export const GeneralInquiriesPage = styled.div`
  max-width: 1200px;
  padding: 112px 16px 0;
  margin: 0 auto 120px;

  @media(min-width: ${({ theme }) => theme.size.md}) {
    padding: 192px 16px 0;
  }
`;

export const Watermark = styled.span`
  display: none;

  @media(min-width: ${({ theme }) => theme.size.xl}) {
    display: inline;
    font-size: 175px;
    position: absolute;
    top: 40px;
    left: 120px;
    z-index: -1;
    font-weight: 800;
    color: ${({ theme }) => theme.bg.mediumGrey};
  }
`;

export const Title = styled.h1`
  margin: 0 0 8px;
  font-size: 2.125rem;
  text-align: center;

  @media(min-width: ${({ theme }) => theme.size.md}) {
    font-size: 5rem;
  }
`;

export const Text = styled.p`
  font-size: 1.25rem;
  text-align: center;
  margin: 0 auto 40px;
  max-width: 540px;
  color: ${({ theme }) => theme.color.purple};
  font-weight: 600;
  font-style: italic;
  font-family: ${({ theme }) => theme.font.secondary};

  @media(min-width: ${({ theme }) => theme.size.md}) {
    font-size: 1.5rem;
    margin: 0 auto 64px;
  }
`;


export const Wrapper = styled.div`
  @media(min-width: ${({ theme }) => theme.size.md}) {
    display: grid;
    grid-column-gap: 120px;
    grid-template-columns: 328px 1fr;
  }
`;

export const FormWrapper = styled.div`
  margin-bottom: 54px;

  @media(min-width: ${({ theme }) => theme.size.md}) {
    order: 1;
  }
`;
export const Info = styled.div`

`;

export const InfoItem = styled.div`
  margin: 0 0 32px;
`;

export const InfoItemTitle = styled.h5`
  font-size: 1.5rem;
  margin: 0 0 16px;
`;

export const InfoItemLink = styled.a`
  font-size: 1.125rem;
  text-decoration: none;
  color: ${({ theme }) => theme.color.purple};
`;

export const Line = styled.hr`
  width: 60%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid ${({ theme }) => theme.bg.mediumGrey};
  margin: 0 auto 16px 0;
`;

export const LogoLink = styled(COMP1)`
  display: block;
  margin: 0 0 20px;
  width: fit-content;
`;

export const Logo = styled(SVG1)`
  width: auto;
  height: 68px;
  display: block;
`;
