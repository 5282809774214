import React, { useContext } from 'react';
import propTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { getShopifyImage } from 'gatsby-source-shopify';

import ShopifyContext from 'src/contexts/ShopifyContext';

import Quantity from 'src/components/Quantity';

import * as S from './LineItemGrid.style';

const LineItemGrid = ({ className }) => {
  const data = useStaticQuery(query);
  const { checkout, updateLineItem, removeLineItem } =
    useContext(ShopifyContext);

  return (
    <S.LineItemGrid className={className}>
      <S.Wrapper>
        {checkout &&
          checkout.lineItems.edges.map(({ node: lineItem }) => (
            <S.Card key={lineItem.id}>
              <S.Image
                alt={
                  lineItem.variant.image
                    ? lineItem.variant.image.altText || ''
                    : 'Image Coming Soon'
                }
                image={
                  lineItem.variant.image
                    ? getShopifyImage({
                        image: lineItem.variant.image,
                        layout: 'constrained',
                        width: 800,
                        height: 800,
                      })
                    : data.file.childImageSharp.gatsbyImageData
                }
              />
              <S.Content>
                <S.ProductTitle>{lineItem.title}</S.ProductTitle>
                <S.Price>
                  ${parseFloat(lineItem.variant.priceV2.amount).toFixed(2)}
                  {lineItem.variant.compareAtPriceV2 && (
                    <S.CompareAtPrice>
                      $
                      {parseFloat(
                        lineItem.variant.compareAtPriceV2.amount
                      ).toFixed(2)}
                    </S.CompareAtPrice>
                  )}
                </S.Price>
                {lineItem.variant.title !== 'Default Title' && (
                  <S.VariantTitle>{lineItem.variant.title}</S.VariantTitle>
                )}
              </S.Content>
              <S.Actions>
                <Quantity
                  value={lineItem.quantity}
                  setValue={(quantity) =>
                    updateLineItem({ id: lineItem.id, quantity })
                  }
                />
                <S.Remove onClick={() => removeLineItem(lineItem.id)}>
                  Remove
                </S.Remove>
              </S.Actions>
            </S.Card>
          ))}
      </S.Wrapper>
    </S.LineItemGrid>
  );
};

LineItemGrid.defaultProps = {
  className: undefined,
};

LineItemGrid.propTypes = {
  className: propTypes.string,
};

const query = graphql`
  query {
    file(relativePath: { eq: "png/coming-soon.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;

export default LineItemGrid;
