/**
 * windowWidth - Returns the width of the browser window
 *
 * @return {number}  Browser Width
 */
export function windowWidth() {
  if (typeof window !== 'undefined') {
    return Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );
  }
  return null;
}

/**
 * mapPurposeToColor - Returns a color based on the purpose string
 *
 * @param  {String} purpose One of the four purposes or 'All'
 * @return {String}         The color that represents the purpose
 */
export function mapPurposeToColor(purpose) {
  switch (purpose) {
    case 'All':
      return 'purple';

    case 'Well-being':
      return 'magenta';

    case 'Family':
      return 'yellow';

    case 'Business':
      return 'blue';

    case 'Life':
      return 'green';

    default:
      return undefined;
  }
}

/**
 * getImageByName - Get a gatsby image according to its file name
 *
 * @param  {Array}  files An array of gatsby files
 * @param  {String} name  Name of the image to return
 * @return {Object}       The found image
 */
export function getImageByName(files, name) {
  for (const file of files) {
    if (file.name === name) return file.childImageSharp.gatsbyImageData;
  }
  return null;
}

/**
 * reportInputValidity - Determines if each of the inputs in a form is valid
 *
 * @param  {Object}  form The form to check validity of
 * @return {Boolean}      Whether the inputs are valid or not
 */
export function reportInputValidity(form) {
  for (const input of form.elements) {
    if (!input.reportValidity()) {
      return false;
    }
  }

  return true;
}
