import styled, { css } from 'styled-components';

export const PurposeMenu = styled.div`
  display: flex;
  height: 54px;
  position: relative;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.bg.lightGrey};
  border-top: 2px solid ${({ theme }) => theme.bg.mediumGrey};

  @media(max-width: 400px) {
    overflow-x: hidden;
  }


  ${({ $family }) => $family === 'link' ? css`

  ` : css`
    @media(min-width: ${({ theme }) => theme.size.lg}) {
      border-top: none;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
    }
  `}
`;

export const Purpose = styled.button`
  background: none;
  white-space: nowrap;
  cursor: pointer;
  border-top: none;
  border-left: none;
  border-right: none;
  font-weight: ${({ $active }) => $active ? '600' : '400'};
  transition: border-bottom 0.25s, padding 0.25s, color 0.25s;
  padding: ${({ $active }) => $active ? '16px 16px 12px' : '16px'};
  border-bottom: ${({ $active, $color, theme }) => $active ? `4px solid ${theme.color[$color]}` : 'none'};
  pointer-events: ${({ $active }) => $active ? 'none' : 'all'};

  :hover {
    opacity: 0.75;
    color: ${({ $color, theme }) => theme.color[$color]};
  }

  :active {
    opacity: 0.5;
    color: ${({ theme }) => theme.text.active.base};
  }

  ${({ $family }) => $family === 'link' ? css`

  ` : css`
    @media(min-width: ${({ theme }) => theme.size.lg}) {
      width: 100%;
      text-align: left;
      font-size: 1.5rem;
      border-radius: 8px;
      padding: 10px 16px;
      margin-bottom: 12px;
      transition: color 0.25s, background 0.25s;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
      border: 2px solid ${({ $color, theme }) => theme.color[$color]};
      background: ${({ $active, $color, theme }) => $active ? theme.hue[$color] : theme.bg.base};
    }
  `}
`;
