import React from 'react';
import propTypes from 'prop-types';
import Animate from 'react-animate-on-scroll';

import * as S from './PageHero.style';

const PageHero = ({ alt, image, title, overlay, wide, children }) => (
  <S.PageHero>
    <S.Image alt={alt} image={image.childImageSharp.gatsbyImageData} />
    {overlay && <S.Overlay />}
    <Animate animateOnce animateIn="fadeIn">
      <S.Content $wide={wide}>
        {title && <S.Title>{title}</S.Title>}
        {children}
      </S.Content>
    </Animate>
  </S.PageHero>
);

PageHero.defaultProps = {
  title: undefined,
  overlay: false,
  wide: false,
  children: undefined,
};

PageHero.propTypes = {
  alt: propTypes.string.isRequired,
  image: propTypes.shape({
    childImageSharp: propTypes.shape({
      gatsbyImageData: propTypes.object.isRequired,
    }).isRequired,
  }).isRequired,
  title: propTypes.node,
  overlay: propTypes.bool,
  wide: propTypes.bool,
  children: propTypes.node,
};

export default PageHero;
