import React from 'react';
import propTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { useQueryParam, withDefault, NumberParam } from 'use-query-params';

import Quote from 'src/components/Quote';

import * as S from './ProductGrid.style';

const productsPerPage = 6;

const ProductGrid = ({ products, paginate, className }) => {
  const data = useStaticQuery(query);
  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 1));

  return (
    <S.ProductGrid className={className}>
      {products.length === 0 && (
        <S.NoneFound>
          It looks like we haven&apos;t created any products yet. Maybe
          it&apos;s time to contact us about it.
        </S.NoneFound>
      )}
      <S.Wrapper>
        {(paginate ? products.slice(0, page * productsPerPage) : products).map(
          (product, i) => (
            <React.Fragment key={product.handle}>
              <S.Card $reverse={i % 2 !== 0} to={`/product/${product.handle}`}>
                <S.ImageWrapper>
                  {product.variants[0].inventoryPolicy !== 'CONTINUE' &&
                    product.totalInventory === 0 && (
                      <S.OutOfStock>
                        <S.Indicator>Out of Stock</S.Indicator>
                      </S.OutOfStock>
                    )}
                  <S.Image
                    alt={
                      product.featuredImage
                        ? product.featuredImage.altText || ''
                        : 'Image Coming Soon'
                    }
                    image={
                      product.featuredImage
                        ? product.featuredImage.gatsbyImageData
                        : data.file.childImageSharp.gatsbyImageData
                    }
                  />
                </S.ImageWrapper>
                <S.Content>
                  <S.Meta>
                    <S.Title>{product.title}</S.Title>
                    <S.Price>
                      ${product.variants[0].price}
                      {product.variants[0].compareAtPrice && (
                        <S.CompareAtPrice>
                          ${product.variants[0].compareAtPrice}
                        </S.CompareAtPrice>
                      )}
                    </S.Price>
                    <S.Excerpt>{product.excerpt}</S.Excerpt>
                    <S.Tag type="type">{product.productType}</S.Tag>
                    {product.tags.map((tag) => (
                      <S.Tag key={tag} type="tag">
                        {tag}
                      </S.Tag>
                    ))}
                  </S.Meta>
                  <S.Link>View Product</S.Link>
                </S.Content>
              </S.Card>
              {i === 0 && (
                <Quote size="small">
                  Our store provides tools for you to Prepare for Success
                  Everyday!
                </Quote>
              )}
            </React.Fragment>
          )
        )}
      </S.Wrapper>
      {paginate && products.length > page * productsPerPage && (
        <S.LoadMore outline onClick={() => setPage(page + 1)}>
          Load More Articles
        </S.LoadMore>
      )}
    </S.ProductGrid>
  );
};

ProductGrid.defaultProps = {
  paginate: false,
  className: undefined,
};

ProductGrid.propTypes = {
  products: propTypes.arrayOf(
    propTypes.shape({
      title: propTypes.string.isRequired,
      handle: propTypes.string.isRequired,
      excerpt: propTypes.string.isRequired,
      totalInventory: propTypes.number.isRequired,
      productType: propTypes.string.isRequired,
      tags: propTypes.arrayOf(propTypes.string).isRequired,
      featuredImage: propTypes.shape({
        altText: propTypes.string,
        gatsbyImageData: propTypes.object.isRequired,
      }),
      variants: propTypes.arrayOf(
        propTypes.shape({
          price: propTypes.number.isRequired,
          compareAtPrice: propTypes.number,
        })
      ).isRequired,
    })
  ).isRequired,
  paginate: propTypes.bool,
  className: propTypes.string,
};

const query = graphql`
  query {
    file(relativePath: { eq: "png/coming-soon.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;

export const ProductGridFragment = graphql`
  fragment ProductGridFragment on ShopifyProduct {
    title
    handle
    excerpt(pruneLength: 300)
    totalInventory
    productType
    tags
    featuredImage {
      gatsbyImageData
      altText
    }
    variants {
      compareAtPrice
      price
    }
  }
`;

export default ProductGrid;
