import styled from 'styled-components';
import COMP1 from 'react-animate-on-scroll';

export const CoreValues = styled.div`
  position: relative;
  padding: 32px 16px 0;
  margin: 56px auto 48px;

  @media(min-width: ${({ theme }) => theme.size.md}) {
    max-width: 768px;
  }

  @media(min-width: ${({ theme }) => theme.size.lg}) {
    max-width: 1200px;
    margin: 66px auto 120px;
    padding: 100px 16px 0;
  }
`;

export const Square = styled.div`
  position: absolute;
  margin: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  height: 154px;
  border-radius: 12px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background: ${({ theme }) => theme.custom.purple};

  @media(min-width: ${({ theme }) => theme.size.md}) {
    height: 268px;
    max-width: 1168px;
    margin: 0 auto;
  }
`;



export const Watermark = styled.span`
  display: none;

  @media(min-width: ${({ theme }) => theme.size.xl}) {
    display: inline;
    font-size: 160px;
    position: absolute;
    top: -40px;
    right: -20px;
    z-index: -1;
    font-weight: 800;
    opacity: 0.6;
    color: ${({ theme }) => theme.bg.mediumGrey};
  }
`;

export const Title = styled.h1`
  font-size: 2.125rem;
  text-align: center;
  margin: 0 0 28px;
  color: ${({ theme }) => theme.text.white};

  @media(min-width: ${({ theme }) => theme.size.md}) {
    font-size: 4rem;
    margin: 0 0 40px;
  }
`;

export const Values = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto auto;
  grid-gap: 16px;

  @media(min-width: ${({ theme }) => theme.size.md}) {
    grid-template-columns: repeat(2, min(100%, 420px));
    grid-template-rows: auto auto;
    justify-content: center;
  }
`;

export const Value = styled(COMP1)`
  padding: 24px 16px 32px;
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background: ${({ theme }) => theme.bg.base};
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 24px;

  svg {
    width: 40px;
    height: 40px;
    margin-right: 16px;
  }
`;

export const Name = styled.h5`
  font-size: 1.25rem;
  margin: 0;
`;

export const Text = styled.p`
  font-size: 0.875rem;
  text-align: center;
  margin: 0;
`;
