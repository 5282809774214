import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'gatsby';

import SEO from 'src/components/SEO';

import * as S from './legal.style';

const LegalPage = ({ useAttributes }) => {
  useAttributes({ initiallyTransparent: false });

  return (
    <S.LegalPage>
      <SEO title="Legal | Absolute Training Centre" />
      <S.Text>
        <h3>Consent policy</h3>
        <p>
          <b>Newsletters and Other Communications from Us.</b> Our registered
          users and customers may receive newsletters, email communications, or
          communications through other channels for, among other purposes, the
          promotion and marketing of a product or service from our family of
          sites and business properties, and business partners, and we may also
          offer other visitors to our sites the opportunity to sign up to
          receive such information. When there is a separate sign-up for
          newsletters, we ask for contact information such as name and email
          address, and, sometimes, demographic and profile information. By
          visiting our sites, you affirmatively consent to our collection and
          use of your data. We use this information in the same manner as we use
          the contact information in the Registration process described above.
          We may offer our users the choice whether to receive mailings from
          others that we think will be of interest. Recipients of our mailings
          can unsubscribe by following instructions that may appear at the end
          of email communications. Note that in order to keep our registered
          users informed about the operation of our services, we may send emails
          and announcements that are needed for the proper functioning and
          administration of our sites and service.
        </p>
        <p>
          <b>Communications with Us.</b> We have features where you can submit
          information to us (such as when contacting us with a question or
          feedback). We may retain your emails and other information you submit
          to us for our internal purposes, and to help us to serve you better.
        </p>
        <h3>Sales</h3>
        <ul>
          <li>
            In addition we may offer for sale teaching resources and other items
            which may contain words such as (“Resources”).
          </li>
          <ul>
            <li>
              Examples of Resources may include books, mugs, DVDs, CDs, USBs,
              and online training courses and all live stream events.
            </li>
          </ul>
          <li>
            After ordering Resources online from our store, you will receive an
            email confirmation containing your order details (if you have
            provided your email address). We will normally confirm receipt of
            your order within a few minutes of your order.
          </li>
          <ul>
            <li>
              We will attempt to send physical Resources via postal service or
              courier in a timely manner; however, if goods are unavailable,
              delivery will take a little longer. Delivery times may be affected
              by event schedules.
            </li>
            <li>
              We will attempt to provide electronic Resources within two working
              days.
            </li>
          </ul>
          <li>
            Emails are often collected by spam filters. It is up to you to
            ensure that spam filters are adequately set up to allow delivery of
            our emails. In some circumstances, web hosts also filter emails and
            may prevent email transmission of invoices and purchase-related
            data. This is outside of our control.
          </li>
          <li>
            If you purchase a Resource from us, we may request certain personal
            information, such as contact information (eg name, email, phone
            number and postal address) and financial information (eg credit card
            number, expiration date). Credit card information is not stored by
            us and is handled directly by our payment gateways (i.e, Stripe,
            PayPal).
          </li>
          <li>
            If you wish to query a delivery please contact us at:{' '}
            <Link to="/contact/general-inquiries">
              absolutetrainingcentre.com
            </Link>{' '}
            We will always try to respond as soon as possible to your inquiry.
          </li>
        </ul>
        <h3>Intellectual Property</h3>
        <ul>
          <li>
            We often produce videos using Facebook Live and Youtube, our own
            recording equipment, other platforms, or third-party recording
            equipment. While we often broadcast these videos publicly, we
            maintain our claim of intellectual property in those recordings. If
            you are interested in reproducing or re-broadcasting any recordings
            produced by us, (for example on another YouTube channel), please
            secure written permission first. We always can be contacted directly
            on our contact page at:{' '}
            <Link to="/contact/general-inquiries">
              absolutetrainingcentre.com
            </Link>
          </li>
        </ul>
        <ul>
          <li>
            When you participate in our recorded events, whether in person or
            through electronic media such as Facebook Live, Youtube, Zoom or any
            other you consent to our recording of your participation and or use
            of the recording of you, without compensation of any kind to you.
          </li>
          <li>
            Unless you request otherwise in writing, in advance of our usage as
            described in this clause, you consent to our reproduction of any of
            your posts or emails, in whole or in part, in the course of our
            provision of information or Resources, and for that reproduction to
            become part of our intellectual property.
          </li>
          <li>
            Registered and unregistered trademarks, logos, service marks and
            trade names (collectively the “Trademarks”) owned by us may not be
            used in any manner without our prior written consent.
          </li>
          <li>
            Where we use a Trademark with the permission of the Trademark’s
            owner, we cannot and do not grant, explicitly or by implication, any
            license or right to any third party to use that Trademark. Any use
            of such Trademarks is strictly prohibited.
          </li>
          <li>
            We will enforce our Trademark rights to the fullest extent of the
            law.
          </li>
          <li>
            Possible Infringement by us. If you are the owner of intellectual
            property and you believe we have infringed rights in that
            intellectual property, or the intellectual property of a third
            party, please notify us at:{' '}
            <Link to="/contact/general-inquiries">
              absolutetrainingcentre.com
            </Link>
          </li>
        </ul>
        All notices should include:
        <ul>
          <li>
            identification of the material that you claim infringes intellectual
            property rights, with enough detail so that we may locate that
            material;
          </li>
          <li>
            information describing how you believe that your intellectual
            property rights were infringed; and
          </li>
          <li>
            your name, address, telephone number, email address, and your
            physical or electronic signature.
          </li>
        </ul>
        Although we endeavour to respond to emails in a timely manner, it may
        take a few days to investigate your complaint and respond. We will
        endeavour to correct any errors promptly once we are made aware of them.
        <h3>Social Media</h3>
        <ul>
          <li>
            We use social media as an integral part of communication for our
            business. Our social media spaces are public spaces with limited
            privacy. Harassment and inappropriate posts are not welcome and may
            be subject to moderation.
          </li>
          <li>
            In our social media spaces, any personal information that is
            disclosed is publicly available, and any statements are also public.
            By posting anything to our social media accounts, you consent to its
            public display and our use of the content you post.
          </li>
          <li>
            If we use testimony from social media, we will attempt to maintain
            anonymity, unless you consent to the release of your name. However,
            we cannot guarantee that your identity may not be discernible as a
            result of the facts you post.
          </li>
          <li>
            In participating and commenting in a Facebook live event or Youtube
            event, Zoom or any other you are gathering in a public place where
            you agree to the broadcast of your information. Sometimes during
            live sessions, personal names can be called out to edify or identify
            you personally and that presentation becomes an integral part of the
            Facebook Live video. While the video remains on Facebook in its
            original format, we also save and upload these videos on YouTube and
            our website. If you are named, or your personal identity is given
            and you wish to remain anonymous in further reproductions, please
            notify us directly at:{' '}
            <Link to="/contact/general-inquiries">
              absolutetrainingcentre.com
            </Link>
          </li>
        </ul>
        <ul>
          <li>
            Preferably within reasonable time of the end of the broadcast, so
            the relevant footage can be removed from the finished product.
          </li>
          <li>
            We encourage discourse on social media, but we will moderate
            comments we deem threatening to our staff, partners or the
            community. Our moderator will determine acceptable limits of
            discussion. Online harassment, particularly personal attacks on
            employees or staff, partners directly involved with Absolute
            Training Centre or any statement which is defamatory, is not
            acceptable.{' '}
          </li>
          <li>
            Screenshots of harassing comments may be archived, comments may be
            deleted, and accounts may be blocked. In severe circumstances,
            authorities will be contacted.
          </li>
          <li>
            Social media sites (Facebook, YouTube, Instagram, etc) collect data
            and analytics according to their relevant privacy policies. For
            information on their policies, please view the links bellow.
          </li>
          <ul>
            <li>
              <u>
                <Link href="https://www.facebook.com/policy.php">
                  https://www.facebook.com/policy.php
                </Link>
              </u>
            </li>
            <li>
              <u>
                <Link href="https://www.youtube.com/yt/about/policies/#community-guidelines">
                  https://www.youtube.com/yt/about/policies/#community-guidelines
                </Link>
              </u>
            </li>
            <li>
              <u>
                <Link href="https://help.instagram.com/519522125107875">
                  https://help.instagram.com/519522125107875
                </Link>
              </u>
            </li>
          </ul>
        </ul>
      </S.Text>
    </S.LegalPage>
  );
};

LegalPage.defaultProps = {
  useAttributes: undefined,
};

LegalPage.propTypes = {
  useAttributes: propTypes.func,
};

export default LegalPage;
