let storedLocation;

/**
 * Determines whether or not to preserve scroll position on route change
 */
export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  const { state } = location;

  // Adds the previous route information to the location object which makes it
  // accessible across the application using reach routers useLocation hook
  // eslint-disable-next-line no-param-reassign
  location.previous = storedLocation;
  storedLocation = location;

  // Only if the pathname has changed should the page scroll to top. Param and hash
  // changes should keep their scroll position
  const scrollToTop = location.pathname !== location?.previous?.pathname;

  if (scrollToTop && (!state || state.updateScroll !== false)) {
    return true;
  }

  return false;
};
