import React from 'react';
import propTypes from 'prop-types';

import * as S from './Textarea.style';

const Textarea = ({
  label,
  name,
  placeholder,
  value,
  onEnter,
  onChange,
  id,
  required,
  className,
}) => {
  function handleKeyUp(event) {
    // If the enter key is pressed act like the button was clicked
    if (event.keyCode === 13) {
      event.preventDefault();
      event.target.blur();
      onEnter(event.target.value);
    }
  }

  return (
    <S.Textarea $name={name} className={className}>
      {label && <S.Label htmlFor={id}>{label}</S.Label>}
      <S.Input
        name={name}
        required={required}
        id={id}
        rows={4}
        placeholder={placeholder}
        value={value}
        onKeyUp={onEnter ? handleKeyUp : undefined}
        onChange={({ target: { value: newValue } }) => onChange(newValue)}
      />
    </S.Textarea>
  );
};

Textarea.defaultProps = {
  label: undefined,
  name: undefined,
  placeholder: undefined,
  onEnter: undefined,
  required: false,
  className: undefined,
};

Textarea.propTypes = {
  label: propTypes.string,
  name: propTypes.string,
  placeholder: propTypes.string,
  value: propTypes.string.isRequired,
  onEnter: propTypes.func,
  onChange: propTypes.func.isRequired,
  id: propTypes.string.isRequired,
  required: propTypes.bool,
  className: propTypes.string,
};

export default Textarea;
