import styled from 'styled-components';

import COMP1 from 'src/components/Form';
import COMP2 from 'src/components/Button';

export const NetlifyForm = styled(COMP1)`
  
`;

export const Button = styled(COMP2)`
  width: 100%;

  @media(min-width: ${({ theme }) => theme.size.md}) {
    max-width: 320px;
  }
`;
