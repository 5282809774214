import styled, { css } from 'styled-components';

import { CheckCircle as SVG1, Error as SVG2, Close as SVG3 } from '@styled-icons/material-rounded';

export const Alert = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  min-width: 240px;
  width: fit-content;
  max-width: 600px;
  background: white;
  margin: 16px 16px 0;
  border-radius: 4px;
  z-index: 10;

  @media(min-width: 632px) {
    margin: 16px auto 0;
  }


  ${({ $status }) => $status === 'success' && css`
    background: ${({ theme }) => theme.status.hue.success};
    border: 2px solid ${({ theme }) => theme.status.color.success};
  `}

  ${({ $status }) => $status === 'error' && css`
  background: ${({ theme }) => theme.status.hue.error};
  border: 2px solid ${({ theme }) => theme.status.color.error};
  `}
`;

export const Success = styled(SVG1)`
  width: 36px;
  height: 36px;
  padding: 6px;
  margin: 8px;
  border-radius: 4px;
  fill: ${({ theme }) => theme.bg.base};
  background: ${({ theme }) => theme.status.color.success};
  flex-shrink: 0;
`;

export const Error = styled(SVG2)`
  width: 36px;
  height: 36px;
  padding: 6px;
  margin: 8px;
  border-radius: 4px;
  fill: ${({ theme }) => theme.bg.base};
  background: ${({ theme }) => theme.status.color.error};
  flex-shrink: 0;
`;

export const Message = styled.p`
  margin: 16px 8px;
  flex-grow: 1;
  padding: 0;
  line-height: 24px;
  text-align: center;
`;

export const Button = styled.button`
  margin: 8px;
  border: none;
  background: none;
  cursor: pointer;
  border-radius: 4px;
  padding: 0;
  flex-shrink: 0;
`;

export const Close = styled(SVG3)`
  width: 34px;
  height: 34px;
  padding: 5px;
  fill: ${({ theme }) => theme.text.light};
  transition: fill 0.25s;

  :hover {
    fill: ${({ theme }) => theme.text.base};
  }
`;
