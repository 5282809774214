import React, { useContext } from 'react';

import ShopifyContext from 'src/contexts/ShopifyContext';

import Spinner from 'src/components/Spinner';
import LineItemGrid from 'src/components/LineItemGrid';

import * as S from './Cart.style';

const Cart = () => {
  const { loading, checkout } = useContext(ShopifyContext);

  return (
    <S.Cart>
      {loading && <Spinner />}
      {!loading && (!checkout || checkout.lineItems.edges.length === 0) && (
        <S.NoneFound>
          It looks like you haven&apos;t added any items to cart yet. Head back
          to the shop page!
        </S.NoneFound>
      )}
      {checkout && checkout.lineItems.edges.length > 0 && (
        <S.Wrapper>
          <LineItemGrid />
          <S.Information>
            <S.Data>
              Subtotal:{' '}
              <span>
                ${parseFloat(checkout.subtotalPriceV2.amount).toFixed(2)}{' '}
                {checkout.subtotalPriceV2.currencyCode}
              </span>
            </S.Data>
            <S.Data>
              Total:{' '}
              <span>
                ${parseFloat(checkout.totalPriceV2.amount).toFixed(2)}{' '}
                {checkout.totalPriceV2.currencyCode}
              </span>
            </S.Data>
            <S.Data>
              Delivery: <span>Calculated at checkout</span>
            </S.Data>
            <S.Button href={checkout.webUrl}>Checkout</S.Button>
            <S.DiscountText>
              <b>Have a discount code?</b> Apply it during checkout
            </S.DiscountText>
          </S.Information>
        </S.Wrapper>
      )}
    </S.Cart>
  );
};
export default Cart;
