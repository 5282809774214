import styled from 'styled-components';
import { GatsbyImage as COMP1 } from 'gatsby-plugin-image';

import SVG1 from 'src/assets/svg/logo-expanded-white.svg';

export const HomeHero = styled.div`
  position: relative;
  padding: 56px 0 0;
  margin: 0 0 24px;

  @media(min-width: ${({ theme }) => theme.size.md}) {
    padding: 80px 0 0;
  }
`;

export const BackgroundImage = styled(COMP1)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
`;

export const Content = styled.div`
  padding: 64px 16px;
  max-width: 768px;
  margin: 0 auto;
`;

export const Headline = styled.h1`
  display: flex;
  justify-content: center;
  font-weight: 400;
  margin: 0 0 12px;
  color: ${({ theme }) => theme.text.white};
`;

export const Intro = styled.span`
  font-size: 2.125rem;
  letter-spacing: 0.04em;
  font-weight: 600;
  font-style: italic;
  color: ${({ theme }) => theme.text.white};

  @media(min-width: ${({ theme }) => theme.size.md}) {
    font-size: 4rem;
  }
`;

export const LogoWrapper = styled.span`
  display: inline-block;
  height: 100%;
  margin-left: -24px;
  margin-top: 10px;
  text-align: bottom;

  @media(min-width: ${({ theme }) => theme.size.md}) {
    margin-left: -48px;
    margin-right: -48px;
    margin-top: 2px;
  }
`;

export const Logo = styled(SVG1)`
  display: inline-block;
  width: 200px;
  height: auto;

  @media(min-width: ${({ theme }) => theme.size.md}) {
    width: 480px;
  }
`;

export const Purpose = styled.p`
  font-size: 1.5rem;
  text-align: center;
  margin: 0 0 24px;
  font-style: normal;
  font-family: ${({ theme }) => theme.font.secondary};
  color: ${({ theme }) => theme.text.white};
  font-weight: 600;

  @media(min-width: ${({ theme }) => theme.size.md}) {
    font-size: 2.125rem;
  }
`;

export const Line = styled.hr`
  margin: 0 0 24px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid ${({ theme }) => theme.text.white};
`;

export const Tagline = styled.p`
  font-size: 1.25rem;
  text-align: center;
  color: ${({ theme }) => theme.text.white};
  font-weight: 600;
  font-style: italic;
  font-family: ${({ theme }) => theme.font.secondary};

  @media(min-width: ${({ theme }) => theme.size.md}) {
    font-size: 1.75rem;
  }
`;
