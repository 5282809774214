import { gql } from 'graphql-request';

export default gql`
  fragment CheckoutLineItemFragment on CheckoutLineItem {
    id
    title
    quantity
    variant {
      ...VariantFragment
    }
  }
`;
