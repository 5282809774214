import styled from 'styled-components';

export const RecentArticles = styled.div`
  padding: 0;
  margin: 0 0 72px;
  max-width: 1200px;
  margin: 0 auto 72px;

  @media(min-width: ${({ theme }) => theme.size.md}) {
    margin: 0 auto 140px;
  }
`;

export const Title = styled.h3`
  font-size: 2rem;
  padding: 0 16px;
  margin: 0 0 6px;
  font-weight: 600;
  font-style: italic;
  font-family: ${({ theme }) => theme.font.secondary};

  @media(min-width: ${({ theme }) => theme.size.md}) {
    font-size: 3.25rem;
  }
`;

export const Text = styled.p`
  font-size: 1rem;
  padding: 0 16px;
  max-width: 760px;
  margin: 0 0 16px;

  @media(min-width: ${({ theme }) => theme.size.md}) {
    font-size: 1.125rem;
  }
`;
