import addLineItem from './functions/addLineItem';
import fetchCheckout from './functions/fetchCheckout';
import removeLineItem from './functions/removeLineItem';
import updateLineItem from './functions/updateLineItem';

import checkoutCreate from './mutations/checkoutCreate';
import checkoutLineItemsAdd from './mutations/checkoutLineItemsAdd';
import checkoutLineItemsRemove from './mutations/checkoutLineItemsRemove';
import checkoutLineItemsUpdate from './mutations/checkoutLineItemsUpdate';

import checkout from './queries/checkout';

export default function shopify(client, state, setState) {
  const augmentedFunctions = {};

  const helpers = {
    client,
    state,
    setState,
    mutations: {
      checkoutCreate,
      checkoutLineItemsAdd,
      checkoutLineItemsRemove,
      checkoutLineItemsUpdate,
    },
    queries: {
      checkout,
    },
  };

  const functions = {
    addLineItem,
    fetchCheckout,
    removeLineItem,
    updateLineItem,
  };

  for (const key of Object.keys(functions)) {
    augmentedFunctions[key] = (...params) => functions[key](helpers, ...params);
  }

  return augmentedFunctions;
}
