import React, { useState } from 'react';
import propTypes from 'prop-types';
import { graphql, navigate } from 'gatsby';

import SEO from 'src/components/SEO';
import EventGrid from 'src/components/EventGrid';
import PurposeMenu from 'src/components/PurposeMenu';

import PageHero from 'src/sections/PageHero';

import * as S from './events.style';

function groupEventsByStatus(events) {
  const result = {
    upcoming: [],
    ongoing: [],
    previous: [],
  };

  for (const event of events) {
    const startDate = new Date(event.frontmatter.dates[0].date);
    const endDate = new Date(
      event.frontmatter.dates[event.frontmatter.dates.length - 1].date
    );
    const currentDate = new Date().setUTCHours(0, 0, 0, 0); // Remove the time from the date for accurate comparison

    if (startDate > currentDate) {
      result.upcoming.push(event);
    } else if (startDate < currentDate && endDate > currentDate) {
      result.ongoing.push(event);
    } else if (startDate < currentDate && endDate < currentDate) {
      result.previous.unshift(event);
    } else {
      // eslint-disable-next-line no-console
      console.error(
        `Could not properly group event with title: ${event.title}`
      );
    }
  }

  return result;
}

const EventsTemplate = ({ data, useAttributes }) => {
  const [open, setOpen] = useState(false);
  useAttributes({ initiallyTransparent: true });

  const groups = groupEventsByStatus(
    data?.allEvents?.nodes || data?.filteredEvents?.nodes
  );

  return (
    <S.EventsTemplate>
      <SEO
        title={`
          ${data.purpose ? `${data.purpose.frontmatter.name} ` : ''}
          Events | Absolute Training Centre
        `}
        description="Equip yourself to reach your true potential with events hosted by Absolute Training Centre."
      />
      <PageHero
        alt="Bryan Fraser in a crowd"
        title={
          <>
            Events To <span>Elevate</span>
          </>
        }
        image={data.file}
      />
      <PurposeMenu
        showAll
        family="link"
        purpose={data?.purpose?.frontmatter?.name || 'All'}
        setPurpose={(newPurpose) =>
          navigate(
            newPurpose.toLowerCase() !== 'all'
              ? `/events/${newPurpose.toLowerCase()}`
              : '/events',
            { state: { updateScroll: false } }
          )
        }
      />
      {!groups.upcoming.length &&
        !groups.ongoing.length &&
        !groups.previous.length && (
          <S.NoneFound>
            It looks like we haven&apos;t added any events in this category yet.
            Maybe it&apos;s time to contact us about it.
          </S.NoneFound>
        )}
      {groups.upcoming.length > 0 && (
        <S.Group>
          <S.GroupTitle>Upcoming Events</S.GroupTitle>
          <EventGrid featured={false} family="row" events={groups.upcoming} />
        </S.Group>
      )}
      {groups.ongoing.length > 0 && (
        <S.Group>
          <S.GroupTitle>Ongoing Events</S.GroupTitle>
          <EventGrid featured={false} family="row" events={groups.ongoing} />
        </S.Group>
      )}
      {groups.previous.length > 0 &&
        (open || (!groups.upcoming.length && !groups.ongoing.length) ? (
          <S.Group>
            <S.GroupTitle>Previous Events</S.GroupTitle>
            <EventGrid featured={false} family="row" events={groups.previous} />
          </S.Group>
        ) : (
          <S.Button onClick={() => setOpen(true)}>
            View Previous Events
          </S.Button>
        ))}
    </S.EventsTemplate>
  );
};

export const query = graphql`
  query ($purpose: String, $filter: Boolean = false) {
    file(relativePath: { eq: "png/events-hero.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    purpose: markdownRemark(
      frontmatter: { type: { eq: "purpose" }, name: { eq: $purpose } }
    ) @include(if: $filter) {
      frontmatter {
        name
      }
    }
    allEvents: allMarkdownRemark(
      sort: { fields: date, order: ASC }
      filter: { frontmatter: { type: { eq: "event" } } }
    ) @skip(if: $filter) {
      nodes {
        frontmatter {
          dates {
            date
          }
        }
        ...EventGridFragment
      }
    }
    filteredEvents: allMarkdownRemark(
      sort: { fields: date, order: ASC }
      filter: {
        frontmatter: { type: { eq: "event" }, purposes: { in: [$purpose] } }
      }
    ) @include(if: $filter) {
      nodes {
        frontmatter {
          dates {
            date
          }
        }
        ...EventGridFragment
      }
    }
  }
`;

EventsTemplate.defaultProps = {
  useAttributes: undefined,
};

EventsTemplate.propTypes = {
  data: propTypes.shape({
    file: propTypes.shape({
      childImageSharp: propTypes.shape({
        gatsbyImageData: propTypes.object.isRequired,
      }).isRequired,
    }).isRequired,
    purpose: propTypes.shape({
      frontmatter: propTypes.shape({
        name: propTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    allEvents: propTypes.shape({
      nodes: propTypes.array.isRequired,
    }),
    filteredEvents: propTypes.shape({
      nodes: propTypes.array.isRequired,
    }),
  }).isRequired,
  pageContext: propTypes.shape({
    purpose: propTypes.string.isRequired,
  }).isRequired,
  useAttributes: propTypes.func,
};

export default EventsTemplate;
