import styled from 'styled-components';

import COMP1 from 'src/components/Button';

export const Signup = styled.div`
  background: ${({ theme }) => theme.bg.lightGrey};
`;

export const Wrapper = styled.div`
  padding: 16px;
  max-width: 1120px;
  margin: 0 auto;

  @media(min-width: ${({ theme }) => theme.size.md}) {
    display: flex;
    padding: 24px 16px;
    align-items: center;
  }
`;

export const TextWrapper = styled.div`
  margin: 0 0 48px;

  @media(min-width: ${({ theme }) => theme.size.md}) {
    width: 380px;
    flex-shrink: 0;
    margin: 0 72px 0 0;
  }
`;

export const Title = styled.h3`
  font-size: 1.75rem;
  margin: 0 0 8px;
  color: ${({ theme }) => theme.color.purple};
  font-weight: 600;
  font-style: italic;
  font-family: ${({ theme }) => theme.font.secondary};

  @media(min-width: ${({ theme }) => theme.size.md}) {
    font-size: 2rem;
  }
`;

export const Text = styled.p`
  font-size: 0.875rem;
  margin: 0;
`;

export const InputWrapper = styled.form`
  height: auto;
  position: relative;

  @media(min-width: ${({ theme }) => theme.size.md}) {
    display: flex;
    width: 100%;
    overflow: hidden;
    border-radius: 8px;
    background: ${({ theme }) => theme.color.purple};
    border: 3px solid ${({ theme }) => theme.color.purple};
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 44px;
  padding: 4px 22px;
  border-radius: 22px;
  font-style: italic;
  border: 3px solid ${({ theme }) => theme.color.purple};
  margin: 0 0 16px;

  :focus {
    outline: none;
  }

  @media(min-width: ${({ theme }) => theme.size.md}) {
    display: block;
    width: calc((100% - 120px) / 2);
    border-radius: 0;
    border: none;
    margin: 0;
  }
`;

export const Rule = styled.hr`
  display: none;

  @media(min-width: ${({ theme }) => theme.size.md}) {
    position: absolute;
    display: block;
    top: 0;
    left: calc((100% - 120px) / 2);
    height: 24px;
    width: 3px;
    margin: 10px 0;
    border: none;
    background: ${({ theme }) => theme.color.purple};
  }
`;

export const Button = styled(COMP1)`
  width: 100%;
  height: 44px;
  border-radius: 22px;
  border: none;
  margin: 0;
  cursor: pointer;
  font-weight: 600;
  color: ${({ theme }) => theme.text.white};
  background: ${({ theme }) => theme.color.purple};

  :focus, :hover {
    outline: none;
    background: ${({ theme }) => theme.color.hover.purple};
  }

  :active {
    outline: none;
    background: ${({ theme }) => theme.color.active.purple};
  }

  @media(min-width: ${({ theme }) => theme.size.md}) {
    display: block;
    width: 120px;
    border-radius: 0;
  }
`;
