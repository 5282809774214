import styled from 'styled-components';

import { markdown } from 'src/utilities/css';

import COMP1 from 'src/components/Button';

export const Event = styled.div`
  display: grid;
  grid-gap: 40px;
  padding: 0 16px;
  max-width: 1200px;
  margin: 0 auto 120px;

  @media(min-width: ${({ theme }) => theme.size.md}) {
    grid-gap: 60px;
    grid-template-columns: 240px 1fr;
  }

  @media(min-width: ${({ theme }) => theme.size.lg}) {
    grid-gap: 80px;
    grid-template-columns: 320px 1fr;
  }
`;

export const Meta = styled.div`

`;

export const MetaItem = styled.div`
  margin: 0 0 24px;
`;

export const Title = styled.h4`
  font-size: 1.125rem;
  margin: 0 0 4px;
  font-style: italic;
  color: ${({ theme }) => theme.text.light};
`;

export const Icon = styled.img`

`;

export const Value = styled.p`
  font-size: 1.125rem;
  line-height: 1.6;
  margin: 0;
`;

export const Button = styled(COMP1)`
  display: block;
  width: 100%;
  margin: 0 0 24px;
`;

export const Line = styled.hr`
  margin: 0 0 24px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid ${({ theme }) => theme.bg.mediumGrey};
`;

export const Purpose = styled.span`
  font-size: 12px;
  display: inline-block;
  line-height: 1;
  padding: 4px 12px;
  border-radius: 12px;
  margin: 0 8px 16px 0;
  border: 2px solid ${({ $color, theme }) => theme.color[$color]};
`;

export const Tag = styled.span`
  font-size: 12px;
  display: inline-block;
  line-height: 1;
  padding: 4px 12px;
  border-radius: 12px;
  margin: 0 8px 16px 0;
  border: 2px solid ${({ theme }) => theme.bg.lightGrey};
`;

export const Description = styled.div`
  ${markdown}
`;
