import styled from 'styled-components';

export const CartPage = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  min-height: 100vh;
  padding: 56px 0 0;

  @media(min-width: ${({ theme }) => theme.size.md}) {
    padding: 80px 0 0;
  }
`;

export const Title = styled.h1`
  font-size: 2rem;
  text-align: center;
  margin: 32px 0;
  font-weight: 600;
  font-style: italic;
  font-family: ${({ theme }) => theme.font.secondary};

  @media(min-width: ${({ theme }) => theme.size.md}) {
    font-size: 3rem;
  }
`;
