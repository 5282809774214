import { useState, useEffect } from 'react';

function getActiveMedia(selectedVariant, media) {
  const id = selectedVariant?.media?.[0]?.id;

  if (id) {
    return media.findIndex((medium) => medium.id === id);
  }

  return 0;
}

const useActiveMedia = (selectedVariant, media) => {
  const [activeMedia, setActiveMedia] = useState(
    getActiveMedia(selectedVariant, media)
  );

  useEffect(() => {
    setActiveMedia(getActiveMedia(selectedVariant, media));
  }, [selectedVariant]);

  return [activeMedia, setActiveMedia];
};

export default useActiveMedia;
