import { gql } from 'graphql-request';

export default gql`
  fragment CheckoutFragment on Checkout {
    id
    webUrl
    completedAt
    subtotalPriceV2 {
      amount
      currencyCode
    }
    totalPriceV2 {
      amount
      currencyCode
    }
    lineItems(first: 250) {
      edges {
        node {
          ...CheckoutLineItemFragment
        }
      }
    }
  }
`;
