import React from 'react';
import propTypes from 'prop-types';
import { graphql } from 'gatsby';

import SEO from 'src/components/SEO';

import PageHero from 'src/sections/PageHero';
import Statement from 'src/sections/Statement';
import CoreValues from 'src/sections/CoreValues';
import Signup from 'src/sections/Signup';
import Leadership from 'src/sections/Leadership';

import * as S from './about-us.style';

const AboutUsPage = ({ data, useAttributes }) => {
  useAttributes({ initiallyTransparent: true });

  return (
    <S.AboutUsPage>
      <SEO title="About Us | Absolute Training Centre" />
      <PageHero alt="The Edmonton Skyline" image={data.file}>
        <S.Headline>
          <S.Headline>
            <S.Intro>
              About <S.Highlight>Absolute</S.Highlight>
            </S.Intro>
          </S.Headline>
        </S.Headline>
        <S.Purpose>
          Our Vision is to bring unity to a world programmed for division.
        </S.Purpose>
        <S.Line />
      </PageHero>
      <Statement type="purpose" />
      <CoreValues />
      <Signup type="e-motivator" />
      <Leadership />
      <Signup type="newsletter" />
    </S.AboutUsPage>
  );
};

export const query = graphql`
  query {
    file(relativePath: { eq: "png/about-us-hero.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;

AboutUsPage.defaultProps = {
  useAttributes: undefined,
};

AboutUsPage.propTypes = {
  data: propTypes.object.isRequired,
  useAttributes: propTypes.func,
};

export default AboutUsPage;
