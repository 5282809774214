import { css } from 'styled-components';

export const markdown = css`
  line-height: 1.6;
  font-size: 1.125rem;
  word-wrap: break-word;
  overflow-wrap: break-word;

  > *:first-child {
    margin-top: 0;
  }

  .gatsby-resp-image-wrapper {
  }

  iframe {
    width: 100%;
    margin: 2rem 0;
    border-radius: 8px;
    overflow: hidden;
  }

  blockquote {
    background: ${({ theme }) => theme.hue.purple};
    margin-block-start: 0.4em;
    margin-block-end: 0.4em;
    margin-left: 0;
    margin-right: 0;
    padding: 16px;
    font-size: 2.5rem;
    font-style: italic;
    border-radius: 8px;
    text-align: center;
    color: ${({ theme }) => theme.color.purple};
    font-weight: 600;
    font-style: italic;
    font-family: ${({ theme }) => theme.font.secondary};
  }

  a {
    color: ${({ theme }) => theme.color.purple};

    :hover {
      color: ${({ theme }) => theme.color.hover.purple};
    }

    :active {
      color: ${({ theme }) => theme.color.active.purple};
    }
  }

  p {
    margin-block-start: 1.2em;
    margin-block-end: 1.2em;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-block-start: 1.2em;
    margin-block-end: 0.4em;
  }

  h1 {
    font-size: 3.11rem;
  }

  h2 {
    font-size: 2.592rem;
  }

  h3 {
    font-size: 2.16rem;
  }

  h4 {
    font-size: 1.8rem;
  }

  h5 {
    font-size: 1.5rem;
  }

  h6 {
    font-size: 1.25rem;
  }
`;
