import styled, { css } from 'styled-components';

import SVG1 from 'src/assets/svg/quotation-mark.svg';

export const Quote = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 24px 20px;
  width: fit-content;
  max-width: calc(100% - 32px);
  margin-left: auto;
  margin-right: auto;
  background: ${({ theme }) => theme.bg.lightGrey};
`;

export const Mark = styled(SVG1)`
  height: 48px;
  width: 48px;
  margin-right: 20px;
`;

export const Text = styled.p`
  margin: 0;
  line-height: 1.15;
  font-weight: 600;
  font-style: italic;
  font-family: ${({ theme }) => theme.font.secondary};
  color: ${({ theme }) => theme.color.purple};

  ${({ $size }) => $size === 'small' && css`
    font-size: 1.25rem;

    @media(min-width: ${({ theme }) => theme.size.md}) {
      font-size: 1.5rem;
    }
  `}

  ${({ $size }) => $size === 'large' && css`
    font-size: 1.5rem;

    @media(min-width: ${({ theme }) => theme.size.md}) {
      font-size: 2.5rem;
    }
  `}
`;
