import styled from 'styled-components';
import { GatsbyImage as COMP2 } from 'gatsby-plugin-image';

export const LineItemGrid = styled.div`

`;

export const NoneFound = styled.p`
  font-size: 1.25rem;
  margin: 0 auto;
  max-width: 640px;
  font-weight: 600;
  text-align: center;
  padding: 0 16px;
`;

export const Wrapper = styled.div`
  display: grid;
  grid-gap: 32px;
  padding: 16px;
`;

export const Card = styled.div`
  display: grid;
  grid-gap: 16px;
  padding: 0 0 16px;
  grid-template-rows: auto auto;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    "Image Content"
    "Actions Actions";
  border-bottom: 1px solid ${({ theme }) => theme.bg.mediumGrey};
  font-size: ${({ $featured }) => $featured ? '1rem' : '0.875rem'};

  @media(min-width: ${({ theme }) => theme.size.md}) {
    grid-template-areas:
      "Image Content"
      "Image Actions";
  }
`;

export const Image = styled(COMP2)`
  grid-area: Image;
  display: block;
  width: 80px;
  height: 0;
  border-radius: 4px;
  padding-bottom: calc(100%);

  @media(min-width: ${({ theme }) => theme.size.md}) {
    width: 160px;
  }
`;

export const Content = styled.div`
  grid-area: Content;
  display: grid;
  align-content: end;
  grid-template-rows: auto auto 1fr;
`;

export const ProductTitle = styled.h4`
  font-size: 1.375rem;
  font-weight: 400;
  margin: 0 0 4px;
  font-style: italic;

  @media(min-width: ${({ theme }) => theme.size.md}) {
    font-size: 1.6875rem;
  }
`;

export const Price = styled.h5`
  font-size: 0.875rem;
  font-weight: 400;
  margin: 0 0 4px;

  @media(min-width: ${({ theme }) => theme.size.md}) {
    font-size: 1rem;
  }
`;

export const CompareAtPrice = styled.span`
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 400;
  color: #BD1816;
  margin-left: 6px;
  text-decoration: line-through;

  @media(min-width: ${({ theme }) => theme.size.md}) {
    font-size: 1rem;
  }
`;

export const VariantTitle = styled.h5`
  font-size: 0.875rem;
  font-weight: 300;
  margin: 0;

  @media(min-width: ${({ theme }) => theme.size.md}) {
    font-size: 1rem;
  }
`;

export const Actions = styled.div`
  grid-area: Actions;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  align-items: center;
`;

export const Remove = styled.button`
  background: none;
  border: none;
  color: #BD1816;
  padding: 0;
  margin: 0 0 0 10px;
  border-bottom: 1px solid #BD1816;
  cursor: pointer;

  @media(min-width: ${({ theme }) => theme.size.md}) {
    margin: 0;
  }
`;
