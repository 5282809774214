import styled from 'styled-components';

export const Purposes = styled.div`
  position: relative;
  padding: 24px 0 0;
  margin: 0 0 60px;
  background: ${({ theme }) => theme.bg.lightGrey};

  @media(min-width: ${({ theme }) => theme.size.lg}) {
    padding: 84px 0 48px;
  }
`;

export const Watermark = styled.span`
  position: absolute;
  font-size: 54px;
  font-weight: 600;
  top: 186px;
  left: 10px;
  white-space: nowrap;
  color: ${({ $color, theme }) => theme.hue[$color]};

  @media(min-width: ${({ theme }) => theme.size.lg}) {
    font-size: 174px;
    top: 64px;
    left: auto;
    right: 48px;
  }
`;

export const Wrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

export const PurposeWrapper = styled.div`
  @media(min-width: ${({ theme }) => theme.size.lg}) {
    display: grid;
    grid-template-columns: 256px 1fr;
    grid-column-gap: 48px;
    padding: 0 16px;
  }
`;

export const Subtitle = styled.h3`
  position: relative;
  font-size: 1.375rem;
  margin: 0;
  padding: 0 16px;
  font-weight: 600;
  font-style: italic;
  font-family: ${({ theme }) => theme.font.secondary};
`;

export const Title = styled.h1`
  position: relative;
  font-size: 2.5rem;
  margin: 0 0 12px;
  padding: 0 16px;

  @media(min-width: ${({ theme }) => theme.size.lg}) {
    font-size: 4rem;
    margin: 0 0 32px;
  }
`;

export const TextWrapper = styled.div`
  padding: 64px 16px 32px;
  background: ${({ theme }) => theme.bg.base};

  @media(min-width: ${({ theme }) => theme.size.lg}) {
    padding: 0;
    background: none;
    margin-right: 74px;
  }
`;

export const Topic = styled.p`
  position: relative;
  font-size: 1.25rem;
  text-align: center;
  font-weight: 600;
  margin: 0 0 32px;
  font-style: italic;

  @media(min-width: ${({ theme }) => theme.size.lg}) {
    font-size: 1.5rem;
    text-align: left;
    margin: 0 0 16px;
  }
`;

export const Content = styled.div`
  position: relative;
  font-size: 1.125rem;
  margin: 0 0 32px;
`;
