import React from 'react';
import propTypes from 'prop-types';
import { graphql } from 'gatsby';

import { mapPurposeToColor } from 'src/utilities/helpers';

import * as S from './PurposeDescription.style';

const PurposeDescription = ({ purpose }) => (
  <S.PurposeDescription>
    <S.Square />
    <S.Watermark $color={mapPurposeToColor(purpose.frontmatter.name)}>
      {purpose.frontmatter.name}
    </S.Watermark>
    <S.Description dangerouslySetInnerHTML={{ __html: purpose.html }} />
  </S.PurposeDescription>
);

export const PurposeDescriptionFragment = graphql`
  fragment PurposeDescriptionFragment on MarkdownRemark {
    html
    frontmatter {
      name
    }
  }
`;

PurposeDescription.propTypes = {
  purpose: propTypes.shape({
    html: propTypes.node.isRequired,
    frontmatter: propTypes.shape({
      name: propTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default PurposeDescription;
