import React, { useRef, useContext, useState, useEffect } from 'react';
import propTypes from 'prop-types';

import { windowWidth } from 'src/utilities/helpers';

import ShopifyContext from 'src/contexts/ShopifyContext';

import useBodyScroll from 'src/hooks/useBodyScroll';
import useRouteChange from 'src/hooks/useRouteChange';

import Alert from './Alert';
import Submenu from './Submenu';

import * as S from './Header.style';

const directory = {
  'About Us': '/about-us',
  Purpose: {
    'Well-being': '/purpose/well-being',
    Family: '/purpose/family',
    Business: '/purpose/business',
    Life: '/purpose/life',
  },
  Events: '/events',
  Blog: '/blog',
  Contact: {
    'General Inquiries': '/contact/general-inquiries',
    'Request Partnership': '/contact/request-partnership',
    'Invite To Speak': '/contact/invite-to-speak',
  },
  Shop: '/shop',
};

const Header = ({ initiallyTransparent }) => {
  const header = useRef();
  const [desktop, setDesktop] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const { checkout } = useContext(ShopifyContext);
  const [scrollable, disableScroll, enableScroll] = useBodyScroll(
    header.current
  );

  const handleResize = () => {
    if (windowWidth() < 992 && desktop) setDesktop(false);
    else if (windowWidth() >= 922 && !desktop) setDesktop(true);
  };

  const handleScroll = () => {
    if (header.current) {
      const headerHeight = header.current.clientHeight;
      if (!scrolled && window.scrollY > headerHeight + 100) setScrolled(true);
      else if (scrolled && window.scrollY <= headerHeight) setScrolled(false);
    }
  };

  useRouteChange(() => {
    if (menuOpen) setMenuOpen(false);
  });

  useEffect(() => {
    if (scrollable && !desktop && menuOpen) disableScroll();
    if (!scrollable && (desktop || !menuOpen)) enableScroll();
  }, [menuOpen, desktop]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [desktop]);

  return (
    <S.Header
      ref={header}
      $desktop={desktop}
      $menuOpen={menuOpen}
      $scrolled={scrolled}
      $transparent={initiallyTransparent}
    >
      <S.Wrapper>
        <S.LogoLink to="/">
          <S.Logo />
        </S.LogoLink>
        <S.MenuButton
          aria-label={`${menuOpen ? 'Close' : 'Open'} menu`}
          onClick={() => setMenuOpen(!menuOpen)}
        >
          {menuOpen ? <S.CloseIcon /> : <S.OpenIcon />}
        </S.MenuButton>
        <S.Menu aria-hidden={!menuOpen} $open={menuOpen}>
          {Object.keys(directory).map((title) =>
            typeof directory[title] === 'object' ? (
              <Submenu
                key={title}
                title={title}
                menuOpen={menuOpen}
                subdirectory={directory[title]}
                desktop={desktop}
              />
            ) : (
              <S.MenuLink
                key={title}
                tabIndex={menuOpen || desktop ? '0' : '-1'}
                to={directory[title]}
              >
                {title}
              </S.MenuLink>
            )
          )}
          <S.Cart to="/cart">
            <S.CartIcon />
            <S.CartText>
              Cart
              {checkout && checkout.lineItems.edges.length > 0 && (
                <S.CartIndicator>
                  ({checkout.lineItems.edges.length})
                </S.CartIndicator>
              )}
            </S.CartText>
          </S.Cart>
        </S.Menu>
      </S.Wrapper>
      <Alert />
    </S.Header>
  );
};

Header.propTypes = {
  initiallyTransparent: propTypes.bool.isRequired,
};

export default Header;
