import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

export const GlobalStyle = createGlobalStyle`
  ${normalize}
  :root {
    font-weight: 400;
    line-height: 1.4;
    color: ${({ theme }) => theme.text.base};
    background: ${({ theme }) => theme.bg.base};
    font-family: ${({ theme }) => theme.font.primary};
  }

  *, :after, :before {
    touch-callout: none;
    box-sizing: border-box;
    tap-highlight-color: transparent;
  }

  h1, h2, h3, h4, h5, h6 {
    line-height: 1.15;
  }
`;
