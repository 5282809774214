import { gql } from 'graphql-request';

export default gql`
  fragment VariantFragment on ProductVariant {
    id
    title
    priceV2 {
      amount
      currencyCode
    }
    compareAtPriceV2 {
      amount
      currencyCode
    }
    image {
      id
      width
      height
      altText
      originalSrc
    }
  }
`;
