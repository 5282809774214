import React from 'react';
import propTypes from 'prop-types';

import * as S from './SocialIcons.style';

const SocialIcons = ({ links, size, family, className }) => (
  <S.SocialIcons className={className}>
    {links.map((link) => (
      <S.Link key={link.url} href={link.url}>
        {link.icon === 'Instagram' && (
          <S.Instagram $size={size} $family={family} />
        )}
        {link.icon === 'Facebook' && (
          <S.Facebook $size={size} $family={family} />
        )}
        {link.icon === 'Youtube' && <S.Youtube $size={size} $family={family} />}
        {link.icon === 'Twitter' && <S.Twitter $size={size} $family={family} />}
      </S.Link>
    ))}
  </S.SocialIcons>
);

SocialIcons.defaultProps = {
  className: undefined,
};

SocialIcons.propTypes = {
  links: propTypes.arrayOf(
    propTypes.shape({
      url: propTypes.string.isRequired,
      icon: propTypes.oneOf(['Instagram', 'Facebook', 'Youtube', 'Twitter'])
        .isRequired,
    })
  ).isRequired,
  size: propTypes.oneOf(['small', 'large']).isRequired,
  family: propTypes.oneOf(['white', 'grey']).isRequired,
  className: propTypes.string,
};

export default SocialIcons;
