import styled, { css } from 'styled-components';

import arrow from 'src/assets/svg/select-arrow.inline.svg';

export const Select = styled.div`
  position: relative;
  margin-bottom: 32px;
  ${({ name }) => name && css`
    grid-area: ${name};
  `}
`;

export const Input = styled.select`
  display: block;
  width: 100%;
  font-size: 1.125rem;
  border-radius: 6px;
  cursor: pointer;
  background: none;
  appearance: none;
  padding: 10px 16px;
  border: 1.5px solid ${({ theme }) => theme.color.purple};
  color: ${({ value, theme }) => (value === '' ? '#808080' : theme.text.base)};


  background: url("${arrow}");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 16px);
  background-position-y: calc((100% - 2px) / 2);
`;

export const Label = styled.label`
  display: block;
  font-size: 1.125rem;
  margin: 0 0 8px;
  font-weight: 500;
  color: ${({ theme }) => theme.text.light};
`;

export const Option = styled.option`
  display: block;
  color: ${({ theme }) => theme.text.base} !important;
`;
