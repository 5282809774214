import { gql } from 'graphql-request';

import CheckoutFragment from '../fragments/CheckoutFragment';
import CheckoutLineItemFragment from '../fragments/CheckoutLineItemFragment';
import VariantFragment from '../fragments/VariantFragment';

export default gql`
  ${CheckoutFragment}
  ${CheckoutLineItemFragment}
  ${VariantFragment}
  query ($id: ID!) {
    node(id: $id) {
      ...CheckoutFragment
    }
  }
`;
