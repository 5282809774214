import React, { createContext, useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { GraphQLClient } from 'graphql-request';

import config from 'src/config';

import shopify from 'src/utilities/shopify';

const SHOPIFY_CHECKOUT_ID = 'shopify_checkout_id';

const ShopifyContext = createContext();

const client = new GraphQLClient(
  `https://${config.CHECKOUT_URL}/api/2021-10/graphql.json`,
  {
    headers: {
      Accept: 'application/json',
      'X-Shopify-Storefront-Access-Token': config.STOREFRONT_TOKEN,
    },
  }
);

const initialState = {
  loading: true,
  checkout: undefined,
};

export const ShopifyProvider = ({ children }) => {
  const [state, setState] = useState(initialState);
  const functions = shopify(client, state, setState);

  // On initial website load fetch the checkout
  useEffect(() => {
    functions.fetchCheckout();
  }, []);

  // Whenever the checkout id changes replace it in local storage
  useEffect(() => {
    if (state?.checkout?.id) {
      const checkoutId = window.localStorage.getItem(SHOPIFY_CHECKOUT_ID);
      if (state.checkout.id !== checkoutId) {
        window.localStorage.setItem(SHOPIFY_CHECKOUT_ID, state.checkout.id);
      }
    }
  }, [state?.checkout?.id]);

  useEffect(() => {
    if (state?.checkout?.completedAt) {
      setState((newState) => ({
        ...newState,
        loading: false,
        checkout: undefined,
      }));
      window.localStorage.removeItem(SHOPIFY_CHECKOUT_ID);
    }
  }, [state?.checkout?.completedAt]);

  return (
    <ShopifyContext.Provider
      value={{
        ...functions,
        loading: state.loading,
        checkout: state.checkout,
      }}
    >
      {children}
    </ShopifyContext.Provider>
  );
};

ShopifyProvider.propTypes = {
  children: propTypes.node.isRequired,
};

export default ShopifyContext;
