import React, { useState } from 'react';
import propTypes from 'prop-types';
import { graphql } from 'gatsby';

import SEO from 'src/components/SEO';
import { Text, Textarea } from 'src/components/Inputs';

import PageHero from 'src/sections/PageHero';
import PartnerDescription from 'src/sections/PartnerDescription';

import * as S from './request-partnership.style';

const initialValue = {
  firstName: '',
  lastName: '',
  emailAddress: '',
  phoneNumber: '',
  organizationName: '',
  organizationLocation: '',
  positionAtOrganization: '',
  organizationSize: '',
  description: '',
};

const RequestPartnershipPage = ({ data, useAttributes }) => {
  const [value, setValue] = useState(initialValue);
  useAttributes({ initiallyTransparent: true });

  return (
    <S.RequestPartnershipPage>
      <SEO title="Request Partnership | Absolute Training Centre" />
      <PageHero
        alt="Two hands holding puzzle pieces"
        image={data.file}
        title={
          <>
            The Power Of <span>Partnership</span>
          </>
        }
      />
      <PartnerDescription />
      <S.Quote size="large">Building a vision of unity together.</S.Quote>
      <S.FormTitle>Ready to Partner? Inquire Today</S.FormTitle>
      <S.NetlifyForm
        name="Request Partnership Form"
        template={[
          'FirstName LastName',
          'EmailAddress PhoneNumber',
          'OrganizationName OrganizationLocation',
          'PositionAtOrganization OrganizationSize',
          'Description Description',
        ]}
        onSubmit={() => setValue(initialValue)}
      >
        <Text
          required
          id="FirstNameInput"
          label="First Name"
          name="FirstName"
          value={value.firstName}
          onChange={(newValue) =>
            setValue((state) => ({ ...state, firstName: newValue }))
          }
        />
        <Text
          required
          id="LastNameInput"
          label="Last Name"
          name="LastName"
          value={value.lastName}
          onChange={(newValue) =>
            setValue((state) => ({ ...state, lastName: newValue }))
          }
        />
        <Text
          required
          type="email"
          id="EmailAddressInput"
          label="Email Address"
          name="EmailAddress"
          value={value.emailAddress}
          onChange={(newValue) =>
            setValue((state) => ({ ...state, emailAddress: newValue }))
          }
        />

        <Text
          required
          type="tel"
          id="PhoneNumberInput"
          label="Phone Number"
          name="PhoneNumber"
          value={value.phoneNumber}
          onChange={(newValue) =>
            setValue((state) => ({ ...state, phoneNumber: newValue }))
          }
        />
        <Text
          required
          id="OrganizationNameInput"
          label="Organization Name"
          name="OrganizationName"
          value={value.organizationName}
          onChange={(newValue) =>
            setValue((state) => ({ ...state, organizationName: newValue }))
          }
        />
        <Text
          required
          id="OrganizationLocationInput"
          label="Organization Location"
          name="OrganizationLocation"
          value={value.organizationLocation}
          onChange={(newValue) =>
            setValue((state) => ({ ...state, organizationLocation: newValue }))
          }
        />
        <Text
          required
          id="PositionAtOrganizationInput"
          label="Your Position at Organization"
          name="PositionAtOrganization"
          value={value.positionAtOrganization}
          onChange={(newValue) =>
            setValue((state) => ({
              ...state,
              positionAtOrganization: newValue,
            }))
          }
        />
        <Text
          required
          id="OrganizationSizeInput"
          label="Organization Size"
          name="OrganizationSize"
          value={value.organizationSize}
          onChange={(newValue) =>
            setValue((state) => ({ ...state, organizationSize: newValue }))
          }
        />

        <Textarea
          required
          id="DescriptionInput"
          label="How would you like to see Absolute and your Organization work together?"
          name="Description"
          value={value.description}
          onChange={(newValue) =>
            setValue((state) => ({ ...state, description: newValue }))
          }
        />
      </S.NetlifyForm>
    </S.RequestPartnershipPage>
  );
};

export const query = graphql`
  query {
    file(relativePath: { eq: "png/request-partnership-hero.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;

RequestPartnershipPage.defaultProps = {
  useAttributes: undefined,
};

RequestPartnershipPage.propTypes = {
  data: propTypes.shape({
    file: propTypes.object.isRequired,
  }).isRequired,
  useAttributes: propTypes.func,
};

export default RequestPartnershipPage;
