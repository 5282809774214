import React from 'react';
import propTypes from 'prop-types';

import SEO from 'src/components/SEO';

import Cart from 'src/sections/Cart';

import * as S from './cart.style';

const CartPage = ({ useAttributes }) => {
  useAttributes({ initiallyTransparent: false });

  return (
    <S.CartPage>
      <SEO title="Cart | Absolute Training Centre" />
      <S.Title>Shopping Cart</S.Title>
      <Cart />
    </S.CartPage>
  );
};

CartPage.defaultProps = {
  useAttributes: undefined,
};

CartPage.propTypes = {
  useAttributes: propTypes.func,
};

export default CartPage;
