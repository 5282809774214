import styled from 'styled-components';

import { markdown } from 'src/utilities/css';

export const PurposeDescription = styled.div`
  position: relative;
  padding: 0 16px;
  max-width: 1200px;
  margin: 40px auto 60px;

  @media(min-width: ${({ theme }) => theme.size.xl}) {
    padding: 54px 16px 0;
    margin: 80px auto 140px;
  }
`;

export const Square = styled.div`
  display: none;

  @media(min-width: ${({ theme }) => theme.size.xl}) {
    display: block;
    position: absolute;
    max-width: 1168px;
    height: 268px;
    margin: 0 auto;
    border-radius: 12px;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background: ${({ theme }) => theme.bg.lightGrey};
  }
`;



export const Watermark = styled.span`
  display: none;

  @media(min-width: ${({ theme }) => theme.size.xl}) {
    display: inline;
    position: absolute;
    font-size: 174px;
    font-weight: 600;
    white-space: nowrap;
    top: -72px;
    left: -64px;
    z-index: -1;
    color: ${({ $color, theme }) => theme.hue[$color]};
  }
`;

export const Description = styled.div`
  ${markdown}
  margin: 0 auto;
  max-width: 680px;

  blockquote {
    font-size: 1.125rem;
    text-align: center;
    background: none;
    color: ${({ theme }) => theme.color.purple};
    font-weight: 600;
    font-style: italic;
    font-family: ${({ theme }) => theme.font.secondary};

    @media(min-width: ${({ theme }) => theme.size.md}) {
      font-size: 1.625rem;
    }
  }
`;
