import React from 'react';
import propTypes from 'prop-types';

import SEO from 'src/components/SEO';

import * as S from './404.style';

const ErrorPage = ({ useAttributes }) => {
  useAttributes({ initiallyTransparent: false });

  return (
    <S.ErrorPage>
      <SEO title="Error 404 | Absolute Training Centre" />
      <S.Title>Error 404</S.Title>
      <S.Text>
        This website is under construction and some pages are still missing.
      </S.Text>
      <S.Text>
        For questions please contact{' '}
        <S.Link href="mailto: info@absolutetrainingcentre.com">
          info@absolutetrainingcentre.com
        </S.Link>
      </S.Text>
      <S.Button to="/">Head Home</S.Button>
    </S.ErrorPage>
  );
};

ErrorPage.defaultProps = {
  useAttributes: undefined,
};

ErrorPage.propTypes = {
  useAttributes: propTypes.func,
};

export default ErrorPage;
