import React, { useRef, useState, useEffect } from 'react';

import config from 'src/config';

import { subscribeToList } from 'src/utilities/klaviyo';

import SocialIcons from 'src/components/SocialIcons';

import * as S from './ArticleCTA.style';

const initialState = {
  firstName: '',
  value: '',
};

const ArticleCTA = () => {
  const form = useRef();
  const button = useRef();
  const valueInput = useRef();
  const [visible, setVisible] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [state, setState] = useState(initialState);

  const handleScroll = () => {
    if (!visible && window.scrollY > window.document.body.scrollHeight * 0.4) {
      setVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [visible]);

  const url = encodeURIComponent(
    typeof window !== 'undefined' && window.location.href
  );

  return (
    <S.ArticleCTA $open={visible && !disabled}>
      <S.Wrapper
        ref={form}
        onSubmit={(event) => {
          event.preventDefault();
          button.current.click();
        }}
      >
        <S.Title>
          Subscribe to our newsletter!
          <S.CloseButton type="button" onClick={() => setDisabled(true)}>
            <S.CloseIcon />
          </S.CloseButton>
        </S.Title>
        <S.Text>
          Allow us to share with you the differences that are being made in our
          communities. Here you’ll find stories of impact and transformational
          tips to simplify your life.
        </S.Text>
        <S.Input
          required
          placeholder="Your Name"
          value={state.firstName}
          onChange={({ target: { value: newValue } }) => {
            setState((prevState) => ({ ...prevState, firstName: newValue }));
          }}
        />
        <S.Input
          ref={valueInput}
          required
          type="email"
          placeholder="Email Address"
          value={state.value}
          onChange={({ target: { value: newValue } }) => {
            setState((prevState) => ({ ...prevState, value: newValue }));
          }}
        />
        <S.Button
          type="button"
          ref={button}
          messages={{ loading: '', success: 'Subscribed', failure: 'Error' }}
          onClick={() =>
            subscribeToList(
              form.current,
              valueInput.current,
              config.NEWSLETTER_LIST_ID,
              state.firstName,
              'email',
              state.value
            )
          }
        >
          Subscribe
        </S.Button>
        <S.Line />
        <S.ShareText>Share this article:</S.ShareText>
        <SocialIcons
          size="large"
          family="grey"
          links={[
            {
              url: `https://www.facebook.com/sharer.php?u=${url}`,
              icon: 'Facebook',
            },
            {
              url: `https://twitter.com/intent/tweet?url=${url}`,
              icon: 'Twitter',
            },
          ]}
        />
      </S.Wrapper>
    </S.ArticleCTA>
  );
};

export default ArticleCTA;
