module.exports = {
  text: {
    base: '#212121',
    light: '#616161',
    white: '#FFFFFF',
    hover: {
      base: '#636363',
      light: '#525252',
      white: '#D8D8D8',
    },
    active: {
      base: '#A6A6A6',
      light: '#434343',
      white: '#B2B2B2',
    },
  },
  bg: {
    base: '#FFFFFF',
    lightGrey: '#EEF0F3',
    mediumGrey: '#DBDDE2',
    hover: {
      base: '#D8D8D8',
      lightGrey: '#C3CAD5',
      mediumGrey: '#B6BAC4',
    },
    active: {
      base: '#B2B2B2',
      lightGrey: '#99A5B7',
      mediumGrey: '#9197A6',
    },
  },
  color: {
    purple: '#3F3568',
    magenta: '#8B004A',
    yellow: '#D4B024',
    blue: '#062C60',
    green: '#6D9608',
    hover: {
      purple: '#352D58',
    },
    active: {
      purple: '#2C2548',
    },
  },
  hue: {
    purple: '#EEECF6',
    magenta: '#F5CDD4',
    yellow: '#F2E7BD',
    blue: '#B4C0CF',
    green: '#D3DFB5',
  },
  custom: {
    purple: '#6C648C',
  },
  status: {
    color: {
      success: '#40BF62',
      error: '#EA4E2C',
    },
    hue: {
      success: '#EAF7EE',
      error: '#FCEDE9',
    },
  },
  font: {
    primary: "'Lato', sans-serif",
    secondary: "'Open Sans', sans-serif",
  },
  size: {
    xs: '360px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xsDown: '360px',
    smDown: '575px',
    mdDown: '767px',
    lgDown: '991px',
    xlDown: '1199px',
  },
};
