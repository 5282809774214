import styled, { css } from 'styled-components';
import { GatsbyImage as COMP1 } from 'gatsby-plugin-image';

import { markdown } from 'src/utilities/css';

import COMP2 from 'src/components/Button';

export const Product = styled.div`
  padding: 0 16px;
  max-width: 1024px;
  margin: 24px auto 120px;

  @media(min-width: ${({ theme }) => theme.size.md}) {
    display: grid;
    grid-gap: 68px;
    grid-template-columns: 1fr 1.5fr;
  }
`;

export const ImageSlider = styled.div`
  margin: 0 0 24px;
`;

export const ActiveImage = styled(COMP1).attrs(() => ({
  imgStyle: { objectFit: 'contain' },
}))`
  display: block;
  overflow: hidden;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.bg.active.lightGrey};

  height: 0;
  width: 100%;
  padding-bottom: calc(100%);
`;

export const ImagePreviews = styled.div`
  display: grid;
  grid-gap: 8px;
  padding: 8px 0 0;
  grid-template-columns: repeat(5, 1fr);

  @media(min-width: ${({ theme }) => theme.size.md}) {
    grid-template-columns: repeat(6, 1fr);
  }
`;

export const ImagePreviewButton = styled.button`
  display: block;
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  border: none;
  background: none;
  padding: 0;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.bg.active.lightGrey};
  transition: all 0.25s;

  :hover {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.4);
  }

  ${({ $active }) => $active && css`
    pointer-events: none;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.6);
  `}
`;

export const ImagePreview = styled(COMP1).attrs(() => ({
  imgStyle: { objectFit: 'contain' },
}))`
  display: block;

  height: 0;
  width: 100%;
  padding-bottom: calc(100%);
`;

export const Meta = styled.div`

`;

export const Title = styled.h1`
  font-size: 1.75rem;
  margin: 0 0 10px;
`;

export const Price = styled.div`
  font-size: 1.125rem;
  font-weight: 600;
  margin: 0 0 32px;
`;

export const CompareAtPrice = styled.span`
  font-size: 1rem;
  font-weight: 400;
  color: #BD1816;
  margin-left: 8px;
  text-decoration: line-through;
`;

export const Description = styled.div`
  ${markdown}
  margin: 0 0 32px;
`;

export const Options = styled.div`
  margin: 0 0 32px;
`;

export const Label = styled.label`
  display: block;
  font-size: 1rem;
  margin: 0 0 8px;
`;

export const Option = styled.div`
  margin: 0 0 14px;
`;

export const Value = styled.button`
  display: inline-block;
  font-size: 1rem;
  line-height: 1;
  padding: 6px 14px;
  border-radius: 17px;
  margin: 0 10px 10px 0;
  transition: all 0.25s;
  background: ${({ theme }) => theme.bg.base};
  border: 2px solid ${({ theme }) => theme.color.purple};
  cursor: pointer;

  :disabled {
    pointer-events: none;
    color: ${({ theme }) => theme.text.white};
    background: ${({ theme }) => theme.bg.mediumGrey};
    border: 2px solid ${({ theme }) => theme.bg.hover.mediumGrey};
  }

  ${({ $selected }) => $selected && css`
    color: ${({ theme }) => theme.text.white};
    background: ${({ theme }) => theme.color.purple};
  `}
`;

export const BuyBox = styled.div`

`;

export const Button = styled(COMP2)`
  display: block;
  width: 100%;
  margin: 0 0 64px;
`;

export const Line = styled.hr`
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid ${({ theme }) => theme.bg.mediumGrey};
  margin: 0 0 16px;
`;

export const Share = styled.div`

`;

export const ShareText = styled.h6`
  font-size: 0.875rem;
  margin: 0 0 12px;
`;
