import React from 'react';
import propTypes from 'prop-types';

import * as S from './Select.style';

const Select = ({
  label,
  name,
  options,
  value,
  onChange,
  id,
  required,
  className,
}) => (
  <S.Select name={name} className={className}>
    {label && <S.Label htmlFor={id}>{label}</S.Label>}
    <S.Input
      name={name}
      required={required}
      id={id}
      value={value}
      onChange={({ target: { value: newValue } }) => onChange(newValue)}
    >
      {options.map((option) => (
        <S.Option key={option} value={option}>
          {option}
        </S.Option>
      ))}
    </S.Input>
  </S.Select>
);

Select.defaultProps = {
  label: undefined,
  name: undefined,
  required: false,
  className: undefined,
};

Select.propTypes = {
  label: propTypes.string,
  name: propTypes.string,
  options: propTypes.arrayOf(propTypes.string).isRequired,
  value: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
  id: propTypes.string.isRequired,
  required: propTypes.bool,
  className: propTypes.string,
};

export default Select;
