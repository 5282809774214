import styled, { css } from 'styled-components';

import {
  Instagram as SVG1,
  Facebook as SVG2,
  Youtube as SVG3,
  Twitter as SVG4,
} from '@styled-icons/bootstrap';

export const SocialIcons = styled.div`
  margin: 0 -6px;
`;

export const Link = styled.a`
  display: inline-block;
`;

export const Instagram = styled(SVG1)`
  display: block;

  ${({ $size }) => $size === 'small' && css`
    width: 24px;
    height: 24px;
    margin: 0 6px;
  `}

  ${({ $size }) => $size === 'large' && css`
    width: 32px;
    height: 32px;
    margin: 0 12px;
  `}

  ${({ $family }) => $family === 'white' && css`
    fill: ${({ theme }) => theme.text.white};
  `}

  ${({ $family }) => $family === 'grey' && css`
    fill: ${({ theme }) => theme.text.light};
  `}
`;

export const Facebook = styled(SVG2)`
  display: block;

  ${({ $size }) => $size === 'small' && css`
    width: 24px;
    height: 24px;
    margin: 0 6px;
  `}

  ${({ $size }) => $size === 'large' && css`
    width: 32px;
    height: 32px;
    margin: 0 10px;
  `}

  ${({ $family }) => $family === 'white' && css`
    fill: ${({ theme }) => theme.text.white};
  `}

  ${({ $family }) => $family === 'grey' && css`
    fill: ${({ theme }) => theme.text.light};
  `}
`;

export const Youtube = styled(SVG3)`
  display: block;

  ${({ $size }) => $size === 'small' && css`
    width: 24px;
    height: 24px;
    margin: 0 6px;
  `}

  ${({ $size }) => $size === 'large' && css`
    width: 32px;
    height: 32px;
    margin: 0 10px;
  `}

  ${({ $family }) => $family === 'white' && css`
    fill: ${({ theme }) => theme.text.white};
  `}

  ${({ $family }) => $family === 'grey' && css`
    fill: ${({ theme }) => theme.text.light};
  `}
`;

export const Twitter = styled(SVG4)`
  display: block;

  ${({ $size }) => $size === 'small' && css`
    width: 24px;
    height: 24px;
    margin: 0 6px;
  `}

  ${({ $size }) => $size === 'large' && css`
    width: 32px;
    height: 32px;
    margin: 0 10px;
  `}

  ${({ $family }) => $family === 'white' && css`
    fill: ${({ theme }) => theme.text.white};
  `}

  ${({ $family }) => $family === 'grey' && css`
    fill: ${({ theme }) => theme.text.light};
  `}
`;
